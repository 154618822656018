/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';

import { addDays, format } from 'date-fns';
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import CarDelivery from "../../assets/images/car-delivery.svg";
import { Creators as OrderActions } from "../../store/ducks/order";
import { Container, Content, DescriptionContainer, Footer, Header, HeaderFlag, Modal, ModalContent, TableContainer } from './styles';

function ShowDeliverySelectedStoreModal() {
  const dispatch = useDispatch();
  const { showDeliverySelectedStore, deliveryStoreData, checkoutData, loading } = useSelector(state => state.order);

  function finishOrder() {
    dispatch(OrderActions.orderRequest({ ...checkoutData }, true));
  }

  function closeModal() {
    dispatch(OrderActions.hideDeliverySelectedStoreModal());
  }

  useEffect(() => {
    return () => {
      dispatch(OrderActions.hideDeliverySelectedStoreModal());
    }
  }, [dispatch])

  return (
    <>
      {showDeliverySelectedStore && (
        <Container>
        <Modal>
          <img src={CarDelivery} alt="Ìcone carro entrega" />
          <HeaderFlag />
          <ModalContent>
          <Header>
            <h1>Fora de Horário de Entrega</h1>
          </Header>

          <Content>
            <DescriptionContainer>
              <p>
              Não estamos em nosso horário de entrega no momento,
              mas caso você aceite, receberá seu pedido no horário listado abaixo.
              </p>
            </DescriptionContainer>
            <TableContainer>
              <thead>
                <tr>
                  <th>Próximo horário de entrega:</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>{(deliveryStoreData.lojaHorario.dia ? deliveryStoreData.lojaHorario.dia.descricao[0].toUpperCase() + deliveryStoreData.lojaHorario.dia.descricao.substr(1) : format(addDays(new Date(deliveryStoreData.lojaHorario.data), 1), 'dd/MM/yyy'))}</td>
                <td>A partir de {deliveryStoreData.lojaHorario.hora_inicio_entrega.slice(0, -3)}</td>
              </tr>
              </tbody>
            </TableContainer>
          </Content>

          <Footer>
            <button onClick={() => finishOrder()}>{loading ? (<><Loader type="Oval" color="#FFFFFF" height={16} width={16} /> Confirmando compra...</>) : "Confirmar Compra" }</button>
            <p onClick={() => closeModal()}>Não, obrigado</p>
          </Footer>
          </ModalContent>
        </Modal>
      </Container>
      )}
    </>
  );
}

export default ShowDeliverySelectedStoreModal;
