import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ItemList from '../../components/ItemList';
import { useParams } from 'react-router-dom';
import { Container, LoaderContainer } from './styles';
import { Creators as BannersActions } from "../../store/ducks/banners";
import Loader from "react-loader-spinner";
import SubHeader from "../../components/SubHeader";
import { ThemeContext } from "styled-components";
import MainLayout from '../../components/MainLayout';

function BannerProducts({ history }) {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { bannerProducts, loading } = useSelector(state => state.banners);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    dispatch(BannersActions.getBannersProductsRequest(uuid));
  }, [dispatch, uuid])

  return (
    <MainLayout history={history}>
      <SubHeader
        title="Produtos relacionados ao banner"
        withBackButton
      />
      <Container>
        {loading ? (
          <LoaderContainer>
            <span>Carregando produtos</span>
            <Loader
              type="ThreeDots"
              color={theme.colors.primary}
              height={30}
              width={30}
            />
          </LoaderContainer>
        ) : <ItemList products={bannerProducts} />}
      </Container>
    </MainLayout>
  );
}

export default BannerProducts;
