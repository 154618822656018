import ShoppingCartIcon from "@material-ui/icons/ShoppingBasketRounded";
import { darken, lighten } from "polished";
import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto 0 auto;
  display: inline-flex;
  width: 100%;
  justify-content: center;
`;

export const NoResultsFound = styled.div`
  left:45%;
  top:40%;
  position: absolute;

  @media (max-width: 500px) {
    left:25%;
    top:30%;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 18px;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  b {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 22px;
  }
`;

export const LoaderContainer = styled.div`
  display: ${props => (props.visible ? "flex" : "none")};
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ContainerProducts = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  `;

export const ContainerProductList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  /* column-gap: 24px;
  row-gap: 40px; */
  column-gap: 16px;
  row-gap: 24px;

  @media ${device.laptop}{
    column-gap: 24px;
    row-gap: 40px;
  }

  @media (max-width: 627px) {
    justify-content: center;

  }
`;

export const FilterContainer = styled.div`
  margin-top: 90px;
  @media (max-width: 520px) {
    position:relative;
    display: none;
  }
`;

export const Product = styled.li`
  cursor: pointer;
  background: ${props => props.theme.colors.white};
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 16%;
  display: flex;
  border-radius: 3px;
  max-width: 100%;
  box-shadow: 0px 1px 2px #ced6e0;
  position: relative;
  justify-content: space-between;

  > div {
    height: 100%;
  }

  a.special-promotion {
      background: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.subtitleDark};
      display: flex;
      align-items: center;
      transition: background 100ms;
      border: none;

      :hover {
        background: ${props => darken(0.05, props.theme.colors.secondary)};
      }

      div.special-promotion-wrapper {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;
          margin: 4px;
          padding: 4px;
          align-items: center;
          position: relative;

          &:after {
            top: -16px;
            display: flex;
            text-align: center;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 8px;
            content: "LEVE + PAGUE -";
            font-size: 1.0rem;
            position: absolute;
            height: 20px;
            background: ${props => props.theme.colors.success};
          }

          div.special-promotion-label {
            font-size: 1.4rem;
            font-weight: 300;
            font-style: italic;

            justify-content: flex-start;
            text-align: left;

            strong {
              font-size: 1.4rem;
              font-weight: 400;
            }

            span, strong {
              margin-left: 8px;
            }
          }

          div.special-promotion-price {
            display: flex;
            align-items: center;
            font-style: italic;
            font-size: 2.2rem;
            font-weight: 400;

            span.prefix, span.suffix {
              margin-right: 4px;
              font-weight: 300;
              font-size: 1.2rem;
              font-style: italic;
            }

            span.suffix {
              margin-left: 4px;
            }
          }
      }

      &:hover {

      }
  }

   button.add-basket-btn {
    background: ${lighten(0.03, "#fff")};
    color: ${props => props.theme.colors.bodyLight};
    display: flex;
    align-items: center;
    transition: background 100ms;
    border: none;
    border-top: 1px solid #dfe6e9;

    &:hover {
      background: ${props => props.theme.colors.primary};
      color: #fff;

      svg {
        fill: #fff;
      }

      div {
        background: rgba(0, 0, 0, 0.1);

        span {
          background: ${props => props.theme.colors.secondary};
        }
      }
    }


    div {
      display: flex;
      align-items: center;
      padding: 12px;
      background: rgba(0, 0, 0, 0.04);
      position: relative;

      span {
        top: 4px;
        right: 4px;
        height: 20px;
        display: flex;
        padding: 0 4px;
        z-index: 0;
        color: ${props => props.theme.colors.bodyDark};
        background: ${props => props.theme.colors.secondary};
        position: absolute;
        flex-wrap: wrap;
        font-size: 0.75rem;
        min-width: 20px;
        box-sizing: border-box;
        align-items: center;
        font-weight: 500;
        align-content: center;
        border-radius: 10px;
        flex-direction: row;
        justify-content: center;
        font-size: 1.2rem;
      }

      svg {
        margin-right: 5px;
      }
    }

    span {
      flex: 1;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
    }
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 15px 0;
  height: 100px;
  margin-top: 70px;

  h2 {
    font-size: 22px;
    color: #007d44;
    text-transform: uppercase;
    font-weight: 500;
  }

  h4 {
    font-size: 18px;
    word-wrap: wrap;
    padding: 5px 0;
    color: #8395a7;
    font-weight: 300;
  }
`;

export const Discount = styled.div`
  position: absolute;
  min-width: 60px;
  padding: 0 5px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${props => props.theme.colors.bodyDark};
  background: ${props => props.theme.colors.discountFlag};
  text-align: center;
  display: ${props => (props.visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
`;

export const FlagNewProduct = styled.div`
  display: ${props => (props.visible ? "flex" : "none")};
  top: ${props => (props.existentFlag ? "32px" : "0")};
  position: absolute;
  min-width: 60px;
  padding: 0 5px;
  height: 30px;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.bodyDark};
  font-weight: 400;
  background: #00bfdf;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const AddCartIcon = styled(ShoppingCartIcon)`
  color: ${props => props.theme.colors.bodyLight};
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${props => props.theme.colors.white};
  height: 170px;

  img {
    object-fit: contain;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;

export const ProductName = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${props => props.theme.colors.headingLight};
  text-transform: capitalize;

  font-size: 1.6rem;

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

export const Info = styled.div`
  margin-top: auto;
  padding: 10px 15px;
  word-wrap: break-word;

  h4 {
    font-weight: normal;
    color: ${props => lighten(0.2, props.theme.colors.headingLight)};
    padding-top: 5px;
    font-size: 1.6rem;
  }

  .wrapper {
    margin-top: 5px;
    margin-bottom: 8px;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;

    h6 {
      display: block;
      font-size: 23px;
      font-weight: 500;
      padding: 5px 15px 5px 0;
      color: ${props => props.theme.colors.headingLight};
    }

    img {
      width: 55px;
      height: 35px;
    }
  }

  span.not-available {
    color: ${props => props.theme.colors.secondary};
    font-size: 15px;
    font-weight: 400;
  }
`;

export const ContainerProductFound = styled.div`
  span{
    font-size: 14px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
`;

export const LoadMore = styled.button`
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  min-width: 40%;
  border-radius: 6px;
  padding: 16px 18px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 0 0 #e6e6e6;
  border: 1px solid #dde1e7;
  outline: none;
  transition: 100ms background;

  svg {
    margin-right: 10px;
  }

  :disabled {
    cursor: wait;
  }

  :hover {
    background: ${darken(0.05, "#fff")};
    color: ${darken(0.5, "#fff")};
  }

  span {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: ${props => lighten(0.2, props.theme.colors.bodyLight)};
  }
`;
