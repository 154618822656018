import produce from "immer";

export const Types = {
  RECOVER_PASSWORD_REQUEST: "@password/RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_SUCCESS: "@password/RECOVER_PASSWORD_SUCCESS",
  RECOVER_PASSWORD_FAILURE: "@password/RECOVER_PASSWORD_FAILURE",

  NEW_PASSWORD_REQUEST: "@password/NEW_PASSWORD_REQUEST",
  NEW_PASSWORD_SUCCESS: "@password/NEW_PASSWORD_SUCCESS",
  NEW_PASSWORD_FAILURE: "@password/NEW_PASSWORD_FAILURE",

  VERIFY_TOKEN_REQUEST: "@password/VERIFY_TOKEN_REQUEST",
  VERIFY_TOKEN_SUCCESS: "@password/VERIFY_TOKEN_SUCCESS",
  VERIFY_TOKEN_FAILURE: "@password/VERIFY_TOKEN_FAILURE",

  UPDATE_PASSWORD_REQUEST: "@password/UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "@password/UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "@password/UPDATE_PASSWORD_SUCCESS"
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  tokenIsValid: false
};

export default function password(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.RECOVER_PASSWORD_REQUEST: {
        draft.loading = true;
        draft.error = null;
        draft.success = false;
        break;
      }

      case Types.RECOVER_PASSWORD_SUCCESS: {
        draft.loading = false;
        draft.error = null;
        draft.success = true;
        break;
      }
      case Types.RECOVER_PASSWORD_FAILURE: {
        draft.error = { type: "INVALID_EMAIL_ERROR", message: action.payload.message };
        draft.success = false;
        break;
      }

      case Types.NEW_PASSWORD_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.NEW_PASSWORD_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.NEW_PASSWORD_FAILURE: {
        draft.error = action.payload.message;
        break;
      }

      case Types.VERIFY_TOKEN_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.VERIFY_TOKEN_SUCCESS: {
        draft.loading = false;
        break;
      }

      case Types.UPDATE_PASSWORD_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.UPDATE_PASSWORD_SUCCESS: {
        draft.loading = false;
        break;
      }

      case Types.UPDATE_PASSWORD_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  recoverPasswordRequest: email => ({
    type: Types.RECOVER_PASSWORD_REQUEST,
    payload: { email }
  }),

  recoverPasswordSuccess: () => ({
    type: Types.RECOVER_PASSWORD_SUCCESS
  }),

  recoverPasswordFailure: message => ({
    type: Types.RECOVER_PASSWORD_FAILURE,
    payload: { message }
  }),

  newPasswordRequest: (password, token) => ({
    type: Types.NEW_PASSWORD_REQUEST,
    payload: { password, token }
  }),

  newPasswordSuccess: () => ({
    type: Types.NEW_PASSWORD_SUCCESS
  }),

  newPasswordFailure: message => ({
    type: Types.NEW_PASSWORD_FAILURE,
    payload: { message }
  }),

  verifyTokenRequest: token => ({
    type: Types.VERIFY_TOKEN_REQUEST,
    payload: { token }
  }),

  verifyTokenSuccess: () => ({
    type: Types.VERIFY_TOKEN_SUCCESS
  }),

  verifyTokenFailure: message => ({
    type: Types.VERIFY_TOKEN_FAILURE,
    payload: { message }
  }),

  updatePasswordRequest: data => ({
    type: Types.UPDATE_PASSWORD_REQUEST,
    payload: { ...data }
  }),

  updatePasswordSuccess: message => ({
    type: Types.UPDATE_PASSWORD_SUCCESS,
    payload: { message }
  }),

  updatePasswordFailure: message => ({
    type: Types.UPDATE_PASSWORD_FAILURE,
    payload: { message }
  })
};
