import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ErrorActions } from "../../store/ducks/error";
import { ErrorContainer } from "./styles";
import { FiX } from "react-icons/fi";
import ReactHtmlParser from "react-html-parser";

export default function Error(props) {
  const { key, message } = useSelector(state => state.error);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(ErrorActions.clearError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    props.errorKey === key && (
      <ErrorContainer style={props.style}>
        <span>{ReactHtmlParser(message)}</span>
        <button onClick={() => dispatch(ErrorActions.clearError())}>
          <FiX />
        </button>
      </ErrorContainer>
    )
  );
}
