import React, { createContext, useState } from "react";

export const FilterContext = createContext({filter: [], setFilters: () => null})

export function FilterProvider({ children }) {
  const [filter, setFilter] = useState([]);

  function setFilters(itensFiltro) {
    setFilter(itensFiltro)
  }

  return (
    <FilterContext.Provider value={{ filter, setFilters}}>
      {children}
    </FilterContext.Provider>
  )
}
