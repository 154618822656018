import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import linha from "../../assets/images/LineSteps.svg";
import etapa1 from "../../assets/images/Step1.svg";
import etapa2 from "../../assets/images/Step2.svg";
import etapa2Vazia from "../../assets/images/Step2Off.svg";
import etapa3 from "../../assets/images/Step3.svg";
import etapa3Vazia from "../../assets/images/Step3Off.svg";
import etapa4 from "../../assets/images/Step4.svg";
import etapa4Vazia from "../../assets/images/Step4Off.svg";
import { Container, Steps, StyledLinkLogo } from './styles';

const BuySteps = ({ step, history }) => {
  const { data } = useSelector(state => state.store);

  const handleNextPage = (url) => {
    if (step < 4)
      history.push(url);
  };

  return (
    <Container>
      <div className='container-logo-steps'>
        <ToastContainer autoClose={2500} />
        {data && data.empresa && data.empresa.url && (
          <StyledLinkLogo to="/">
            <img
              src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
              title={data?.empresa?.nome}
              alt={`${data?.empresa?.nome}`}
            />
          </StyledLinkLogo>

        )}
        <Steps>
          <img className='line' src={linha} width={50} alt={""}></img>
          <div className='lineEvo' style={{ "width": step > 1 && step < 4 ? ((100 * (step - 1)) + 75) + "px" : (step == 4 ? "325px" : "75px") }} />
          <img src={etapa1} onClick={()=> handleNextPage("/carrinho")} width={50} title={"Cesta de Compras"}></img>
          {step > 1 ? (
            <img src={etapa2} onClick={()=> handleNextPage("/checkout/entrega")} width={50} title={"Retirada dos Produtos"}></img>
          ) : (
            <img src={etapa2Vazia} onClick={()=> handleNextPage("/checkout/entrega")} width={50} title={"Retirada dos Produtos"}></img>
          )}
          {step > 2 ? (
            <img src={etapa3} width={50} title={"Forma de Pagamento"}></img>
          ) : (
            <img src={etapa3Vazia} width={50} title={"Forma de Pagamento"}></img>
          )}
          {step > 3 ? (
            <img src={etapa4} width={50} title={"Compra Finalizada"}></img>
          ) : (
            <img src={etapa4Vazia} width={50} title={"Compra Finalizada"}></img>
          )}
        </Steps>
      </div>
    </Container>

  );
};

export default BuySteps;
