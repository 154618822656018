import styled from 'styled-components';

export const Container = styled.div`
      /* visibility: hidden; */
      background-color: #404040;
      color: #fff;
      font-weight: 400;
      text-align: center;
      border-radius: 4px;
      min-height: 50px;
      position: fixed;
      z-index: 9999;
      width: calc(100% - 64px);
      max-width: 450px;
      margin: 0 auto;
      line-height: 24px;
      padding: 16px;
      bottom: 30px;
      left: 32px;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      animation: fadein 0.5s;

      @media (max-width: 550px) {
          left: 0;
          min-width: 100%;
          border-radius: 0;
          bottom: 0;
      }

      @-webkit-keyframes fadein {
        from {
          bottom: 0;
          opacity: 0;
        }
        to {
          bottom: 30px;
          opacity: 1;
        }
      }

      @keyframes fadein {
        from {
          bottom: 0;
          opacity: 0;
        }
        to {
          bottom: 30px;
          opacity: 1;
        }
      }
`;
