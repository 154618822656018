import axios from "axios";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { ReactComponent as DeliveryTruck } from '../../assets/images/truck-icon.svg';
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import Error from "../../components/Error";
import PartlyFulfilledOrderModal from "../../components/PartlyFulfilledOrderModal";
import SubHeader from '../../components/SubHeader';
import api from '../../services/api';
import { setTitle } from "../../services/browser";
import { Creators as AddressActions } from "../../store/ducks/address";
import { Creators as ErrorActions } from "../../store/ducks/error";
import { Creators as OrderActions } from "../../store/ducks/order";
import { Creators as StoreByUserActions } from "../../store/ducks/storeByUser";
import { formatPrice } from "../../util/format";
import { ButtonFooterWrap, ContentWrap, PriceInfoWrap, TitleWrap, Wrap } from "../Cart/styles";
import { AddressItem, AddressList, ChooseStoreForm, Container, DeliveryConfirmButton, DeliverySummary, FieldErrorMessage, FormGroup, Header, Image, InputField, Label, LoadingContainer, NewAddressForm, PriceInfoContent, RadioGroup, SecondStep, StoreItem, StoreList, StoreListTitle, Tabs, ThirdStep, WithdrawalByThirdForm } from './styles';
import { AddressSchema, ThirdPartyWithdrawalSchema, cepMask, cpfMask, phoneMask } from "./validation";

function AddressCheckout({ history }) {

  const [ufs, setUfs] = useState([]);
  const [loading, setLoading] = useState({ loading: false, message: '' });
  const [stores, setStores] = useState([]);
  const [cities, setCities] = useState({
    cidades: []
  });
  const [neighborhoods, setNeighborhoods] = useState({
    bairros: []
  });
  const [uf, setUf] = useState(null);
  const [city, setCity] = useState(null);
  const [retiradaTerceiro, setRetiradaTerceiro] = useState('false')
  const [nomeTerceiro, setNomeTerceiro] = useState('')
  const [cpfTerceiro, setCPFTerceiro] = useState('')
  const [telefoneTerceiro, setTelefoneTerceiro] = useState('')
  const [neighborhood, setNeighborhood] = useState(null);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [showLoadingStore, setShowLoadingStore] = useState(false);
  const dispatch = useDispatch();
  const { createdAddress, data: addresses, loading: loadingAddresses, newAddressLoading } = useSelector(state => state.address);
  const store = useSelector(state => state.storeByUser.data);
  const user = useSelector((state) => state.user.profile);
  const cart = useSelector((state) => state.cart.data);
  const [toastId, setToastId] = useState(null);
  const [choosenOptions, setChosenOptions] = useState({
    deliveryType: null,
    address: null,
    selectedStore: null,
    currentStep: 1
  });
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { amount } = useSelector(state => state.cart);
  const { address, dadosEntrega } = getInfoCheckout();
  const [searchStarted, setSearchStarted] = useState(false);
  const theme = useContext(ThemeContext);
  const habilitarCarregamentoAutomaticoEndereco = false;

  const formRef = useRef()

  const handleSubmitFormTerceiro = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      if (formRef.current.state?.errors?.cpf || formRef.current.state?.errors?.telefone_contato || formRef.current.state?.errors?.nome_completo)
        return;
    }
  }


  useEffect(() => {
    if (dadosEntrega && dadosEntrega.dadosTerceiro) {
      setRetiradaTerceiro(dadosEntrega.dadosTerceiro.retiradaTerceiro)
      setNomeTerceiro(dadosEntrega.dadosTerceiro.nomeCompleto)
      setCPFTerceiro(dadosEntrega.dadosTerceiro.cpf)
      setTelefoneTerceiro(dadosEntrega.dadosTerceiro.telefone)
    }
  }, [dadosEntrega])

  useEffect(() => {
    setTitle("Checkout / Entrega");
    if (cart.length === 0) {
      history.push("/");
    }
  }, [cart.length, history]);

  const handleClose = () => {
    setLoading({ loading: false, message: '' });

    let store = choosenOptions.selectedStore;
    let deliType = choosenOptions.deliveryType

    if (deliType === 'delivery-address') {
      handleSetAddressSelected(choosenOptions.address, false, cart);
    } else {
      handleDistrictChange(choosenOptions.selectedStore.uf, choosenOptions.selectedStore.cidade, choosenOptions.selectedStore.bairro, false, cart);
    }

    handleSetSelectedStore(store, deliType, false);
  };

  function getInfoCheckout() {
    return JSON.parse(localStorage.getItem("@ConexaoGAM:checkout")) || {};
  }

  function notifyCPFIsSameOwnerAccount() {
    if (!toast.isActive(toastId)) {
      const newToast = toast.info(`O CPF informado é o mesmo do próprietário da conta. Por favor, digite outro CPF ou informe que não será retirado por um terceiro.`, {
        position: "top-center",
        className: "toast",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setToastId(newToast);
    }
  }

  function obterTextoBotaoConfirmacao(isSubmitting) {
    if (choosenOptions.selectedStore?.id) {
      return isSubmitting ? 'CONFIRMANDO RETIRADA... ' : 'CONFIRMAR RETIRADA';
    } else {
      return isSubmitting ? 'CONFIRMANDO ENTREGA... ' : 'CONFIRMAR ENTREGA';
    }
  }

  async function handleContinueWithAddressSelected() {
    try {
      setLoading({ loading: true, message: 'Verificando Endereço...' })

      if (retiradaTerceiro === "true")
        handleSubmitFormTerceiro();

      const { data } = await api.get(
        `/customers/${user.uuid}/address/${choosenOptions.address.id}/check-store-delivery${store !== null ? `/${store.uuid}` : ''}`
      );
      const custo_entrega = data.custo_entrega ?? null;

      if (store !== null && custo_entrega !== null) {
        dispatch(StoreByUserActions.setStore({ ...store, custo_entrega }, false))
      }

      let finalBasket = [];

      cart.forEach((product) => {
        const productFound = finalBasket.find((item) => item.id === product.id);

        if (!productFound) {
          finalBasket.push({ ...product, quantidade: product.quantidade + (product.brotherQuantity ?? 0) })
        }
      })

      api.post(
        `/orders/check-store-stock${choosenOptions.selectedStore !== null ? `/${choosenOptions.selectedStore.uuid}` : ''}`,
        {
          storeId: choosenOptions.selectedStore.id,
          items: finalBasket
        }
      ).then((param) => {
        if (param.data.itensNaoAtendidos.length > 0) {
          dispatch(OrderActions.showOutOfStockItems(param.data));
        } else {
          setCheckoutAddress({
            address: choosenOptions.address,
            dadosEntrega: {
              tipoEntrega: "RECEBER_EM_CASA",
              lojaSelecionada: choosenOptions.selectedStore,
              dadosTerceiro: null,
              enderecoCliente: choosenOptions.address
            }
          });

          history.push("/checkout/pagamento");
          setLoading({ loading: false, message: '' });

          setChosenOptions({
            ...choosenOptions,
            address: null,
            selectedStore: null,
            currentStep: 3
          })
        }
      }).catch((error) => {
        console.log(error);
      })

    } catch (err) {
      setLoading({ loading: false, message: '' });
      window.scrollTo(0, 0);
      setErrorDeliverAddress(err?.response?.data?.user)
    }
  }

  function setErrorDeliverAddress(msgErro) {
    console.log(msgErro)
    if (msgErro && msgErro !== '')
      dispatch(ErrorActions.setError("deliver-address", msgErro));
  }

  function setClearError() {
    dispatch(ErrorActions.clearError());
  }

  function saveDadosTerceiro(retiradaTerceiro, nomeCompleto, cpf, telefone) {
    const { address, dadosEntrega } = getInfoCheckout();
    if (dadosEntrega) {
      dadosEntrega.dadosTerceiro = retiradaTerceiro === "false" ? null : { retiradaTerceiro: 'true', nomeCompleto: nomeCompleto, cpf: cpf, telefone: telefone }
      setCheckoutAddress({
        dadosEntrega,
        address
      });
    } else {
      if (retiradaTerceiro === "true") {
        let novoDadosEntrega = { dadosTerceiro: { retiradaTerceiro: 'true', nomeCompleto: nomeCompleto, cpf: cpf, telefone: telefone } }
        setCheckoutAddress({
          dadosEntrega: novoDadosEntrega,
          address
        });
      }

    }
  }

  function handleContinueToPayment(values) {
    saveDadosTerceiro(retiradaTerceiro, nomeTerceiro, cpfTerceiro, telefoneTerceiro)

    if (retiradaTerceiro === "true") {
      var cpfWithoutMask = cpfTerceiro?.replace(/[^\d]+/g, "")
      if (cpfWithoutMask === user.cpf) {
        notifyCPFIsSameOwnerAccount()
        return;
      }
    }

    let finalBasket = [];

    cart.forEach((product) => {
      const productFound = finalBasket.find((item) => item.id === product.id);

      if (!productFound) {
        finalBasket.push({ ...product, quantidade: product.quantidade + (product.brotherQuantity ?? 0) })
      }
    })

    api.post(
      `/orders/check-store-stock${choosenOptions.selectedStore !== null ? `/${choosenOptions.selectedStore.uuid}` : ''}`,
      {
        storeId: choosenOptions.selectedStore.id,
        items: finalBasket
      }
    ).then((param) => {
      if (param.data.itensNaoAtendidos.length > 0) {
        dispatch(OrderActions.showOutOfStockItems(param.data));
      } else {
        setCheckoutAddress({
          dadosEntrega: {
            tipoEntrega: "RETIRAR_NA_LOJA",
            lojaSelecionada: choosenOptions.selectedStore,
            dadosTerceiro: retiradaTerceiro === "false" ? null : { retiradaTerceiro: 'true', nomeCompleto: nomeTerceiro, cpf: cpfTerceiro, telefone: telefoneTerceiro },
            enderecoCliente: null,
          },
          address: null,
        });

        history.push("/checkout/pagamento");
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  function handleSubmit(values, { resetForm }) {
    const {
      nome_contato,
      telefone_contato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge,
    } = values;

    const telefone = telefone_contato.replace(/[^a-zA-Z0-9]/g, "");
    const cepWithoutMask = cep.replace(/[^a-zA-Z0-9]/g, "");

    dispatch(
      AddressActions.addressStoreRequest({
        nome_contato,
        telefone_contato: telefone,
        cep: cepWithoutMask,
        uf,
        cidade,
        bairro,
        numero,
        rua,
        complemento,
        ibge,
      })
    );

    setTimeout(() => {
      resetForm({
        nome_contato: "",
        telefone_contato: "",
        cep: "",
        uf: "",
        cidade: "",
        bairro: "",
        numero: "",
        rua: "",
        complemento: "",
        ibge: "",
      });

      setShowNewAddressForm(!setShowNewAddressForm);
      window.scrollTo(0, 0);
    }, 500)
  }

  function setCheckoutAddress(paramObject) {
    localStorage.setItem("@ConexaoGAM:checkout", JSON.stringify(paramObject));
  }

  useEffect(() => {
    if (choosenOptions.deliveryType === "delivery-address") {
      dispatch(AddressActions.getAddressesRequest());
    }
  }, [choosenOptions.deliveryType]); //eslint-disable-line

  useEffect(() => {
    const existentCreated = (addresses && createdAddress) && addresses.find(e => e.id === createdAddress.id)
    setChosenOptions({
      ...choosenOptions,
      address: existentCreated ? existentCreated : null,
      selectedStore: null,
      currentStep: existentCreated ? 3 : 2
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, dispatch])

  function handleSetDeliveryTypeWhithOutClear(deliveryType, actionUser) {
    let storeModified;
    setStores([])

    if (deliveryType === 'pickup-store') {
      setShowNewAddressForm(false)
      if (store === null) {
        setUf('');
        setCity('');
        setNeighborhood('');

        setCities({
          cidades: []
        });
        setNeighborhoods({
          bairros: []
        });

        setShowLoadingStore(!actionUser);

        getUfs(actionUser);
      }
      else {
        let mostrar_horario_retirada = `${store.horario_comeco_retirada.slice(0, 5)} às ${store.horario_final_retirada.slice(0, 5)}`;
        let mostrar_tempo_retirada = `${store.tempo_para_retirada}`;

        storeModified = { ...store, mostrar_horario_retirada, mostrar_tempo_retirada }

        handleValidatePickupStore(storeModified.id, actionUser);
      }
    } else if (deliveryType === 'delivery-address') {
      setShowLoadingStore(!actionUser);
      handleSetAddressSelected(address, false, null);
    }

    setChosenOptions({
      ...choosenOptions,
      deliveryType,
      address: null,
      selectedStore: store !== null ? storeModified : null,
      currentStep: 2
    })
  }

  function handleSetDeliveryType(deliveryType, actionUser) {
    setCheckoutAddress(null);
    handleSetDeliveryTypeWhithOutClear(deliveryType, actionUser);
  }

  useEffect(() => {
    if (dadosEntrega?.tipoEntrega) {
      handleSetDeliveryTypeWhithOutClear(dadosEntrega.tipoEntrega === "RETIRAR_NA_LOJA" ? "pickup-store" : "delivery-address", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosEntrega?.tipoEntrega])

  function getProductsInCart(updatedCart) {
    if (updatedCart) {
      return updatedCart.map(item => {
        return {
          productId: item.id,
          amount: item.quantidade
        }
      });
    } else {
      return cart.map(item => {
        return {
          productId: item.id,
          amount: item.quantidade
        }
      });
    }
  }

  function handleSetAddressSelected(address, actionUser, updatedCart) {
    let existeEndereco = false;
    if (addresses && address) {
      existeEndereco = addresses.find(e => e.id === address.id);
    }

    if (address && existeEndereco) {
      const products = getProductsInCart(updatedCart);
      if (products && products.length > 0) {
        setShowLoadingStore(true)
        setChosenOptions({
          ...choosenOptions,
          deliveryType: 'delivery-address',
          address,
          selectedStore: null,
          currentStep: 3
        })
        setClearError();

        api.post(`/stores/customerAddress/${address.id}`, { products: products, storeIdPrimary: store?.id })
          .then(response => {
            buildSelectStore(response.data, actionUser, 'delivery-address');
            setShowLoadingStore(false)
          }).catch(err => {
            setStores([])
            setShowLoadingStore(false)
            setErrorDeliverAddress(err?.response?.data?.user)
          })
      }
    } else {
      setShowLoadingStore(false)
    }
  }

  function handleSetSelectedStore(store, deliveryType, actionUser) {
    let chooseOptionsLocal = {
      ...choosenOptions,
      deliveryType: deliveryType,
      selectedStore: store,
      currentStep: 3
    }
    if (deliveryType === 'pickup-store') {
      chooseOptionsLocal = {
        ...chooseOptionsLocal,
        address: null
      }
    } else if (deliveryType === 'delivery-address' && !chooseOptionsLocal.address && address && !actionUser) {
      chooseOptionsLocal = {
        ...chooseOptionsLocal,
        address: address
      }
    }
    setChosenOptions(chooseOptionsLocal)
  }

  function getUfs(actionUser) {
    api.get('/stores/states')
      .then(response => {
        setUfs(response.data);

        if (habilitarCarregamentoAutomaticoEndereco && !actionUser && dadosEntrega && dadosEntrega.lojaSelecionada && dadosEntrega.lojaSelecionada.uf) {
          handleUF(dadosEntrega.lojaSelecionada.uf, false);
        }
      })
  }

  function handleUF(uf, actionUser) {
    setUf(uf)
    setCity('');
    setNeighborhood('');
    setCities({
      cidades: []
    });
    setNeighborhoods({
      bairros: []
    });
    setStores([]);

    api.get(`/stores/cities/${uf}`)
      .then(response => {
        setCities(response.data);

        if (habilitarCarregamentoAutomaticoEndereco && !actionUser && dadosEntrega && dadosEntrega.lojaSelecionada && dadosEntrega.lojaSelecionada.uf && dadosEntrega.lojaSelecionada.cidade) {
          handleCityChange(dadosEntrega.lojaSelecionada.cidade, dadosEntrega.lojaSelecionada.uf, false);
        }
      })
  }

  function buildSelectStore(data, actionUser, deliveryType) {
    let lojas = data;

    lojas = lojas.map((loja) => {
      let mostrar_horario_retirada = `${loja.horario_comeco_retirada.slice(0, 5)} às ${loja.horario_final_retirada.slice(0, 5)}`;
      let mostrar_tempo_retirada = `${loja.tempo_para_retirada}`;
      let distancia = 0;
      if (loja.distancia && loja.distancia > 0)
        distancia = (loja.distancia / 1000).toFixed(2)

      return { ...loja, mostrar_horario_retirada, mostrar_tempo_retirada, distancia };
    })
    setStores(lojas)

    if (habilitarCarregamentoAutomaticoEndereco && !actionUser && dadosEntrega && dadosEntrega.lojaSelecionada) {

      const selectedLoja = lojas.find(p => p.id === dadosEntrega.lojaSelecionada.id);
      handleSetSelectedStore(selectedLoja, deliveryType, actionUser);
    }
  }

  const handleUFChange = useCallback((e) => {
    handleUF(e.target.value, true);
  }, [uf])//eslint-disable-line

  const handleCityChange = useCallback((cidade, uf, actionUser) => {
    setCity(cidade);
    setNeighborhood('');
    setNeighborhoods({
      bairros: []
    });
    setStores([]);

    api.get(`/stores/districts/${uf}/${cidade}`)
      .then(response => {
        setNeighborhoods(response.data);

        if (habilitarCarregamentoAutomaticoEndereco && !actionUser && dadosEntrega && dadosEntrega.lojaSelecionada && dadosEntrega.lojaSelecionada.uf && dadosEntrega.lojaSelecionada.cidade && dadosEntrega.lojaSelecionada.bairro) {
          handleDistrictChange(dadosEntrega.lojaSelecionada.uf, dadosEntrega.lojaSelecionada.cidade, dadosEntrega.lojaSelecionada.bairro, false, null);
        }
      })
  }, [])//eslint-disable-line

  const handleDistrictChange = useCallback((uf, cidade, bairro, actionUser, updatedCart) => {
    setNeighborhood(bairro.toUpperCase());
    setStores([]);

    const products = getProductsInCart(updatedCart);

    if (products && products.length > 0) {
      setShowLoadingStore(true)
      setClearError();
      api.post(`/stores/pickup/${uf}/${cidade}/${bairro}`, { products: products, storeIdPrimary: store?.id })
        .then(response => {
          buildSelectStore(response.data, actionUser, 'pickup-store')
          setShowLoadingStore(false)
        }).catch(err => {
          setStores([])
          setShowLoadingStore(false)
          setErrorDeliverAddress(err?.response?.data?.user)
        })
    }
  }, [])//eslint-disable-line

  function handleValidatePickupStore(lojaId, actionUser) {
    setShowLoadingStore(true)
    setClearError();
    const products = getProductsInCart(null);
    api.post(`/stores/validate/stock/${lojaId}`, { products: products })
      .then(response => {
        buildSelectStore([response.data], actionUser, 'pickup-store')
        setShowLoadingStore(false)
      }).catch(err => {
        setStores([])
        setShowLoadingStore(false)
        setErrorDeliverAddress(err?.response?.data?.user)
      })
  }

  function changeRetiradaTerceiro(value) {
    if (value === 'true') {
      setRetiradaTerceiro(value)
    } else {
      setRetiradaTerceiro('false')
      setNomeTerceiro('')
      setCPFTerceiro('')
      setTelefoneTerceiro('')
    }
    saveDadosTerceiro(value, nomeTerceiro, cpfTerceiro, telefoneTerceiro);
  }

  function changeNomeTerceiro(value) {
    setNomeTerceiro(value);
    saveDadosTerceiro(retiradaTerceiro, value, cpfTerceiro, telefoneTerceiro);
  }

  function changeCPFTerceiro(value) {
    setCPFTerceiro(value);
    saveDadosTerceiro(retiradaTerceiro, nomeTerceiro, value, telefoneTerceiro);
  }

  function changeTelefoneTerceiro(value) {
    setTelefoneTerceiro(value);
    saveDadosTerceiro(retiradaTerceiro, nomeTerceiro, cpfTerceiro, value);
  }

  useEffect(() => {
    if (choosenOptions === null || choosenOptions.deliveryType === null) {
      handleSetDeliveryType("pickup-store", false);
      setShowLoadingStore(false);
    }

    const subtotal = cart.reduce(
      (acc, product) =>
        acc +
        parseFloat(
          product?.preco_mostrar_cesta?.preco_de || product?.preco_mostrar_cesta
        ) *
        product.quantidade,
      0
    );

    setSubtotal(subtotal);
    setDiscount(subtotal - amount);
  });

  return (
    <BuyStepsTemplate step={2} history={history}>
      <SubHeader
        icon={DeliveryTruck}
        title="Retirada dos produtos"
      />
      <PartlyFulfilledOrderModal handleClose={handleClose} />
      <Container>
        <ContentWrap>
          <Wrap>
            <Tabs>
              <>
                <Image
                  id="pagamento-online"
                  onClick={() => handleSetDeliveryType("pickup-store", true)}
                >
                  <span className={choosenOptions.deliveryType === 'delivery-address' ? "" : "selected"}>Retirar na loja</span>
                </Image>
              </>
              <>
                <Image
                  id="pagamento-local"
                  onClick={() => handleSetDeliveryType("delivery-address", false)}
                >
                  <span className={choosenOptions.deliveryType === 'delivery-address' ? "selected" : ""}>Receber em casa</span>
                </Image>
              </>
            </Tabs>
            <SecondStep>
              {choosenOptions.deliveryType === 'pickup-store' && (
                <>
                  <Header>
                    <span>{store === null ? "Escolha a farmácia para retirada" : "Loja selecionada para retirada"}</span>
                  </Header>
                  <Error errorKey="deliver-address" style={{ marginBottom: 20 }} />
                  {(showLoadingStore && choosenOptions.selectedStore == null) && (
                    <LoadingContainer>
                      <span>Carregando a loja do pedido...</span>
                      <Loader
                        type="ThreeDots"
                        color={theme.colors.primary}
                        height={40}
                        width={40}
                      />
                    </LoadingContainer>
                  )}
                  {store === null && (
                    <ChooseStoreForm>
                      <Formik
                        validationSchema={{}}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div id="wrap">
                              <div className="form-control">
                                <label htmlFor="select-uf">UF</label>
                                <select
                                  id="select-uf"
                                  className="input-select"
                                  name="uf"
                                  value={uf}
                                  onChange={handleUFChange}
                                  onBlur={handleBlur}
                                  disabled={ufs.length === 0}
                                >
                                  <option hidden>UF</option>
                                  {ufs.map((uf, index) => (
                                    <option key={index} value={uf}>{uf}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="form-control city">
                                <label htmlFor="select-city">Cidade</label>
                                <select
                                  id="select-city"
                                  className="input-select"
                                  onChange={(e) => handleCityChange(e.target.value, uf, true)}
                                  onBlur={handleBlur}
                                  name="cidade"
                                  value={city}
                                  disabled={cities.cidades.length === 0}
                                >
                                  <option hidden>Cidades</option>
                                  {cities.cidades.map((city, index) => (
                                    <option key={index} value={city.cidade}>{city.cidade + ' ' + city.quantidade_farmacias}</option>
                                  ))}
                                </select>

                              </div>
                            </div>

                            <div className="form-control neighborhood">
                              <label htmlFor="select-uf">Bairro</label>
                              <select
                                className="input-select"
                                id="select-neighborhood"
                                onChange={(e) => handleDistrictChange(uf, city, e.target.value, true, null)}
                                onBlur={handleBlur}
                                name="bairro"
                                value={neighborhood}
                                disabled={neighborhoods.bairros.length === 0}
                              >
                                <option hidden>Bairros</option>
                                {neighborhoods.bairros.map((neighborhood, index) => (
                                  <option key={index} value={neighborhood.bairro.toUpperCase()}>{neighborhood.bairro + ' ' + neighborhood.quantidade_farmacias}</option>
                                ))}
                              </select>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </ChooseStoreForm>
                  )}
                </>
              )}

              {choosenOptions.deliveryType === 'delivery-address' && (
                <>
                  <Header>
                    <h1>Selecione ou cadastre um novo endereço</h1>
                  </Header>
                  <Error errorKey="deliver-address" style={{ marginBottom: 20 }} />
                  {(loadingAddresses || newAddressLoading) && (
                    <LoadingContainer>
                      <span>Atualizando endereços cadastrados...</span>
                      <Loader
                        type="ThreeDots"
                        color={theme.colors.primary}
                        height={40}
                        width={40}
                      />
                    </LoadingContainer>
                  )}
                  {(showLoadingStore && choosenOptions.selectedStore == null) && (
                    <LoadingContainer>
                      <span>Carregando a loja do pedido...</span>
                      <Loader
                        type="ThreeDots"
                        color={theme.colors.primary}
                        height={40}
                        width={40}
                      />
                    </LoadingContainer>
                  )}
                  {!showNewAddressForm && (
                    <>
                      <AddressList>
                        {addresses.map((address) => (
                          <AddressItem selected={address.id === choosenOptions.address?.id} key={address.id} onClick={() => handleSetAddressSelected(address, true, null)} className="choose-address-btn">
                            <div className="address-info">
                              <h2>{address.endereco}</h2>
                              <p>
                                {address.endereco}, {address.numero}, {address.bairro},{" "}
                                {address.complemento ? address.complemento + "," : null}{" "}
                                {address.cidade.nome}/{address.cidade.uf_sigla}
                              </p>
                            </div>
                            <div id={"pagamento-" + address.id} className={address.id === choosenOptions.address?.id ? "radio selected" : "radio"}>
                              <input id={"radio-" + address.id} value="PI"
                                onChange={(e) => { handleSetAddressSelected(address, true, null); }}
                                onBlur={(e) => { handleSetAddressSelected(address, true, null); }}
                                name="paymentMode" type="radio" checked={address.id === choosenOptions.address?.id} />
                              <label htmlFor={"radio-" + address.id} className="radio-label">Selecionar</label>
                            </div>
                          </AddressItem>
                        ))}

                        {addresses.length > 0 && (
                          <div className="addresses-bottom">
                            <button onClick={() => setShowNewAddressForm(!showNewAddressForm)} className="add-address-btn"> <FiPlus size={18} /> Adicionar novo endereço</button>
                          </div>
                        )}
                      </AddressList>
                    </>
                  )}
                </>
              )}

              {stores.length > 0 && (
                <StoreList>
                  {store === null && (<StoreListTitle>{choosenOptions.deliveryType === 'delivery-address' ? 'Farmácias que entregam no seu endereço' : 'Farmácias encontradas'}</StoreListTitle>)}
                  {stores.map((store) => (
                    <StoreItem storeByUser={store !== null} key={store.id} onClick={() => handleSetSelectedStore(store, choosenOptions.deliveryType, true)}>
                      <div className="store-info">
                        <div className="radio" id="retirada-terceiro-nao">
                          <input id={"radio-" + store.id}
                            onChange={evento => {
                              handleSetSelectedStore(store, choosenOptions.deliveryType, true)
                            }}
                            onBlur={evento => {
                              handleSetSelectedStore(store, choosenOptions.deliveryType, true)
                            }}
                            value={'false'} name="retirada_terceiro" type="radio" checked={store.id === choosenOptions.selectedStore?.id} />
                          <label htmlFor={"radio-" + store.id} className="radio-label"></label>
                        </div>
                        <div>
                          <strong>{store.nome_fantasia} {(store.distancia && store.distancia > 0 ? `- (${store.distancia}km do seu endereço)` : '')}</strong>
                          <div className="store-address-info">
                            <p>{"(" + store.endereco},{" "}
                              {store.endereco_numero ? `${store.endereco_numero},` : ""}{" "}
                              {store.bairro},{" "}{store.cidade}/{store.uf + ")"}</p>
                          </div>
                        </div>
                      </div>
                    </StoreItem>
                  ))}
                </StoreList>
              )}

              {(showNewAddressForm || (addresses.length === 0 && !loadingAddresses && choosenOptions.currentStep === 2 && choosenOptions.deliveryType === "delivery-address")) && (
                <NewAddressForm>
                  <Formik
                    initialValues={{
                      nome_contato: "",
                      telefone_contato: "",
                      cep: "",
                      uf: "",
                      cidade: "",
                      bairro: "",
                      numero: "",
                      rua: "",
                      complemento: "",
                      ibge: "",
                    }}
                    validationSchema={AddressSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      errors,
                      touched,
                      handleSubmit,
                      setFieldValue,
                      isValid,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <FormGroup>
                          {errors.cep && touched.cep ? (
                            <FieldErrorMessage>{errors.cep}</FieldErrorMessage>
                          ) : null}

                          <MaskedInput
                            invalid={errors.cep && touched.cep}
                            id="cep"
                            type="text"
                            name="cep"
                            className="mediumSize"
                            mask={cepMask}
                            onChange={handleChange}
                            onKeyUp={async () => {
                              const cep = values.cep.replace(/[^a-zA-Z0-9]/g, "");
                              if (cep.length === 8) {
                                const { data } = await axios.get(
                                  `https://viacep.com.br/ws/${values.cep}/json`
                                );
                                setFieldValue("uf", data.uf || "");
                                setFieldValue("cidade", data.localidade || "");
                                setFieldValue("rua", data.logradouro || "");
                                setFieldValue("bairro", data.bairro || "");
                                setFieldValue("ibge", data.ibge || "");
                              }
                            }}
                            value={values.cep}
                            onBlur={handleBlur}
                            placeholder="Ex: _____-___"
                            render={(ref, props) => (
                              <InputField ref={(input) => ref(input)} {...props} />
                            )}
                          />

                          <Label>Digite o CEP de entrega</Label>
                        </FormGroup>

                        {values.uf !== "" && (
                          <>
                            <div className="textGroup">
                              <FormGroup>
                                {errors.rua && touched.rua ? (
                                  <FieldErrorMessage>{errors.rua}</FieldErrorMessage>
                                ) : null}

                                <InputField
                                  invalid={errors.rua && touched.rua}
                                  id="rua"
                                  type="text"
                                  name="rua"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.rua}
                                  placeholder="Ex: Rua/Av. Fulano da Silva"
                                />
                                <Label>Rua/Avenida</Label>
                              </FormGroup>
                              <FormGroup>
                                {errors.numero && touched.numero ? (
                                  <FieldErrorMessage>{errors.numero}</FieldErrorMessage>
                                ) : null}

                                <InputField
                                  invalid={errors.numero && touched.numero}
                                  id="numero"
                                  type="text"
                                  name="numero"
                                  className="smallSize"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.numero}
                                  placeholder="Ex: 1191"
                                />
                                <Label>Número</Label>
                              </FormGroup>
                            </div>

                            <div className="textGroup">
                              <FormGroup>
                                {errors.cidade && touched.cidade ? (
                                  <FieldErrorMessage>{errors.cidade}</FieldErrorMessage>
                                ) : null}

                                <InputField
                                  disabled
                                  invalid={errors.cidade && touched.cidade}
                                  id="cidade"
                                  type="text"
                                  name="cidade"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.cidade}
                                  placeholder="Ex: Tubarão"
                                />
                                <Label>Cidade</Label>
                              </FormGroup>
                              <FormGroup>
                                {errors.uf && touched.uf ? (
                                  <FieldErrorMessage>{errors.uf}</FieldErrorMessage>
                                ) : null}
                                <InputField
                                  invalid={errors.uf && touched.uf}
                                  id="uf"
                                  type="text"
                                  name="uf"
                                  className="smallSize"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled
                                  value={values.uf}
                                  placeholder="Ex: Santa Catarina"
                                />
                                <Label>Estado</Label>
                              </FormGroup>
                            </div>

                            <FormGroup>
                              {errors.bairro && touched.bairro ? (
                                <FieldErrorMessage>{errors.bairro}</FieldErrorMessage>
                              ) : null}

                              <InputField
                                invalid={errors.bairro && touched.bairro}
                                id="bairro"
                                type="text"
                                name="bairro"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bairro}
                                placeholder="Ex: Santo Antonio de Pádua"
                              />
                              <Label>Bairro</Label>
                            </FormGroup>

                            <FormGroup>
                              {errors.complemento && touched.complemento ? (
                                <FieldErrorMessage>
                                  {errors.complemento}
                                </FieldErrorMessage>
                              ) : null}

                              <InputField
                                invalid={errors.complemento && touched.complemento}
                                id="complemento"
                                type="text"
                                name="complemento"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.complemento}
                                placeholder="Ex: Andar/Apartamento (opcional)"
                              />
                              <Label>Complemento</Label>
                            </FormGroup>

                            <div className="textGroup">
                              <FormGroup>
                                {errors.nome_contato && touched.nome_contato ? (
                                  <FieldErrorMessage>
                                    {errors.nome_contato}
                                  </FieldErrorMessage>
                                ) : null}

                                <InputField
                                  invalid={errors.nome_contato && touched.nome_contato}
                                  id="nome_contato"
                                  type="text"
                                  name="nome_contato"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.nome_contato}
                                  placeholder="Ex: Fulano da Silva"
                                />
                                <Label>Nome para contato</Label>
                              </FormGroup>
                              <FormGroup>
                                {errors.telefone_contato && touched.telefone_contato ? (
                                  <FieldErrorMessage>
                                    {errors.telefone_contato}
                                  </FieldErrorMessage>
                                ) : null}

                                <MaskedInput
                                  name="telefone_contato"
                                  type="text"
                                  placeholder="Ex: (__) ____-____"
                                  mask={phoneMask}
                                  className="smallSize"
                                  value={values.telefone_contato}
                                  invalid={
                                    errors.telefone_contato && touched.telefone_contato
                                  }
                                  id="registerNumber"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  render={(ref, props) => (
                                    <InputField ref={(input) => ref(input)} {...props} />
                                  )}
                                />
                                <Label>Telefone Contato</Label>
                              </FormGroup>
                            </div>
                          </>
                        )}

                        <DeliveryConfirmButton disabled={!isValid} type="submit">
                          {newAddressLoading ? 'Salvando...' : 'Salvar endereço'}
                        </DeliveryConfirmButton>
                      </form>
                    )}
                  </Formik>
                </NewAddressForm>
              )}

            </SecondStep>

            <ThirdStep>
              {choosenOptions.deliveryType === 'pickup-store' && choosenOptions.selectedStore !== null && stores && stores.length > 0 && (
                <>
                  <div style={{ borderTop: "1px solid " + theme.colors.neutral100, marginTop: "25px" }}>
                    <Header>
                      <h1>Quem vai retirar os produtos?</h1>
                    </Header>
                    <Formik
                      ref={formRef}
                      initialValues={{
                        retirada_terceiro: retiradaTerceiro,
                        nome_completo: nomeTerceiro,
                        cpf: cpfTerceiro,
                        telefone_contato: telefoneTerceiro
                      }}
                      validationSchema={ThirdPartyWithdrawalSchema}
                      onSubmit={handleContinueToPayment}
                      isInitialValid
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        handleReset,
                        isSubmitting,
                        isValid,
                      }) => (
                        <>
                          <WithdrawalByThirdForm>
                            <form id="form-continue-payment" onSubmit={(e) => { handleContinueToPayment(values); e.preventDefault(); }}>
                              <FormGroup>
                                {errors.retirada_terceiro && touched.retirada_terceiro ? (
                                  <FieldErrorMessage>{errors.retirada_terceiro}</FieldErrorMessage>
                                ) : null}

                                <RadioGroup>
                                  <div className="radio" id="retirada-terceiro-nao">
                                    <input
                                      id="radioRet-2"
                                      onChange={evento => changeRetiradaTerceiro(evento.target.value)}
                                      onBlur={evento => changeRetiradaTerceiro(evento.target.value)}
                                      onClick={handleReset}
                                      value={'false'}
                                      name="retirada_terceiro"
                                      type="radio"
                                      checked={retiradaTerceiro === 'false' ? true : false}
                                    />
                                    <label htmlFor="radioRet-2" className="radio-label">
                                      Eu mesmo
                                    </label>
                                  </div>

                                  <div className="radio" id="retirada-terceiro-sim">
                                    <input
                                      id="radioRet-1"
                                      onChange={evento => {
                                        changeRetiradaTerceiro(evento.target.value)
                                        handleChange(evento)
                                      }}
                                      onBlur={evento => {
                                        changeRetiradaTerceiro(evento.target.value)
                                        handleBlur(evento)
                                      }}
                                      value={'true'}
                                      name="retirada_terceiro"
                                      type="radio"
                                      checked={retiradaTerceiro === 'true' ? true : false}
                                    />
                                    <label htmlFor="radioRet-1" className="radio-label">
                                      Outra pessoa
                                    </label>
                                  </div>
                                </RadioGroup>
                              </FormGroup>

                              <FormGroup>
                                {errors.nome_completo && touched.nome_completo ? (
                                  <FieldErrorMessage>{errors.nome_completo}</FieldErrorMessage>
                                ) : null}

                                <InputField
                                  invalid={errors.nome_completo && touched.nome_completo}
                                  id="nome_completo"
                                  type="text"
                                  name="nome_completo"
                                  onChange={evento => {
                                    changeNomeTerceiro(evento.target.value)
                                    handleChange(evento)
                                  }}
                                  onBlur={evento => {
                                    changeNomeTerceiro(evento.target.value)
                                    handleBlur(evento)
                                  }}
                                  value={nomeTerceiro}
                                  placeholder="Ex: Fulano da Silva"
                                  disabled={retiradaTerceiro === "false"}
                                />
                                <Label>Nome Completo*</Label>
                              </FormGroup>
                              <div className="divCamposTerceiro">
                                <FormGroup className={"campoEsquerdo"}>
                                  {errors.cpf && touched.cpf ? (
                                    <FieldErrorMessage>{errors.cpf}</FieldErrorMessage>
                                  ) : null}

                                  <MaskedInput
                                    mask={cpfMask}
                                    invalid={errors.cpf && touched.cpf}
                                    id="cpf"
                                    placeholder="Ex: 000.000.000-00"
                                    name="cpf"
                                    onChange={evento => {
                                      changeCPFTerceiro(evento.target.value)
                                      handleChange(evento);
                                    }}
                                    onBlur={evento => {
                                      changeCPFTerceiro(evento.target.value)
                                      handleBlur(evento);
                                    }}
                                    value={cpfTerceiro}
                                    disabled={retiradaTerceiro === "false"}
                                    render={(ref, props) => (
                                      <InputField ref={input => ref(input)} {...props} />
                                    )}
                                  />
                                  <Label>CPF*</Label>
                                </FormGroup>

                                <FormGroup>
                                  {errors.telefone_contato && touched.telefone_contato ? (
                                    <FieldErrorMessage>{errors.telefone_contato}</FieldErrorMessage>
                                  ) : null}

                                  <MaskedInput
                                    name="telefone_contato"
                                    type="text"
                                    placeholder="Ex: (__) ____-____"
                                    mask={phoneMask}
                                    value={telefoneTerceiro}
                                    invalid={
                                      errors.telefone_contato && touched.telefone_contato
                                    }
                                    onChange={evento => {
                                      changeTelefoneTerceiro(evento.target.value)
                                      handleChange(evento);
                                    }}
                                    onBlur={evento => {
                                      changeTelefoneTerceiro(evento.target.value)
                                      handleBlur(evento)
                                    }}
                                    disabled={retiradaTerceiro === "false"}
                                    render={(ref, props) => (
                                      <InputField ref={(input) => ref(input)} {...props} />
                                    )}
                                  />
                                  <Label>Seu Telefone*</Label>
                                </FormGroup>
                              </div>
                            </form>
                          </WithdrawalByThirdForm>
                          <DeliveryConfirmButton style={{ display: "none" }} id="button-continue-payment" form="form-continue-payment" type="submit" disabled={!isValid}>
                            {
                              obterTextoBotaoConfirmacao(isSubmitting)
                            }
                            <DeliveryTruck />
                          </DeliveryConfirmButton>
                        </>
                      )}
                    </Formik>
                  </div>
                </>
              )}
              {choosenOptions.deliveryType == "NE" && choosenOptions.address !== null && choosenOptions.selectedStore !== null && (
                <>
                  <Header>
                    <h1>RESUMO DA ENTREGA</h1>
                    <span>Detalhes das informações selecionadas</span>
                  </Header>
                  <DeliverySummary>
                    <ul>
                      <li>
                        <span>TIPO ENTREGA</span>
                        <strong>Entregar no meu endereço</strong>
                      </li>
                      <li>
                        <span>ENDEREÇO SELECIONADO</span>
                        <strong>
                          {choosenOptions.address.endereco}, {choosenOptions.address.numero}, {choosenOptions.address.bairro},{" "}
                          {choosenOptions.address.complemento ? choosenOptions.address.complemento + "," : null}{" "}
                          {choosenOptions.address.cidade.nome}/{choosenOptions.address.cidade.uf_sigla}
                        </strong>
                      </li>
                      <li>
                        <span>RESPONSÁVEL</span>
                        <strong>{choosenOptions.address.nome_contato}</strong>
                      </li>
                    </ul>
                  </DeliverySummary>
                  <DeliveryConfirmButton style={{ display: "none" }} onClick={() => handleContinueWithAddressSelected()} type="submit" form="myform">
                    {loading.loading ? loading.message : 'CONFIRMAR ENTREGA'}
                    <DeliveryTruck />
                  </DeliveryConfirmButton>
                </>
              )}
            </ThirdStep>
          </Wrap>
          <PriceInfoWrap>
            <TitleWrap>Resumo do Pedido</TitleWrap>
            <PriceInfoContent>
              <span className="price-description">Subtotal ({cart.length} itens)</span>
              <span className="price-value">{formatPrice(subtotal)}</span>
            </PriceInfoContent>

            {discount > 0 && (
              <PriceInfoContent>
                <span className="price-description">Descontos</span>
                <span className="price-value">{formatPrice(discount)}</span>
              </PriceInfoContent>
            )}

            {(choosenOptions.selectedStore && choosenOptions.deliveryType === "delivery-address") && (
              <PriceInfoContent>
                <span className="price-description">Custo de entrega</span>
                <span className="price-value">{formatPrice(choosenOptions.selectedStore.custo_entrega)}</span>
              </PriceInfoContent>
            )}

            <PriceInfoContent className="total">
              <span className="price-description total">
                Total
              </span>
              <span className="price-value total">{formatPrice(amount + ((choosenOptions.selectedStore && choosenOptions.deliveryType === "delivery-address") ? parseFloat(choosenOptions.selectedStore.custo_entrega) : 0))}</span>
            </PriceInfoContent>
            <ButtonFooterWrap
              id="btn-prosseguir"
              form="form-continue-payment"
              type="submit"
              disabled={(retiradaTerceiro === 'true' && (nomeTerceiro === '' || cpfTerceiro === '' || telefoneTerceiro === '' || choosenOptions.selectedStore === null)) || (retiradaTerceiro === 'false' && choosenOptions.selectedStore === null)}
              onClick={() => handleContinueWithAddressSelected()}
            >
              {loading.loading ? loading.message : "Avançar"}
            </ButtonFooterWrap>
            <a className="linkCompra" href="/">Continuar comprando</a>
          </PriceInfoWrap>
        </ContentWrap>
      </Container>
    </BuyStepsTemplate>
  );
}

export default AddressCheckout;
