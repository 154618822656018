import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";
import { setQuantityProducts } from "./products";
import { Creators as SearchProductsActions } from "../ducks/search";
import { getCartFromStorage } from "./cart";
import removeAccents from "remove-accents";

export function* getProductsBySearchTerm(action) {
  try {
    let { offset, limit } = yield select(state => state.search);
    const { searchTerm, filters, searchOrdering, category, subcategory, offset: offsetSearch, limit: limitSearch } = action.payload;
    if (offsetSearch && limitSearch) {
      offset = offsetSearch;
      limit = limitSearch;
    }

    const store = yield select(state => state.storeByUser.data);

    const filteredValuesFilter = filters && filters.map(e => {
      return `&${e.key}=${e.value}`
    })

    let uniqueStringFilters = '';
    if (filteredValuesFilter) {
      for (var i = 0; i < filteredValuesFilter.length; i++) {
        uniqueStringFilters = uniqueStringFilters + filteredValuesFilter[i];
      }
    }

    let body = {
      termo: removeAccents(searchTerm)
    }

    if (store !== null) {
      body.store = store.uuid;
    }

    const { data: { foundProducts, totalizadorGeral } } = yield call(
      api.post,
      `products/search?_offset=${offset}&_limit=${limit}
      ${uniqueStringFilters ? uniqueStringFilters : ''}
      ${category ? '&categoria=' + category : ''}
      ${subcategory ? '&subcategoria=' + subcategory : ''}
      ${searchOrdering ? '&ordering=' + searchOrdering : ''}
      `,
      body
    );

    const products = yield setQuantityProducts(foundProducts);
    yield put(SearchProductsActions.searchProductsSuccess(products, totalizadorGeral));
  } catch (err) {
    yield put(
      SearchProductsActions.searchProductsFailure("Erro ao buscar produtos")
    );
  }
}

export function* setQuantityProductsFound() {
  const { productsFound } = yield select(state => state.search);

  if (productsFound.length > 0) {
    const storageProducts = yield getCartFromStorage();

    const products = productsFound.map(productsFound => {
      const storageProduct = storageProducts.find(
        storageProduct => storageProduct.id === productsFound.id
      );

      return {
        ...productsFound,
        quantidade: !storageProduct
          ? productsFound.quantidade
          : productsFound.quantidade !== storageProduct.quantidade
            ? storageProduct.quantidade
            : productsFound.quantidade
      };
    });

    yield put(SearchProductsActions.setQuantityProductsFoundSuccess(products));
  }
}
