import { darken, transparentize } from "polished";
import styled from 'styled-components';

export const NewAddressForm = styled.div`
  width: 100%;
  min-width: 426px;
  padding: 24px;

  input {
    width: 450px;
    @media(max-width: 768px) {
          width: 100%;
    }
  }

  .mediumSize {
    width: 300px;
  }

  .smallSize {
    width: 200px;
  }

  div:has(> .smallSize) {
    margin-left: 20px;
    @media(max-width: 768px) {
      margin-left: 0px;
    }
  }

  form {
    width: 100%;
  }

  .textGroup{
    display: flex;
    @media(max-width: 768px) {
      flex-direction: column;
    }
  }

  @media(max-width: 1100px) {
    max-width: 100%;
    padding: 16px;
  }

  @media(max-width: 460px) {
    min-width: 326px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;


export const Container = styled.div`
  min-height: calc(100vh - 398px);
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  background: #FDFDFD;

  @media(max-width: 1100px) {
    flex-direction: column;
  }

  @media(max-width: 400px) {
    padding: 8px;
  }
`;

export const DeliveryTypeCard = styled.button`
  width: 100%;
  height: 100px;
  cursor: pointer;
  border: 1px solid #ECECEC;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.04);
  background: #fff;
  display: flex;
  justify-content: flex-start;
  opacity: ${props => props.disabled ? 0.2 : 1};
  align-items: center;
  margin-bottom: 12px;
  padding: 24px;
  border: 1px solid ${props => props.selected ? props.theme.colors.secondary : 'transparent'};

  svg {
      fill: ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary};
  }

  div.content-info {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      align-items: flex-start;

      h2 {
          font-size: 1.6rem;
          color: ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary}
      }

      span {
        margin-top: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 2.1rem;
        text-align: left;
        width: 230px;
      }
  }
`;

export const SecondStep = styled.div`
  width: 100%;
  @media(max-width: 1100px) {
    margin-left: 0;
  }
`;

export const ChooseStoreForm = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 20px;
  margin-top: 24px;

  @media(max-width: 1100px) {
    max-width: 100%;
  }

  form {
    width: 100%;

    select.input-select {
      height: 53px;
      border: 1px solid var(--neutral-200, #D0D1D3);
      background: #FFF;
      color: var(--neutral-900, #151922);
      font-family: Red Hat Display;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.5px; /* 165.625% */

      padding-left: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 97%;
      background-position-y: 13px;
      border-radius: 8px;
      &:disabled{
        color: var(--neutral-900, #151922);
      }
    }


    div.form-control.city {
      width: 100%;
      margin-left: 16px;
    }

    div.form-control.neighborhood {
      width: 100%;
      margin-top: 24px;
    }

    div.form-control {
      display: flex;
      flex-direction: column;

      select#select-uf{
        width: 83px;
      }

      select#select-city{
        width: 100%;
        @media (max-width: 472px) {
        }
      }

      label {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--neutral-900, #151922);
      }
    }

    select#select-neighborhood{
      width: 71%;
      @media (max-width: 472px) {
        width: 100%;
      }
    }

    div#wrap {
      width: 100%;
      display: flex;

      @media (max-width: 472px) {
        flex-direction: column;
        > div{
          width: 100%;
          margin: 0;
          margin-top: 24px;
        }
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 400;
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
  }
`;

export const StoreItem = styled.li`
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  margin-top: ${props => props.storeByUser ? '0px' : '16px'};
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;

  div.store-info {
    flex: 1;
    display: flex;

    .radio {
      margin-top: 3px;
      margin-right: 10px;
      label{
        font-size: 14px;
      }
      input[type="radio"] {
        position: absolute;
        display: none;
        + .radio-label {
          &:before {
            content: "";
            background: #f4f4f4;
            border-radius: 100%;
            border: 1px solid ${props => props.theme.colors.primary};
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            position: relative;
            top: -0.2em;
            margin-right: 8px;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }
        &:checked {
          + .radio-label {
            &:before {
              background-color: ${props => props.theme.colors.primary};
              box-shadow: inset 0 0 0 4px #f4f4f4;
            }
          }
        }
        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: ${props => props.theme.colors.primary};
            }
          }
        }
        &:disabled {
          + .radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px #f4f4f4;
              border-color: ${darken(0.25, "#f4f4f4")};
              background: ${darken(0.25, "#f4f4f4")};
            }
          }
        }
        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  div.store-address-info p {
    color: #877E7E;
    font-size: 14px;
    margin-top: 8px;
  }

  div.container-time-button {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  div.delivery-time-info {
    display: flex;
    flex-direction: column;

    span.label-time-info {
      color: #877E7E;
      font-weight: 400;
      font-size: 14px;
    }

    strong {
      font-weight: 600;
      color: #877E7E;
      margin-top: 4px;
      font-size: 16px;
    }

    span.withdrawal-time {
      color: #57B85B;
      font-weight: 400;

      strong {
        font-weight: 600;
        color: #57B85B;
      }
    }
  }

  div.store-has-stock {
    span.has {
      color: #00B6AD !important;
    }
    span.hasnt {
      color: #FF7575 !important;
    }
  }

  div.price-delivery {
    span {
      color: #57B85B;
    }
  }

  strong {
    font-size: 1.6rem;
    line-height: 18px;
    color: ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary};
  }

  button.choose-store-btn {
    width: 100%;
    max-width: 120px;
    height: 40px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary};
    color: ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary};
    transition: 200ms ease-in-out;
    font-weight: 500;
    margin-left: 16px;
    margin-top: auto;
    margin-bottom: auto;

    &:disabled {
      background: none;
      color: ${props => transparentize(0.5, props.theme.colors.secondary)};
      border: 1px solid ${props => transparentize(0.5, props.theme.colors.secondary)};
    }
  }
`;

export const StoreList = styled.ul`
  list-style: none;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.neutral100};
  margin-top: 25px;

  @media(max-width: 1100px) {
    max-width: 100%;
  }
`;

export const StoreListTitle = styled.div`
    font-size: 20px;
    color: ${props => props.theme.colors.neutral900};
    font-weight: 700;
    margin-top: 16px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 56px;

  h1 {
    color: var(--neutral-900, #151922);
    /* Subtitle H2 */
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  span {
    color: var(--neutral-900, #151922);
    /* Subtitle H2 */
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const AddressList = styled.ul`
  list-style: none;
  width: 100%;

  div.addresses-bottom {
    display: flex;
    justify-content: flex-end;

    button.add-address-btn {
      font-size: 14px;
      color: ${props => props.theme.colors.primary};
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      margin-top: 24px;
      margin-bottom: 15px;
      font-weight: 700;

      svg {
        margin-right: 8px;
      }
    }

    @media(max-width: 1100px) {
      justify-content: flex-start;
    }
  }

  @media(max-width: 1100px) {
    max-width: 100%;
  }
`;

export const AddressItem = styled.li`
    padding: 24px;
    width: 100%;
    min-height: 100px;
    background: #FFFFFF;
    border: 1px solid ${props => props.selected ? props.theme.colors.primary : '#ECECEC'};
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    transition: 100ms ease-in-out;
    justify-content: space-between;
    border-radius: 8px;

    + li {
      margin-top: 16px;
    }

    div.address-info {
      display: flex;
      flex-direction: column;
      margin-right: 12px;

      h2 {
        color: var(--neutral-900, #151922);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        font-size: 14px;
        margin-top: 8px;
      }
    }

    button.choose-address-btn {
      width: 100%;
      max-width: 110px;
      height: 30px;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary};
      color: ${props => props.selected ? props.theme.colors.secondary : props.theme.colors.primary};
      transition: 200ms ease-in-out;
      font-weight: 500;

      :hover {
        background: ${props => props.selected ? 'transparent' : props.theme.colors.primary};
        color: ${props => props.selected ? props.theme.colors.secondary : '#fff'};
      }

      &:disabled {
        background: none;
        color: ${props => transparentize(0.5, props.theme.colors.secondary)};
        border: 1px solid ${props => transparentize(0.5, props.theme.colors.secondary)};
      }
    }

    @media(min-width: 1025px) {
      :hover {
        border: 1px solid ${props => props.selected ? props.theme.colors.neutral900 : props.theme.colors.primary};
        cursor: pointer;
      }
    }

    .radio {
      margin-top: 3px;
      margin-right: 10px;
      label{
        font-size: 14px;
      }
      input[type="radio"] {
        position: absolute;
        display: none;
        + .radio-label {
          &:before {
            content: "";
            background: #f4f4f4;
            border-radius: 100%;
            border: 1px solid ${props => props.theme.colors.primary};
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            position: relative;
            margin-right: 8px;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }
        &:checked {
          + .radio-label {
            &:before {
              background-color: ${props => props.theme.colors.primary};
              box-shadow: inset 0 0 0 4px #f4f4f4;
            }
          }
        }
        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: ${props => props.theme.colors.primary};
            }
          }
        }
        &:disabled {
          + .radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px #f4f4f4;
              border-color: ${darken(0.25, "#f4f4f4")};
              background: ${darken(0.25, "#f4f4f4")};
            }
          }
        }
        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
`;

export const RadioGroup = styled.div`
  display: flex;
  font-size: 1.6rem;

  .radio {
    margin-top: 8px;
    margin-right: 24px;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: "";
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid ${props => props.theme.colors.primary};
          display: inline-block;
          width: 1.2em;
          height: 1.2em;
          position: relative;
          top: -0.2em;
          margin-right: 8px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: ${props => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 24px;
`;

export const ThirdStep = styled.div`
  flex: 1;

  @media(max-width: 1100px) {
    margin-left: 0;
    max-width: 100%;
  }
`;


export const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.neutral900};
  margin-bottom: 8px;
`;

export const WithdrawalByThirdForm = styled.div`
  width: 100%;

  form {
    width: 100%;
  }

  .divCamposTerceiro{
    display: flex;

    .campoEsquerdo{
      margin-right: 10px;
    }

    @media (max-width: 472px) {
      flex-direction: column;
      margin-right: 0px;
    }
  }
`;

export const InputField = styled.input`
  height: 56px;
  background: #ffffff;
  border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#ECECEC")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  border-radius: 10px;

  input {
    width: 300px;
  }

  + label {
    font-size: 14px;
    color: ${props => (props.invalid ? props.theme.colors.warning : props.theme.colors.neutral900)};
  }

  :focus {
    border: 1px solid
      ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${props =>
    props.invalid ? props.theme.colors.warning : props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${darken(.06, "#ffffff")};
    opacity: 0.8;
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${props => props.theme.colors.bodyLight};
  }
`;

export const DeliveryConfirmButton = styled.button`
  width: 200px;
  height: 50px;
  margin-top: 16px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.neutral800};
  font-size: 16px;
  font-weight: 400;
  font-family: 'Red Hat Display', sans-serif;;
  border-radius: 40px;
  cursor: pointer;
  transition:all 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #fff;
    margin-left: 12px;
  }

  &:disabled {
    background: ${props => transparentize(0.5, props.theme.colors.neutral200)};
    cursor: not-allowed;
  }
`;

export const DeliverySummary = styled.ul`
  width: 100%;
  max-width: 360px;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.04);
  padding: 24px;
  list-style: none;

  li {
    border-bottom: 1px solid #f4f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    &:last-child {
      border-bottom: none;
    }

    span {
      font-size: 14px;
      text-transform: uppercase;
      color: ${props => props.theme.colors.primary};
      margin-bottom: 4px;
    }

    strong {
      font-weight: 500;
      color: #909090;
      line-height: 24px;
    }
  }

  @media(max-width: 1100px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e8e8;
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 35px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  mouse: hover;

  :hover {
    text-decoration: underline;
  }

  img {
    object-fit: contain;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  span{
    color: ${props => props.theme.colors.neutral900};
    font-weight: bold;
    font-size: 16px;
    height: 100%;
    width: 150px;
    text-align: center;
  }

  span.selected{
    border-bottom: solid 3px ${props => props.theme.colors.primary};
  }
`;


export const PriceInfoContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  :has(>.total) {
    padding-top: 32px;
    margin-top: 32px;
    border-top: solid 1px ${props => props.theme.colors.neutral100};
  }

  @media (max-width: 900px) {
    max-width: 100%;
    padding: 0px;
  }

  span.price-value {
    padding: 8px 25px;
    font-family: 'Red Hat Display', sans-serif;;
    font-size: 16px;
    color: ${props => props.theme.colors.black};
    font-weight: 700;
  }

  span.price-description {
    padding: 8px 50px 8px 0;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${props => props.theme.colors.neutral900};
    font-size: 16px;
    font-weight: 500;
  }

  span.total {
    font-weight: 700;
    font-size: 20px;
  }
`;
