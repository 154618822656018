import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  padding: 32px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 1200px;
  margin: 32px auto 0;
`;
