export const size = {
  laptop: "1024px",
  tablet: "768px",
  mobile: "250px"
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`
};
