import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";

import { Creators as ProductsActions } from "../ducks/products";
import { Creators as ErrorActions } from "../ducks/error";
import { getCartFromStorage } from "./cart";

export function* getProducts() {
  try {
    const { offset, limit } = yield select((state) => state.products);

    const store = yield select((state) => state.storeByUser.data);

    const { data } = yield call(
      api.get,
      `products?_offset=${offset}&_limit=${limit}${
        store !== null ? `&store=${store.uuid}` : ""
      }`,
      { offset, limit }
    );

    const products = yield setQuantityProducts(data);

    yield put(ProductsActions.getProductsSuccess(products));
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter os produtos"));
  }
}

export function* setQuantityProducts(products) {
  const local = yield getCartFromStorage();

  return products.map((item) => {
    const storageItem = local.find((i) => i.id === item.id);
    return {
      ...item,
      quantidade: !storageItem
        ? item.quantidade
        : item.quantidade !== storageItem.quantidade
        ? storageItem.quantidade
        : item.quantidade,
    };
  });
}

export function* getProductBySlug(action) {
  const { slug } = action.payload;

  const store = yield select((state) => state.storeByUser.data);

  try {
    const { data } = yield call(
      api.get,
      `products/${slug}${store !== null ? `?store=${store.uuid}` : ""}`
    );

    const cart = yield getCartFromStorage();
    let cartProduct = cart.find((item) => item.id === data.id);

    if (cartProduct) {
      cartProduct = {
        ...data,
        quantidade: cartProduct.quantidade,
        brotherQuantity: cartProduct.brotherQuantity ?? 0,
      };
    } else {
      cartProduct = { ...data, quantidade: 0, brotherQuantity: 0 };
    }

    yield put(ProductsActions.showProductSuccess(cartProduct));
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter o produto"));
  }
}

export function* resetQuantityProducts() {
  const products = yield select((state) => state.products.data);

  if (products.length === 0) return;

  const resetedProducts = products.map((item) => {
    return { ...item, quantidade: 0 };
  });

  yield put(ProductsActions.setDataSuccess(resetedProducts));
}

export function* getNewsProduct() {
  try {
    const store = yield select((state) => state.storeByUser.data);
    let { offset, limit } = yield select((state) => state.products);

    const { data } = yield call(
      api.get,
      `products/product-news?_offset=${offset}&_limit=${limit}${
        store !== null ? `&store=${store.uuid}` : ""
      }`
    );

    const products = yield setQuantityProducts(data);
    yield put(ProductsActions.getNewProductsSuccess(products));
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter os produtos"));
  }
}
