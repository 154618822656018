import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import {
  AddIcon,
  ProductCard,
  ProductInfo,
  QuantityWrap,
  RemoveProductButton,
  ThumbnailWrap,
} from "../../pages/Cart/styles";

export const Container = styled.div`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  -webkit-overflow-scrolling: touch;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 674px;
  width: 100%;
  padding: 56px 64px 56px 64px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  margin-top: 6%;

  p{
    color: #000;
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px; /* 165.625% */
  }

  h2{
    color: var(--neutral-900, #151922);
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 56px;

  }


  @media (max-width: 690px) {
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin:0;
    padding: 56px 16px 56px 16px;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    color: var(--neutral-900, #151922);
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }



  button.close {
    right: 0;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 3.4rem;
      color: ${(props) => props.theme.colors.primary};
    }

    @media (max-width: 600px) {
      svg {
        font-size: 3rem;
      }
    }
  }
`;

export const ProductOrder = styled(ProductCard)``;
export const ThumbnailWrapOrder = styled(ThumbnailWrap)`
  width: 60px;
  height: 60px;
`;
export const ProductInfoOrder = styled(ProductInfo)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
   > div {
    padding: 5px;
    gap: 4px;
    p{
      color: var(--neutral-900, #151922);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
   }
`;

export const ProductContent = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

  }

  @media (max-width: 730px) {
    flex-wrap: wrap;
    > div {
      width: 100%;
      :first-of-type {
        margin-right: 0px;
      }
    }
  }
`;

export const ProductTitleOrder = styled.h3`
  color: var(--neutral-900, #151922);
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 56px;
  &.secondary {
    color: ${(props) => props.theme.colors.secondary};
    ::before {
      background-color: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export const ProductQtd = styled.span`
  color: var(--neutral-600, #72757A);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const RequestButton = styled.button`
  border-radius: 40px;
  background: var(--primary-500, #143C97);
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border: none;
  color: #FFF;
  margin-top: 56px;


  @media (max-width: 660px) {
    width: 100%;
  }
  :disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  img {
    width: 130px;
  }

  span.titulo {
    font-size: 22px;
    margin-bottom: 20px;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${(props) => props.theme.colors.headingLight};
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 500;
  }

  span.descricao {
    font-size: 15px;
    text-align: center;
    width: 70%;
    margin-top: 12px;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${(props) => props.theme.colors.bodyLight};
    text-transform: uppercase;
    letter-spacing: 1.3px;
  }

  span.descricao-menor {
    font-size: 14px;
    text-align: center;
    width: 70%;
    margin-top: 10px;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${(props) => props.theme.colors.bodyLight};
    letter-spacing: 1.3px;
  }
`;

export const QuantityWrapOrder = styled(QuantityWrap)``;
export const RemoveProductButtonOrder = styled(RemoveProductButton)``;

export const AddIconOrder = styled(AddIcon)``;
export const IoIosCloseOrder = styled(IoIosClose)``;

export const Badge = styled.span`
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.bodyDark};
  font-size: 9px;
  padding: 4px 10px;
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
