export const Types = {
  GET_REQUEST: "products/GET_REQUEST",
  GET_SUCCESS: "products/GET_SUCCESS",
  SHOW_REQUEST: "products/SHOW_REQUEST",
  SHOW_SUCCESS: "products/SHOW_SUCCESS",
  SET_SEARCH_TERM: "products/SET_SEARCH_TERM",
  SET_SUGGESTIONS: "products/SET_SUGGESTIONS",
  SET_SUGGESTIONS_SUCCESS: "products/SET_SUGGESTIONS_SUCCESS",
  GET_SUGGESTIONS_REQUEST: "products/GET_SUGGESTIONS_REQUEST",
  CLEAR_REQUESTED_PRODUCT: "products/CLEAR_SUGGESTIONS_REQUEST",
  GET_SUGGESTIONS_SUCCESS: "products/GET_SUGGESTIONS_SUCCESS",
  CLEAR_SUGGESTIONS: "products/CLEAR_SUGGESTIONS",
  SET_SELECTED_PRODUCT: "products/SET_SELECTED_PRODUCT",
  SET_DATA_SUCCESS: "products/SET_DATA_SUCCESS",
  GET_REQUEST_NEW_PRODUCTS: "products/GET_REQUEST_NEW_PRODUCTS",
  GET_NEW_PRODUCT_SUCCESS: "products/GET_NEW_PRODUCT_SUCCESS",
  SET_NEW_PRODUCTS_DATA_SUCCESS: "products/SET_NEW_PRODUCTS_DATA_SUCCESS",
  GET_ALREADY_REQUESTED_PRODUCTS: "products/GET_ALREADY_REQUESTED_PRODUCTS",
};

const INITIAL_STATE = {
  data: [],
  dataNewProducts: [],
  loading: true,
  searchLoading: true,
  selected: {},
  offset: 0,
  limit: 12,
  searchTerm: "",
  suggestions: [],
  hasMore: true,
  requestedProducts: {}
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_REQUEST_NEW_PRODUCTS:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        loading: false,
        error: null,
        offset: state.data.length + action.payload.data.length + 1,
        hasMore: action.payload.data.length ? true : false,
      };
    case Types.GET_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        dataNewProducts: [...state.dataNewProducts, ...action.payload.dataNewProducts],
        loading: false,
        error: null,
        hasMore: action.payload.dataNewProducts.length ? true : false,
        offset: state.dataNewProducts.length + action.payload.dataNewProducts.length + 1
      };
    case Types.SHOW_REQUEST:
      return { ...state, searchLoading: true };
    case Types.SHOW_SUCCESS:
      return {
        ...state,
        selected: action.payload.data,
        searchLoading: false,
      };
    case Types.GET_SUGGESTIONS_REQUEST:
      return { ...state, loading: true };
    case Types.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload.term };
    case Types.SET_SUGGESTIONS:
      return { ...state };
    case Types.SET_SUGGESTIONS_SUCCESS:
      return { ...state, suggestions: action.payload.keep };
    case Types.CLEAR_SUGGESTIONS:
      return { ...state, suggestions: [] };
    case Types.SET_SELECTED_PRODUCT:
      return { ...state, selected: action.payload.data };
    case Types.SET_DATA_SUCCESS:
      return { ...state, data: action.payload.data };
    case Types.SET_NEW_PRODUCTS_DATA_SUCCESS:
      return { ...state, dataNewProducts: action.payload.dataNewProducts };
    case Types.GET_ALREADY_REQUESTED_PRODUCTS:
      return {
        ...state,
        requestedProducts: action.payload.requestedProducts,
        loading: true,
      };
    case Types.CLEAR_REQUESTED_PRODUCT:
      return {
        ...state,
        requestedProducts: null,
        loading: true,
      };
    default:
      return state;
  }
}

export const Creators = {
  setSearchTerm: (term) => ({
    type: Types.SET_SEARCH_TERM,
    payload: { term },
  }),

  getSuggestionsRequest: () => ({
    type: Types.GET_SUGGESTIONS_REQUEST,
  }),

  clearSuggestionsRequest: () => ({
    type: Types.CLEAR_REQUESTED_PRODUCT,
  }),

  getSuggestionsSuccess: (data) => ({
    type: Types.GET_SUGGESTIONS_SUCCESS,
    payload: { data },
  }),

  showProductRequest: (slug) => ({
    type: Types.SHOW_REQUEST,
    payload: { slug },
  }),

  showProductSuccess: (data) => ({
    type: Types.SHOW_SUCCESS,
    payload: { data },
  }),

  setSuggestions: () => ({
    type: Types.SET_SUGGESTIONS,
  }),

  setSuggestionsSuccess: (keep) => ({
    type: Types.SET_SUGGESTIONS_SUCCESS,
    payload: { keep },
  }),

  clearSuggestions: () => ({
    type: Types.CLEAR_SUGGESTIONS,
  }),

  getProductsRequest: () => ({
    type: Types.GET_REQUEST,
  }),

  getNewProductsRequest: () => ({
    type: Types.GET_REQUEST_NEW_PRODUCTS,
  }),

  getNewProductsSuccess: (dataNewProducts) => ({
    type: Types.GET_NEW_PRODUCT_SUCCESS,
    payload: { dataNewProducts },
  }),

  setNewProductsDataSuccess: (dataNewProducts) => ({
    type: Types.SET_NEW_PRODUCTS_DATA_SUCCESS,
    payload: { dataNewProducts },
  }),

  getProductsSuccess: (data) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  getAlreadyRequestedProducts: (requestedProducts) => ({
    type: Types.GET_ALREADY_REQUESTED_PRODUCTS,
    payload: { requestedProducts },
  }),

  setSelectedProduct: (data) => ({
    type: Types.SET_SELECTED_PRODUCT,
    payload: { data },
  }),

  setDataSuccess: (data) => ({
    type: Types.SET_DATA_SUCCESS,
    payload: { data },
  }),
};
