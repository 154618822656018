import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  font-size: 14px;
  /* margin-top: 30px; */
  background: #FFF;
  .footer-info{
    border-top: 1px solid var(--neutral-100, #E8E8E9);
  background: #fff;

  }

  .footer-contact {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    gap: 5px;
    > div{
      border-radius: 5px;
      padding: 8px;
    }
  }

  .divEq {
    order:0;
    flex-grow:7;
    flex-shrink:1;
    align-self:flex-start;
    .menus{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      gap: 5px;
      /* padding-bottom: 64px; */

      ul{
          display:list-item;
          li{
            margin-bottom: 10px;
            a{
              &:hover{
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
      }

      .categorias {
        flex-grow:1;
        flex-shrink:1;
        align-self:flex-start;
      }

      .nossas-marcas {
        flex-grow:1;
        align-self:flex-start;
      }

      .nossos-termos {
        flex-grow:1;
        align-self:flex-start;
      }
    }

  }
  .divDr {
    flex-grow:3;
    align-self:flex-start;

    .contact{
      .contact-data{
        margin-top: 18px;
        >div{
          margin-bottom: 10px;
        }
      }
    }
  }

  .footer-pags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    height: 100%;
    padding: 40px 0px;
    gap: 5px;
    max-width: 1200px;
    margin: 0 auto;
    border-top: solid 1px #E8E8E9;
  }

    .footer-pags > div{
      padding: 8px;
      width: 100%;
      gap: 5px;
      > div.logo-policy{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
        > div.logo{
          flex-grow:0;

        }
        > div.policy{
          flex-grow:1;
          align-self: center;
          margin-left: 10px;
          > p{
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }


    strong {
        color: var(--neutral-900, #151922);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    ul {
      list-style: none;
      margin-top: 14px;
      display: flex;
      flex-wrap: wrap;

      li {
        padding: 2px 0;
        margin-right: 20px;

        img {
          height: 20px;
        }
      }
    }

    @media (max-width: 768px) {
      .footer-contact{
        flex-direction: column;
      }

      .footer-pags, div.divEq > div.menus {
        flex-direction: column;
      }

      div.divEq > div.menus > .categorias, div.divEq > div.menus > .nossas-marcas{
        display: none;
      }

      .footer-pags > div, div.divEq > div.menus > div{
        margin-top: 20px;;
      }
    }
`;
