import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Container,
  FormTitle,
  Title,
  FieldErrorMessage,
  Input,
  Label,
  FormGroup,
  Form,
  SubmitButton,
} from "./styles";
import { useDispatch } from "react-redux";
import { Creators as PasswordActions } from "../../store/ducks/password";
import SubHeader from "../../components/SubHeader";
import { setTitle } from "../../services/browser";
import MainLayout from "../../components/MainLayout";

export default function ChangePassword({ history, match }) {
  const dispatch = useDispatch();

  const ChangePasswordSchema = Yup.object().shape({
    senha: Yup.string()
      .min(6, "A senha deve conter no mínimo 6 caracteres")
      .required("Senha não pode ser vazio"),
    confirmacaoSenha: Yup.string()
      .required("Confirmar senha não pode ser vazio")
      .oneOf([Yup.ref("senha"), null], "As senhas não conferem"),
  });

  function handleSubmit({ senha }) {
    dispatch(PasswordActions.newPasswordRequest(senha, match.params.token));
  }

  useEffect(() => {
    setTitle("Nova Senha");
    dispatch(PasswordActions.verifyTokenRequest(match.params.token));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.token]);

  return (
    <MainLayout history={history}>
      <SubHeader title="Alterar senha" withBackButton={false} />
      <Container>
        <Formik
          initialValues={{ senha: "", confirmacaoSenha: "" }}
          validate={(values) => { }}
          validationSchema={ChangePasswordSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <FormTitle>
                  <Title>Nova senha</Title>
                </FormTitle>

                <FormGroup>
                  {errors.senha && touched.senha ? (
                    <FieldErrorMessage>{errors.senha}</FieldErrorMessage>
                  ) : null}

                  <Input
                    invalid={errors.senha && touched.senha}
                    id="senha"
                    type="password"
                    placeholder="Sua senha secreta"
                    name="senha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.senha}
                  />
                  <Label htmlFor="senha">Senha*</Label>
                </FormGroup>

                <FormGroup>
                  {errors.confirmacaoSenha && touched.confirmacaoSenha ? (
                    <FieldErrorMessage>
                      {errors.confirmacaoSenha}
                    </FieldErrorMessage>
                  ) : null}
                  <Input
                    invalid={
                      errors.confirmacaoSenha && touched.confirmacaoSenha
                    }
                    id="confirmacaoSenha"
                    type="password"
                    placeholder="Repita sua senha secreta"
                    name="confirmacaoSenha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmacaoSenha}
                  />
                  <Label htmlFor="confirmacaoSenha">Repita sua Senha*</Label>
                </FormGroup>

                <SubmitButton>ALTERAR SENHA</SubmitButton>
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </MainLayout>
  );
}
