import React, { useEffect } from "react";
import { setTitle } from "../../services/browser";
import MainLayout from "../../components/MainLayout";

export default function NoMatch({ history }) {

  useEffect(() => {
    setTitle("Página não encontrada")
  }, [])

  return (
    <MainLayout history={history}>
      <h1>Página não encontrada</h1>
    </MainLayout>
  );
}
