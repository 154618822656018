import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import imgClosePopUp from "../../assets/images/close-popup.svg";
import searchBlueSvg from "../../assets/images/search-blue.svg";
import searchSvg from "../../assets/images/search.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import api from "../../services/api";
import { Creators as StoreByUserActions } from "../../store/ducks/storeByUser";

import {
  Container,
  Content,
  Form,
  Header,
  InputContainer,
  LoaderContainer,
  NotFound,
  StoreContainer,
  StoreList
} from "./styles";

export default function SelectStoreModal({ closeModal }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [searchStoreTerm, setSearchStoreTerm] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  useOutsideClick(() => { });

  async function findStores(event) {
    event.preventDefault();
    setStoreList([]);
    if (searchStoreTerm === "") return;
    setLoading(true);
    const { data } = await api.get(
      `stores?search=${searchStoreTerm}&_limit=${10}`
    );
    data.length === 0 ? setNotFound(true) : setNotFound(false);

    setLoading(false);
    setStoreList(data);
  }

  function handleKeyUp(event) {
    let searchTerm = event.target.value;
    if (searchTerm.length === 9) {
      searchTerm = searchTerm.replace(/[^a-zA-Z0-9]/g, "");
    }
    setSearchStoreTerm(searchTerm);
  }

  function setStore(store) {
    if (location.pathname.includes("checkout")) {
      history.push("/carrinho")
    }
    dispatch(StoreByUserActions.setStore(store));
    closeModal();
  }

  return (
    <Container>
      <Content>
        <Header>
          <h1>Encontre uma farmácia próxima a você!</h1>

          <button type="button" className="close" onClick={() => closeModal()}>
            <img src={imgClosePopUp} alt={"Fechar"} title={"Fechar"} />
          </button>
        </Header>

        <Form onSubmit={event => findStores(event)}>
          <InputContainer>
            <img
              src={searchSvg}
              alt="Procurar"
            />
            <input
              placeholder="Busque pelo seu CEP, nome, bairro ou cidade da farmácia"
              type="search"
              name="store-search"
              onKeyUp={handleKeyUp}
              autoComplete="off"
            />
          </InputContainer>
          <button type="submit" className="btn-search desktop">
              Buscar
            </button>
        </Form>
        <span className="all-stores" onClick={() => setStore(null)}>
            <img
              src={searchBlueSvg}
              alt="Procurar"
            />
          <span>Todas lojas</span>
        </span>
        {storeList.length > 0 && (
          <StoreContainer>
            <StoreList>
              {storeList.map(store => (
                <li key={store.id} onClick={() => setStore(store)}>
                  <div>
                    <strong>{store.nome_fantasia}</strong>
                    <span>
                      {store.endereco},{" "}
                      {store.endereco_numero ? `${store.endereco_numero}` : ""}{" "}
                      {store.bairro} | {store.cidade} - {store.uf}
                    </span>
                  </div>
                </li>
              ))}
            </StoreList>
          </StoreContainer>
        )}
        {notFound && (
          <NotFound>
            <span>
              Nenhuma farmácia encontrada com os termos pesquisados.<br />
              Tente novamente com outro CEP, nome, bairro ou cidade ou, se preferir, veja todas nossas lojas.
            </span>
          </NotFound>
        )}
        {loading && (
          <LoaderContainer>
            <Loader type="Oval" color="#00786A" height={25} width={25} />
          </LoaderContainer>
        )}
      </Content>
    </Container>
  );
}
