import styled from "styled-components";
import { darken } from "polished";

export const SignedMobile = styled.div`
  padding: 0 10px 15px 32px;
  text-decoration: none;
  font-size: 1.9rem;
  color: ${props => props.theme.colors.bodyLight};
  display: block;
  transition: 0.1s;
  color: ${props => darken(0.02, props.theme.colors.primary)};
  font-weight: 500;

  span {
    font-weight: 400;
  }
`;

export const Sidenav = styled.nav`
  height: 100%;
  width: 250px;
  margin-left: ${props => (props.isOpen ? "0" : "-250px")};
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.3s;
  transition-timing-function: ease;
  padding-top: 60px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);

  a {
    padding: 15px 10px 15px 32px;
    text-decoration: none;
    font-size: 1.9rem;
    color: ${props => props.theme.colors.bodyLight};
    display: block;
    transition: 0.1s;

    .point {
      background: #F35C19;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      left: 12px;
      margin-top: 4px;
    }

    :hover {
      color: ${props => darken(0.02, props.theme.colors.primary)};
    }
    + a {
      border-top: 1px solid #f1f1f1;
    }
  }

  .signOut {
    padding: 15px 10px 15px 32px;
    text-decoration: none;
    font-size: 1.9rem;
    color: ${props => props.theme.colors.bodyLight};
    display: block;
    transition: 0.1s;
    text-align: inherit;
    :hover {
      color: ${props => darken(0.02, props.theme.colors.primary)};
    }
    background: none;
    border: none;
    width: 100%;
    border-top: 1px solid #f1f1f1;
  }

  .closebtn {
    position: absolute;
    top: 1px;
    right: 10px;
    font-size: 3.5rem;
    background: transparent;
    border: none;
    color: ${props => props.theme.colors.primary};
  }

  @media screen and (max-height: 450px) {
    padding-top: 15px;

    a {
      font-size: 18px;
    }
  }
`;
