import produce from "immer";

export const Types = {
  GET_DOMAIN_REQUEST: "@domain/GET_DOMAIN_REQUEST",
  GET_DOMAIN_SUCCESS: "@domain/GET_DOMAIN_SUCCESS",
  GET_DOMAIN_FAILURE: "@domain/GET_DOMAIN_FAILURE",
};

const INITIAL_STATE = {
  loading: false,
  allowedDomain: false
};

export default function domain(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_DOMAIN_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case Types.GET_DOMAIN_SUCCESS: {
        draft.loading = false;
        draft.allowedDomain = true;
        break;
      }
      case Types.GET_DOMAIN_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
}

export const Creators = {
  getDomainRequest: () => ({
    type: Types.GET_DOMAIN_REQUEST,
  }),

  getDomainSuccess: () => ({
    type: Types.GET_DOMAIN_SUCCESS,
  }),

  getDomainFailure: (error) => ({
    type: Types.GET_DOMAIN_FAILURE,
    message: { error }
  }),
};
