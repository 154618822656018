import produce from "immer";

export const Types = {
  OPEN_MODAL: "@auth/OPEN_MODAL",
  CLOSE_MODAL: "@auth/CLOSE_MODAL",
  SIGN_IN_REQUEST: "@auth/SIGN_IN_REQUEST",
  SIGN_IN_FAILURE: "@auth/SIGN_FAILURE",
  SIGN_IN_SUCCESS: "@auth/SIGN_IN_SUCCESS",
  SIGN_OUT: "@auth/SIGN_OUT",
  SIGN_OUT_SUCCESS: "@auth/SIGN_OUT_SUCCESS",
  REFRESH_TOKEN_REQUEST: "@auth/REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "@auth/REFRESH_TOKEN_SUCCESS",
  RESET_ERROR: "@auth/RESET_ERROR"
};

const INITIAL_STATE = {
  isOpen: false,
  signed: localStorage.getItem("@ConexaoGAM:token") !== null || false,
  loading: false,
  error: null,
  accessToken: localStorage.getItem("@ConexaoGAM:token") || null,
  refreshToken: localStorage.getItem("@ConexaoGAM:refreshToken") || null
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.OPEN_MODAL: {
        draft.isOpen = true;
        break;
      }
      case Types.CLOSE_MODAL: {
        draft.isOpen = false;
        break;
      }
      case Types.SIGN_IN_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case Types.SIGN_IN_SUCCESS: {
        draft.accessToken = action.payload.accessToken;
        draft.refreshToken = action.payload.refreshToken;
        draft.signed = true;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case Types.SIGN_IN_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case Types.SIGN_OUT: {
        draft.accessToken = null;
        draft.refreshToken = null;
        draft.signed = false;
        break;
      }
      case Types.RESET_ERROR: {
        draft.error = null;
        draft.loading = false;
        break;
      }
      case Types.REFRESH_TOKEN_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REFRESH_TOKEN_SUCCESS: {
        draft.accessToken = action.payload.accessToken;
        draft.refreshToken = action.payload.refreshToken;
        localStorage.setItem("@ConexaoGAM:token", action.payload.accessToken);
        localStorage.setItem(
          "@ConexaoGAM:refreshToken",
          action.payload.refreshToken
        );
        draft.signed = true;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case "@user/GET_ACCOUNT_SUCCESS": {
        draft.signed = true;
        break;
      }
      case "@user/GET_ACCOUNT_FAILURE": {
        draft.signed = false;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  openModal: () => ({
    type: Types.OPEN_MODAL
  }),

  resetError: () => ({
    type: Types.RESET_ERROR
  }),

  closeModal: () => ({
    type: Types.CLOSE_MODAL
  }),

  signInRequest: (email, senha, redirectCheckout = false) => ({
    type: Types.SIGN_IN_REQUEST,
    payload: { email, senha, redirectCheckout }
  }),

  signFailure: message => ({
    type: Types.SIGN_IN_FAILURE,
    payload: { message }
  }),

  signInSuccess: (accessToken, refreshToken, user) => ({
    type: Types.SIGN_IN_SUCCESS,
    payload: { accessToken, refreshToken, user }
  }),

  signOut: () => ({
    type: Types.SIGN_OUT
  }),

  signOutSuccess: () => ({
    type: Types.SIGN_OUT_SUCCESS
  }),

  refreshTokenSuccess: (accessToken, refreshToken) => ({
    type: Types.REFRESH_TOKEN_SUCCESS,
    payload: { accessToken, refreshToken }
  })
};
