import produce from "immer";

export const Types = {
  GET_REQUEST: "@myOrders/GET_REQUEST",
  GET_FAILURE: "@myOrders/GET_FAILURE",
  GET_SUCCESS: "@myOrders/GET_SUCCESS",
  SET_ORDER_SELECTED: "@myOrders/SET_ORDER_SELECTED"
};

const INITIAL_STATE = {
  loading: true,
  orderSelected: null,
  error: null,
  data: []
};

export default function myOrders(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case Types.GET_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.error = null;
        break;
      }
      case Types.GET_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.errorMessage;
        break;
      }
      case Types.SET_ORDER_SELECTED: {
        draft.orderSelected = action.payload.order;
        break;
      }

      default:
    }
  });
}

export const Creators = {
  getRequest: () => ({
    type: Types.GET_REQUEST
  }),

  getSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  getFailure: errorMessage => ({
    type: Types.GET_FAILURE,
    payload: { errorMessage }
  }),

  setOrderSelected: order => ({
    type: Types.SET_ORDER_SELECTED,
    payload: { order }
  })
};
