import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import corpo1 from '../../assets/images/categoryFeatured/corpo-1.jpg';
import corpo2 from '../../assets/images/categoryFeatured/corpo-2.jpg';
import facial1 from '../../assets/images/categoryFeatured/facial-1.jpg';
import facial2 from '../../assets/images/categoryFeatured/facial-2.jpg';
import imgSetaAvancar from '../../assets/images/seta-avancar.svg';
import imgSetaVoltar from '../../assets/images/seta-voltar.svg';
import api from "../../services/api";
import { Card, Container } from './styles';

export default function CategoryFeatured({ id }) {

  const getPageSize = (divisor, productsCount) => {
    const size = Math.abs(productsCount / divisor);
    const intSize = parseInt(size);
    if (size > intSize)
      return intSize + 1;
    else
      return intSize;
  };

  const store = useSelector(state => state.storeByUser.data);

  const [indexScroll, setIndexScroll] = useState(0)
  const [pageSize, setPageSize] = useState(getPageSize(4, 10))
  const [category, setCategory] = useState(null)
  const [products, setProducts] = useState([]);
  const [qtdProducs, setQtdProducs] = useState(10);

  const divProducts = React.createRef();

  const handleWindowResize = () => {
    let length = qtdProducs;
    if (window.innerWidth >= 1025) {
      setPageSize(getPageSize(4, length));
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
      setPageSize(getPageSize(3, length));
    } else if (window.innerWidth >= 481 && window.innerWidth <= 767) {
      setPageSize(getPageSize(2, length));
    } else if (window.innerWidth >= 320 && window.innerWidth <= 480) {
      setPageSize(getPageSize(1, length));
    }

    setIndexScroll(0);
  };


  const handleNextPage = () => {
    if (indexScroll < (pageSize - 1))
      handlePage(indexScroll + 1);
  }

  const handleBackPage = () => {
    if (indexScroll > 0)
      handlePage(indexScroll - 1);
  }

  const handlePage = (index) => {
    setIndexScroll(index);

    const left = (divProducts.current.scrollWidth / pageSize) * index;

    divProducts.current.scrollTo({
      left: left,
      behavior: 'smooth',
      duration: 9000
    });
  };

  const getCategory = () => {
    if (id === 10) {
      setCategory({
        id: id,
        descricao: "Corpo",
        imagens: {
          url1: corpo1,
          url2: corpo2
        },
      })
    }
    else {
      setCategory({
        id: id,
        descricao: "Rosto",
        imagens: {
          url1: facial1,
          url2: facial2
        },
      })
    }
  }

  useEffect(() => {
    getCategory();

    searchProductsByCategory(id);

    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    handleWindowResize();
  }, [products]);

  const searchProductsByCategory = (idCategory) => {
    api.get(`products?_subCategory=${id}&_offset=${0}&_limit=${10}${store !== null ? `&store=${store.uuid}` : ""}`).then((response) => {
      setProducts(response.data);
      setQtdProducs(response.data?.length ?? 10);
    });
  }

  return (
    <Container>
      {products?.length > 0 && category && (
        <>
          <h2>{category.descricao}</h2>
          <div className='images'>
            <div className='primary'>
              <img src={category.imagens.url1} alt="Imagem" />
            </div>
            <div className='secondary'>
              <img src={category.imagens.url2} alt="Imagem" />
            </div>
          </div>
          <div className='products' ref={divProducts}>
            {products &&
              products.map((product) => (
                <Card key={product.id} product={product} hasSelectedStore={store !== null} />
              ))}
          </div>
          <div className='actions'>
            <ul>
              <li onClick={handleBackPage}>
                <img src={imgSetaVoltar} alt="Voltar" />
              </li>
              {pageSize && (
                Array.from(Array(pageSize).keys()).map((item, index) => (
                  <li key={index} onClick={() => handlePage(index)} >
                    <span key={index} className={indexScroll === index ? "active" : ""}></span>
                  </li>
                ))
              )}
              <li onClick={handleNextPage}>
                <img src={imgSetaAvancar} alt="Avançar" />
              </li>
            </ul>
          </div>
        </>
      )}
    </Container>
  );
}
