import styled from 'styled-components';
import { transparentize, darken } from "polished";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 6000 !important;
  overflow-y: auto;
  top: 0;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100%;
  max-width: calc(705px - 40px);
  background: #fff;
  border-radius: 8px;
  margin-top: 6%;
  margin-bottom: 4%;

  padding:0px 0px 12px 0px;

  img {
    width: 108px;
    height: 108px;
    position: absolute;
    top: -64px;
    left: 50%;
    margin-left: -50px;
    z-index: 1000;

    @media (max-width: 600px) {
      visibility: hidden;
    }

    @media (max-width: 300px) {
      visibility: hidden;
    }
  }

  @media (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
    padding:0px 0px 16px 0px;
  }

  @media (max-width: 300px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
    padding:0px 0px 8px 0px;
  }
`;

export const ModalContent = styled.div`
  padding:0px 64px 12px 64px;

  @media (max-width: 600px) {
    padding:0px 32px 16px 32px;
  }

  @media (max-width: 300px) {
    padding:0px 16px 8px 16px;
  }

`;

export const HeaderFlag = styled.div`
  padding: 0;
  background-color:${props => props.theme.colors.secondary};
  opacity: 30%;
  width: 100%;
  height: 64px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 26px 0 26px 0;
  h1 {
    font-size: 24px;
    font-weight: 500;
    color: ${props => props.theme.colors.secondary}
  }
`;


export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      flex-direction: column;
      margin: auto;

      li {
        padding: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .card-icon-bg {
          min-width: 60px;
          margin-right: 16px;
          height: 60px;
          border-radius: 50px;
          background-color: ${props => transparentize(.9, props.theme.colors.secondary, 0.05)};
          display: flex;
          align-items: center;

          svg {
            fill: ${props => props.theme.colors.secondary};
            margin-left: -5px;
          }

        }

        .card-info {
          display: flex;
          flex-direction: column;

          span.card-title {
            font-weight: 500;
            color: #5E5E5E;
          }

          span.card-description {
            color: #A7A7A7;
            font-weight: normal;
            margin-top: 8px;

            strong {
              font-weight: 500;
            }
          }

        }

        .order-amount-label {
          font-size: 24px;
        }
        .order-amount-value {
          font-size: 18px;
        }

        @media(max-width: 360px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .card-icon-bg {
            margin-right: 0px;
            margin-bottom: 8px;
          }

          .card-info {
            span.card-title {
                text-align: center;
            }

            span.card-description {
              text-align: center;
            }
          }
        }
      }
    }

`;

export const DescriptionContainer = styled.div`
p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 12px;
  }
`;

export const TableContainer = styled.table`
  border: 1px solid #ECECEC;
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 12px;

  th {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    td {
      padding: 8px;
      font-size: 16px;
      color: #8D98A6;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 12px;

  button {
    width: 100%;
    max-width: 244px;
    height: 46px;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.secondary};
    border: none;
    border-radius: 4px;

    padding: 10px;

    > div {
      margin-right: 12px;
      margin-top: 5px;
    }

    @media(max-width: 560px) {
      margin-top: 32px;
      max-width: 100%;
    }
  }

  p {
    font-size: 14px;
    color: #777F8A;
    text-align: center;
    cursor: pointer;
    margin-top: 12px;
  }
`;
