import axios from "axios";
import store from "../store";
import { Creators as AuthActions } from "../store/ducks/auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwOTM0MzU5NDU5NDQiLCJuYW1lIjoiRmFybcOhY2lhcyBBc3NvY2lhZGFzIiwiaWF0IjoxNTgwMzE5NTM2LCJ1dWlkIjoiZWIwNWU1OTYtNWI1Yi00MjljLTk0NDEtMjI5OWJkNDM1YjI3In0.WSvcekvEl26C81peJyq1SwlgD7PnMaHXh4Xz4JGOkU8"
  }
});

api.interceptors.request.use(async config => {
  const token = store.getState().auth.accessToken;

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwOTM0MzU5NDU5NDQiLCJuYW1lIjoiRmFybcOhY2lhcyBBc3NvY2lhZGFzIiwiaWF0IjoxNTgwMzE5NTM2LCJ1dWlkIjoiZWIwNWU1OTYtNWI1Yi00MjljLTk0NDEtMjI5OWJkNDM1YjI3In0.WSvcekvEl26C81peJyq1SwlgD7PnMaHXh4Xz4JGOkU8`;
  }

  return config;
});

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function fetchAccessToken() {
  return api.put("/private/token", {
    refreshToken: store.getState().auth.refreshToken
  });
}

api.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const {
      config,
      response: { status }
    } = error;

    const originalRequest = config;

    if (status === 401) {
      if (!store.getState().auth.signed) {
        return Promise.reject(error);
      }
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        fetchAccessToken().then(response => {
          isAlreadyFetchingAccessToken = false;

          const { accessToken, refreshToken } = response.data;

          store.dispatch(
            AuthActions.refreshTokenSuccess(accessToken, refreshToken)
          );

          onAccessTokenFetched(accessToken);
        });
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(accessToken => {
          originalRequest.headers.Authorization = "Bearer " + accessToken;

          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

export default api;
