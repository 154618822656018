import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.neutral50};
    padding: 14px 0px;

    .container-logo-steps{
      max-width: 1200px;
      width: 100%;
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
        a{
          justify-content: center;
        }
      }
    }
`;

export const StyledLinkLogo = styled(Link)`
    float: left;
    display: flex;
    align-items: center;
    /* margin-top: 20px; */
    /* @media ${device.laptop} {
        position: absolute;
        left: 35px;
        z-index: 1;
    } */
`;

export const Steps = styled.div`
    display: flex;
    flex-direction: row;
    height: 100px;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-right: 134px;

    img {
        z-index: 2;
        cursor: pointer;
    }

    img:not(:first-child):not(:last-child){
        margin-right: 50px;
    }

    img.line{
        z-index: 1;
        position: absolute;
        width: 265px;
        display: flex;
        align-self: center;
    }

    div.lineEvo {
        background: #143C97;
        height: 3px;
        position: absolute;
        display: flex;
        align-self: center;
        z-index: 1;
        left: calc(50% - 175px);
    }

    @media (max-width: 768px) {
      margin-right: 0;
    }
`;
