import React from "react";
import { ToastContainer } from "react-toastify";
import { Footer } from "../Footer";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";

const MainLayout = ({ children, history }) => {
  return (
    <>
      <ScrollToTop />
      <ToastContainer autoClose={2500} />
      <Header history={history} />
      {children}
      <Footer />
    </>
  );
}

export default MainLayout;
