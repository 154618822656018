import styled from "styled-components";

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${props => props.theme.colors.warning};
  align-items: center;
  border-radius: 2px;

  > svg {
    color: #fff;
    padding: 10px;
    font-size: 4.8rem;
  }

  span {
    padding: 5px 10px;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    color: ${props => props.theme.colors.bodyDark};
  }

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 10px;
    font-size: 2.5rem;
    svg {
      color: #fff;
    }
  }

  @media (max-width: 400px) {
    span {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 320px) {
    span {
      font-size: 1.4rem;
    }

    button {
      font-size: 2.2rem;
    }
  }
`;
