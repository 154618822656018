import React from 'react';

import { Container } from './styles';

function ToastOffline() {
  return (
    <Container>
      <span>
          Parece que você está offline! <br/> Verique sua conexão com a internet.
      </span>
    </Container>
  );
}

export default ToastOffline;
