import styled from "styled-components";
import CardSale from '../CardSale';


export const Container = styled.div`
  max-width: 996px;
  width: 100%;
  margin: 0 auto;
  background: #FDFDFD;
  font-size: 14px;
  margin-top: 30px;
  padding-bottom: 64px;
  .images{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;

    height: 100%;
    gap: 24px;
    padding-bottom: 24px;

    .primary{
      width: 60%;
      overflow: hidden;
      img{
        max-width: 100%;
        height: 100%;
        border-radius: 8px;
        @media (max-width: 768px){
          width: 100%;
          height: auto;
        }
      }

      @media (max-width: 768px){
          width: 100%;
          height: auto;
      }
    }
    .secondary{
      width: 40%;
      overflow: hidden;
      img{
        max-width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      @media (max-width: 768px){
        display: none;
      }
    }

    @media (max-width: 768px){
      .images {
        height: auto;
      }
    }
  }
  h2{
    font-family: var(--subtitle-h2-font-family);
    font-weight: var(--subtitle-h2-font-weight);
    color: var(--neutral-900);
    font-size: var(--subtitle-h2-font-size);
    letter-spacing: var(--subtitle-h2-letter-spacing);
    line-height: var(--subtitle-h2-line-height);
    font-style: var(--subtitle-h2-font-style);
    padding-bottom: 24px;
  }
  .products{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    gap: 24px;
    min-height: 403px;
  }

  .actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    ul{
      display:inline-flex;
      list-style: none;
      justify-content: center;
      align-content: center;
      align-items: center;
      gap: 8px;
      li{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        cursor: pointer;
        span{
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: var(--neutral-200, #D0D1D3);
          transition: all 0.2s ease-in-out;
          &.active{
            width: 32px;
            background: var(--primary-500, #143C97);
          }
        }
      }
    }
  }

  @media (max-width: 1200px){
    padding-left: 16px;
    padding-right: 16px;
  }

`;


/*Ajustar css para card que n é responsivo*/
export const Card = styled(CardSale)`
  width: 300px !important;
  > div {
    background-color:red;
    width: 550px;

    > div {
      background-color:blue;
      width: 550px;
    }
  }
`;
