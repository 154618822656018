import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as MyOrdersActions } from "../ducks/myOrders";


export function* getMyOrdersRequest() {
  try {
    const user = yield select(state => state.user.profile);
    const { accessToken } = yield select(state => state.auth);

    api.defaults.headers.Authorization = "Bearer " + accessToken;
    const { data } = yield call(api.get, `orders/${user.uuid}`);

    yield put(MyOrdersActions.getSuccess(data));
  } catch (err) {
    yield put(MyOrdersActions.getFailure("Ocorreu um erro ao buscar pedidos"));
  }
}
