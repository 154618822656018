import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
  nome: Yup.string().required("Nome não pode ser vazio"),
  email: Yup.string()
    .email("Informe um e-mail válido")
    .required("E-mail não pode ser vazio"),
  celular: Yup.string().required("Telefone não pode ser vazio"),
  data_nascimento: Yup.string().required(
    "Data de nascimento não pode ser vazio"
  ),
  genero: Yup.string().required("Sexo não pode ser vazio")
});

export const cpfMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/
];

export const dateMask = [
  /[0-9]/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
