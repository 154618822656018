import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/Banner";
import Categories from "../../components/Categories";
import CategoryFeatured from "../../components/CategoryFeatured";
import HelmetCustom from "../../components/HelmetCustom";
import ItemList from "../../components/ItemList";
import MainLayout from "../../components/MainLayout";
import { setTitle } from "../../services/browser";
import { Creators as ProductsActions } from "../../store/ducks/products";
import { Container } from "./styles";

export default function Main({ history }) {
  const dispatch = useDispatch();
  const { data: products, loading, hasMore } = useSelector(
    state => state.products
  );

  useEffect(() => {
    setTitle("Farmácias Associadas", false);
    dispatch(ProductsActions.getProductsRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadMore() {
    dispatch(ProductsActions.getProductsRequest());
  }

  return (
    <MainLayout history={history}>
      <Container>
        <HelmetCustom />
        <Banner />
        <Categories />
        <div className="products">
          {products.length >= 1 && (
            <ItemList
              products={products}
              hasMore={hasMore}
              loadMore={loadMore}
              loading={loading}
            />
          )}
        </div>
        <CategoryFeatured id={10} />
        <CategoryFeatured id={11} />
      </Container>
    </MainLayout>
  );
}
