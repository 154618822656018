import React, { } from "react";
import { ContainerInput, FieldErrorMessage, FormGroup, ImageInput, Input, InputPassword, Label } from "./styles";
import Eye from "../../assets/images/eye.svg";
import CloseEye from "../../assets/images/closeEye.svg";

const InputCustom = ({ id, name, type, description, value, onChange, onBlur, isInvalid, errors, className }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    return (
        <FormGroup className={className}>
            <Label htmlFor={id}>{description}</Label>
            <ContainerInput>
                {type == "password" ? (
                    <InputPassword
                        invalid={isInvalid}
                        id={id}
                        type={showPassword ? "text" : "password"}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                    />
                ) : (
                    <Input
                        invalid={isInvalid}
                        id={id}
                        type={type}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                    />
                )}
                {type === "password" && (
                    <ImageInput onClick={toggleShowPassword} src={type == "password" && showPassword ? Eye : CloseEye} alt={type == "password" && showPassword ? "Eye" : "CloseEye"} />
                )}
            </ContainerInput>

            {isInvalid ? (
                <FieldErrorMessage>{errors}</FieldErrorMessage>
            ) : null}
        </FormGroup>
    );
}

export default InputCustom;