/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { addDays, format } from 'date-fns';
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import MoonIcon from "../../assets/images/moon.svg";
import { Creators as OrderActions } from "../../store/ducks/order";
import { Container, Content, DescriptionContainer, Footer, Header, HeaderFlag, Modal, ModalContent, TableContainer } from './styles';

function ShowOpeningHours() {
  const dispatch = useDispatch();
  const { showOpeningHours, deliveryStoreData, checkoutData, loading } = useSelector(state => state.order);
  const { amount } = useSelector(state => state.cart);

  function finishOrder() {
    dispatch(OrderActions.orderRequest({ ...checkoutData }, true));
  }

  function closeModal() {
    dispatch(OrderActions.hideOpeningHoursModal());
  }


  useEffect(() => {
    return () => {
      dispatch(OrderActions.hideOpeningHoursModal());
    }
  }, [dispatch])

  return (
    <>
      {(showOpeningHours) && (
        <Container>
          <Modal>
            <img src={MoonIcon} alt="Ìcone lua" />
            <HeaderFlag />
            <ModalContent>
            <Header>
              <h1>Já estamos fechados...</h1>
            </Header>

            <Content>
              <DescriptionContainer>
                <p>
                Mas não se preocupe!
                você poderá retirar seu produto,
                conforme nosso próximo horário de funcionamento informado na tabela abaixo.
                </p>
              </DescriptionContainer>
              <TableContainer>
                <thead>
                  <tr>
                    <th>Próximo horário para retirada:</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                {deliveryStoreData && (
                  <>
                  <td>{deliveryStoreData.lojaHorario.dia ? deliveryStoreData.lojaHorario.dia.descricao[0].toUpperCase() + deliveryStoreData.lojaHorario.dia.descricao.substr(1) : format(addDays(new Date(deliveryStoreData.lojaHorario.data), 1), 'dd/MM/yyyy')}</td>
                  <td>{deliveryStoreData.lojaHorario.hora_inicio_retirada.slice(0, -3) + ' até ' + deliveryStoreData.lojaHorario.hora_fim_retirada.slice(0, -3)}</td>
                  </>
                )}
                </tr>
                </tbody>
              </TableContainer>
            </Content>

            <Footer>
              <button onClick={() => finishOrder()}>{loading ? (<><Loader type="Oval" color="#FFFFFF" height={16} width={16} /> Confirmando compra...</>) : "Confirmar Compra" }</button>
              <p onClick={() => closeModal()}>Não, obrigado</p>
            </Footer>
            </ModalContent>
          </Modal>
        </Container>
      )}
    </>
  );
}

export default ShowOpeningHours;
