import * as Yup from "yup";
const cpfValidation = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const SignUpSchema = Yup.object().shape({
  cpf: Yup.string()
    .required("CPF não pode ser vazio")
    .min(14, "O CPF deve conter 11 números com pontuação")
    .max(14, "O CPF deve conter 11 números com pontuação")
    .matches(cpfValidation, "Digite um CPF válido")
    .test("cpf", "Digite um CPF válido", (strCPF = "") => {
      strCPF = strCPF.replace(/[^\d]+/g, "");
      let soma;
      let resto;
      soma = 0;
      if (
        strCPF === "00000000000" ||
        strCPF === "11111111111" ||
        strCPF === "22222222222" ||
        strCPF === "33333333333" ||
        strCPF === "44444444444" ||
        strCPF === "55555555555" ||
        strCPF === "66666666666" ||
        strCPF === "77777777777" ||
        strCPF === "88888888888" ||
        strCPF === "99999999999"
      )
        return false;

      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(strCPF.substring(9, 10))) return false;

      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }),
  nome: Yup.string().required("Nome não pode ser vazio"),
  email: Yup.string()
    .email("Informe um e-mail válido")
    .required("E-mail não pode ser vazio"),
  celular: Yup.string().required("Telefone não pode ser vazio"),
  dataNascimento: Yup.string().required(
    "Data de nascimento não pode ser vazio"
  ),
  genero: Yup.string().required("Sexo não pode ser vazio"),
  senha: Yup.string()
    .min(6, "A senha deve conter no mínimo 6 caracteres")
    .required("Senha não pode ser vazio"),
  confirmacaoSenha: Yup.string()
    .required("Confirmar senha não pode ser vazio")
    .oneOf([Yup.ref("senha"), null], "As senhas não conferem"),
  lgpd_aceite: Yup.bool().oneOf([true], "Deve aceitar as políticas de privacidade"),
  participaCartaoVantagens: Yup.boolean()
});

export const cpfMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/
];

export const dateMask = [
  /[0-9]/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
