import produce from "immer";

export const Types = {
  ADDRESS_REQUEST: "@address/ADDRESS_REQUEST",
  ADDRESS_FAILURE: "@address/ADDRESS_FAILURE",
  ADDRESS_SUCCESS: "@address/ADDRESS_SUCCESS",
  ADDRESS_UPDATE_REQUEST: "@address/ADDRESS_UPDATE_REQUEST",
  ADDRESS_UPDATE_FAILURE: "@address/ADDRESS_UPDATE_FAILURE",
  ADDRESS_UPDATE_SUCCESS: "@address/ADDRESS_UPDATE_SUCCESS",
  GET_ADDRESSES_REQUEST: "@address/GET_ADDRESSES_REQUEST",
  GET_ADDRESSES_SUCCESS: "@address/GET_ADDRESSES_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  newAddressLoading: false,
  createdAddress: null,
  data: [],
};

export default function address(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ADDRESS_REQUEST: {
        draft.newAddressLoading = true;
        break;
      }
      case Types.ADDRESS_SUCCESS: {
        const finalData = [...draft.data, action.payload.data]

        const orderedData = finalData.sort((a, b) => {
          if(a.id > b.id) {
            return -1
          } else {
            return true
          }
        })

        draft.newAddressLoading = false;
        draft.data = orderedData;
        draft.createdAddress = action.payload.data;
        break;
      }
      case "error/SET": {
        if(action.payload.key === "deliver-address") {
          draft.newAddressLoading = false;
        }
        break;
      }
      case Types.ADDRESS_FAILURE: {
        draft.loading = false;
        break;
      }
      case Types.ADDRESS_UPDATE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.ADDRESS_UPDATE_FAILURE: {
        draft.loading = false;
        break;
      }
      case Types.ADDRESS_UPDATE_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.GET_ADDRESSES_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_ADDRESSES_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  addressStoreRequest: (data, isFromCheckout = true) => ({
    type: Types.ADDRESS_REQUEST,
    payload: { data, isFromCheckout }
  }),

  addressStoreSuccess: (data) => ({
    type: Types.ADDRESS_SUCCESS,
    payload: { data }
  }),

  addressStoreFailure: () => ({
    type: Types.ADDRESS_FAILURE
  }),

  addressUpdateRequest: (data, isFromCheckout = false) => ({
    type: Types.ADDRESS_UPDATE_REQUEST,
    payload: { data, isFromCheckout }
  }),

  addressUpdateSuccess: data => ({
    type: Types.ADDRESS_UPDATE_SUCCESS,
    payload: { data }
  }),

  addressUpdateFailure: () => ({
    type: Types.ADDRESS_UPDATE_FAILURE
  }),

  getAddressesRequest: () => ({
    type: Types.GET_ADDRESSES_REQUEST
  }),

  getAddressesSuccess: (data) => ({
    type: Types.GET_ADDRESSES_SUCCESS,
    payload: { data }
  })
};
