import styled from "styled-components";

export const ButtonSecundary = styled.button`
    background-color: ${props => props.theme.colors.white};
    padding: 12px 16px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 700;

    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};

    &:hover{
        color: ${props => props.theme.colors.primaryDark};
        border: 1px solid ${props => props.theme.colors.primaryDark};
    }

    &:disabled{
        color: ${props => props.theme.colors.grayDark};
        border: 1px solid ${props => props.theme.colors.grayDark};
    }
`;