import styled from 'styled-components';



export const LoaderContainer = styled.div``;

export const Container = styled.div`
  margin-top: 32px;
  min-height: calc(100vh - 355px);
  

  ${LoaderContainer} {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 64px;

    span {
      color: ${props => props.theme.colors.primary};
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
`;