import findLast from "lodash/findLast";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { all, call, delay, put, select } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as CartActions } from "../ducks/cart";
import { Creators as ErrorActions } from "../ducks/error";
import { Creators as OrderActions } from "../ducks/order";
import { Creators as ProductsActions } from "../ducks/products";
import { Creators as StoreByUserActions } from "../ducks/storeByUser";
import { setQuantityBannerProducts } from "./banners";
import { setQuantityProductsFound } from "./search";
import { toast } from "react-toastify";

export function* addProductBasket({ payload }) {
  const { hasDelay = true } = payload;
  if (hasDelay) {
    yield delay(500);
  }

  const participateRewardsProgram = yield select(
    (state) => state.user.profile?.programa_fidelidade ?? true
  );

  const { currentProductAdded, qty: amountAdded, sum, isUpdate = false } = payload;
  console.log({ currentProductAdded, qty: amountAdded, sum });
  let basket = yield getCartFromStorage();

  //Verifica se o produto já está na cesta.
  let productFound = basket.find(
    (basketProduct) => basketProduct.id === currentProductAdded.id
  );

  //Tira o/os produtos já existentes na sexta
  basket = basket.filter(
    (basketProduct) => basketProduct.id !== currentProductAdded.id
  );

  //Aplica a quantidade final do produto na cesta.
  const finalQuantity = productFound
    ? sum
      ? productFound.quantidade + amountAdded
      : amountAdded
    : amountAdded;

  if (finalQuantity > 50) return;

  //Verifica se o produto adicionado tem promoção por quantidade Múltipla
  console.log(
    currentProductAdded.promocao_quantidade_multipla &&
    finalQuantity >=
    currentProductAdded.preco_quantidade_multipla[0]?.quantidade_multipla
  );
  if (
    currentProductAdded.promocao_quantidade_multipla &&
    finalQuantity >=
    currentProductAdded.preco_quantidade_multipla[0]?.quantidade_multipla
  ) {
    const { quantityWithPromotion, quantityWithoutPromotion } =
      yield checksPricePromotionByMultipleQuantity(
        currentProductAdded,
        finalQuantity
      );

    const finalPriceWithRewardsProgram =
      currentProductAdded.preco_quantidade_multipla.find((preco) => {
        return preco.programa_fidelidade;
      });

    const finalPriceWithoutRewardsProgram =
      currentProductAdded.preco_quantidade_multipla.find((preco) => {
        return !preco.programa_fidelidade;
      });
    console.log(
      !participateRewardsProgram &&
      !isEmpty(finalPriceWithRewardsProgram) &&
      isEmpty(finalPriceWithoutRewardsProgram)
    );
    if (
      !participateRewardsProgram &&
      !isEmpty(finalPriceWithRewardsProgram) &&
      isEmpty(finalPriceWithoutRewardsProgram)
    ) {
      const newProduct = yield addProduct(
        currentProductAdded,
        finalQuantity,
        participateRewardsProgram
      );
      basket.push({
        ...newProduct,
        tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
      });
    } else {
      console.log(
        participateRewardsProgram &&
        !isEmpty(finalPriceWithRewardsProgram) &&
        quantityWithPromotion > 0
      );
      if (
        participateRewardsProgram &&
        !isEmpty(finalPriceWithRewardsProgram) &&
        quantityWithPromotion > 0
      ) {
        basket.push({
          ...currentProductAdded,
          promocao_aplicada: true,
          tipo_promocao: "PROMOCAO_QUANTIDADE_MULTIPLA",
          quantidade: quantityWithPromotion,
          preco_mostrar_cesta: finalPriceWithRewardsProgram,
          brotherQuantity: quantityWithoutPromotion,
        });
      } else if (
        quantityWithPromotion > 0 &&
        !isEmpty(finalPriceWithoutRewardsProgram)
      ) {
        basket.push({
          ...currentProductAdded,
          promocao_aplicada: true,
          tipo_promocao: "PROMOCAO_QUANTIDADE_MULTIPLA",
          quantidade: quantityWithPromotion,
          preco_mostrar_cesta: finalPriceWithoutRewardsProgram,
          brotherQuantity: quantityWithoutPromotion,
        });
      }
      console.log(quantityWithoutPromotion > 0);
      if (quantityWithoutPromotion > 0) {
        const newProduct = yield addProduct(
          currentProductAdded,
          quantityWithoutPromotion,
          participateRewardsProgram
        );

        basket.push({
          ...newProduct,
          brotherQuantity: quantityWithPromotion,
          tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
        });
      }
    }
  } else if (currentProductAdded.promocao_quantidade_minima) {
    const finalPriceWithRewardsProgram = findLast(
      currentProductAdded.preco_quantidade_minima,
      function (preco) {
        return (
          preco.quantidade_minima <= finalQuantity && preco.programa_fidelidade
        );
      }
    );

    const finalPriceWithoutRewardsProgram = findLast(
      currentProductAdded.preco_quantidade_minima,
      function (preco) {
        return (
          preco.quantidade_minima <= finalQuantity && !preco.programa_fidelidade
        );
      }
    );

    let productWithNewPrice = {};

    if (participateRewardsProgram && !isEmpty(finalPriceWithRewardsProgram)) {
      productWithNewPrice = {
        ...currentProductAdded,
        promocao_aplicada: true,
        quantidade: finalQuantity,
        preco_mostrar_cesta: finalPriceWithRewardsProgram,
        tipo_promocao: "PROMOCAO_QUANTIDADE_MINIMA",
      };
    } else {
      if (isEmpty(finalPriceWithoutRewardsProgram)) {
        productWithNewPrice = yield addProduct(
          currentProductAdded,
          finalQuantity,
          participateRewardsProgram
        );
      } else {
        let tipo_promocao = "PROMOCAO_QUANTIDADE_MINIMA";

        productWithNewPrice = {
          ...currentProductAdded,
          promocao_aplicada: true,
          quantidade: finalQuantity,
          preco_mostrar_cesta: finalPriceWithoutRewardsProgram,
          tipo_promocao,
        };
      }
    }

    basket.push(productWithNewPrice);
  } else {
    const newProduct = yield addProduct(
      currentProductAdded,
      finalQuantity,
      participateRewardsProgram
    );

    basket.push(newProduct);
  }

  const basketOrdered = basket.sort(function (a, b) {
    if (a.nome < b.nome) {
      return -1;
    }
    if (a.nome > b.nome) {
      return 1;
    }
    return 0;
  });

  yield saveCartStorage(basketOrdered);
  yield put(CartActions.addProductSuccess(basketOrdered));
  yield setQuantityProducts();
  yield setQuantityProductsFound();
  yield setQuantityBannerProducts();
  yield calculateAmount();
  yield toast.success(isUpdate ? "Produto atualizado com sucesso!" : "Produto adicionado com sucesso!");
}

export function* addProductListBasket({ payload }) {
  yield delay(500);

  const { currentProductListAdded } = payload;

  let basket = yield getCartFromStorage();

  let updatedBasket = [];
  let productFound = basket.filter((basketProduct) => {
    const product = currentProductListAdded.find(
      (productOnBasket) => basketProduct.id === productOnBasket.id
    );
    if (!product) {
      updatedBasket.push(basketProduct);
    }
    return currentProductListAdded.find(
      (currentProduct) => basketProduct.id === currentProduct.id
    );
  });

  currentProductListAdded.forEach((product) => {
    const finalQuantity =
      (productFound.find((e) => e.id === product.id)?.quantidade || 0) +
      product.quantidade;
    if (finalQuantity < 50)
      updatedBasket.push({
        ...product,
        quantidade: finalQuantity,
        promocao_aplicada: false,
        preco_mostrar_cesta: product.preco_normal,
        tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
      });
  });

  const basketOrdered = sortBy(updatedBasket, "name");

  yield saveCartStorage(basketOrdered);
  yield put(CartActions.addProductSuccess(basketOrdered));
  yield setQuantityProducts();
  yield setQuantityProductsFound();
  yield setQuantityBannerProducts();
  yield calculateAmount();
}

export function* addProduct(product, quantity, participateRewardsProgram) {
  if (
    !isEmpty(product.preco_programa_fidelidade) &&
    participateRewardsProgram
  ) {
    return yield {
      ...product,
      quantidade: quantity,
      promocao_aplicada: true,
      preco_mostrar_cesta: product.preco_programa_fidelidade,
      tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
    };
  }

  return yield {
    ...product,
    quantidade: quantity,
    promocao_aplicada: false,
    preco_mostrar_cesta: product.preco_normal,
    tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
  };
}

export function* checksPricePromotionByMultipleQuantity(product, quantity) {
  //Pega o quociente da divisão multiplicado pelo dividor (Quantidade do produto com promoção)
  let quantityWithPromotion =
    Math.trunc(
      quantity / product.preco_quantidade_multipla[0].quantidade_multipla
    ) * product.preco_quantidade_multipla[0].quantidade_multipla;

  //Pega o resto da divisão (Quantidade do produto sem promoção)
  let quantityWithoutPromotion =
    quantity % product.preco_quantidade_multipla[0].quantidade_multipla;

  return yield { quantityWithPromotion, quantityWithoutPromotion };
}

export function* calculateAmount() {
  const storageProducts = yield getCartFromStorage();

  const amount = storageProducts.reduce(
    (acc, product) =>
      acc +
      parseFloat(
        product.preco_mostrar_cesta.preco_por || product.preco_mostrar_cesta
      ) *
      product.quantidade,
    0
  );

  yield put(CartActions.setAmount(amount));
}

export function* shakeAnimation() {
  yield put(CartActions.shakeAnimationStart());
  yield delay(1500);
  yield put(CartActions.shakeAnimationEnd());
}

export function* setQuantityProducts() {
  const { data: stateProducts } = yield select((state) => state.products);
  const storageProducts = yield getCartFromStorage();

  const products = stateProducts.map((stateProduct) => {
    const storageProduct = storageProducts.find(
      (storageProduct) => storageProduct.id === stateProduct.id
    );

    return {
      ...stateProduct,
      quantidade: !storageProduct
        ? stateProduct.quantidade
        : stateProduct.quantidade !== storageProduct.quantidade
          ? storageProduct.quantidade
          : stateProduct.quantidade,
    };
  });

  yield put(ProductsActions.setDataSuccess(products));
}

export function* removeProductCart(action) {
  const storageProducts = yield getCartFromStorage();
  const user = yield select((state) => state.user.profile);
  const signed = yield select((state) => state.auth.signed);

  const { data: stateProducts } = yield select((state) => state.products);

  const cart = storageProducts.filter(
    (storageProduct, index) => index !== action.payload.key
  );

  const cartUpdated = cart.map((storageProduct) => {
    if (storageProduct.id === action.payload.currentProductRemoved.id) {
      return { ...storageProduct, brotherQuantity: 0 };
    }
    return storageProduct;
  });

  const newStateProducts = stateProducts.map((stateProduct) => {
    return {
      ...stateProduct,
      quantidade:
        action.payload.currentProductRemoved.id === stateProduct.id
          ? 0
          : stateProduct.quantidade,
    };
  });

  signed
    ? yield localStorage.setItem(
      `@ConexaoGAM:cart#${user.uuid}`,
      JSON.stringify(cartUpdated)
    )
    : yield localStorage.setItem(
      `@ConexaoGAM:cart`,
      JSON.stringify(cartUpdated)
    );

  if (cartUpdated.length == 0) {
    localStorage.removeItem("@ConexaoGAM:checkout");
  }

  yield calculateAmount();
  yield put(CartActions.removeProductSuccess(cartUpdated));
  yield put(ProductsActions.setDataSuccess(newStateProducts));
}

export function* setOrderStorage(action) {
  localStorage.setItem(
    "@ConexaoGAM:checkout",
    JSON.stringify(action.payload.order)
  );
  const data = yield getOrderFromStorage();
  const cart = yield getCartFromStorage();

  yield put(CartActions.setOrderStorageSuccess({ data, cart }));
}

export function* getOrderAndCartFromStorage() {
  const cart = yield getCartFromStorage();
  const data = yield getOrderFromStorage();

  yield put(CartActions.setOrderStorageSuccess({ data, cart }));
}

export function* getCartFromStorage() {
  const signed = yield select((state) => state.auth.signed);

  if (!signed) {
    return yield JSON.parse(localStorage.getItem(`@ConexaoGAM:cart`)) || [];
  } else {
    const user = yield select((state) => state.user.profile);
    return yield JSON.parse(
      localStorage.getItem(`@ConexaoGAM:cart#${user?.uuid}`)
    ) || [];
  }
}

export function* setCartInState() {
  const signed = yield select((state) => state.auth.signed);
  let cart = [];

  if (!signed) {
    cart = yield JSON.parse(localStorage.getItem(`@ConexaoGAM:cart`)) || [];
  } else {
    const user = yield select((state) => state.user.profile);
    cart = yield JSON.parse(
      localStorage.getItem(`@ConexaoGAM:cart#${user?.uuid}`)
    ) || [];
  }

  yield put(CartActions.addProductSuccess(cart));
}

export function* saveCartStorage(cart = []) {
  const signed = yield select((state) => state.auth.signed);
  const user = yield select((state) => state.user.profile);

  if (!signed) {
    yield localStorage.setItem(`@ConexaoGAM:cart`, JSON.stringify(cart));
  } else {
    yield localStorage.setItem(
      `@ConexaoGAM:cart#${user?.uuid}`,
      JSON.stringify(cart)
    );
  }
}

export function* createCartOnUserSign() {
  const user = yield select((state) => state.user.profile);
  const cart = yield JSON.parse(localStorage.getItem("@ConexaoGAM:cart"));
  const cartUser = yield JSON.parse(
    localStorage.getItem(`@ConexaoGAM:cart#${user?.uuid}`)
  ) || [];
  let newCart = [];

  if (cart.length > 0 && cartUser.length === 0) {
    newCart = yield JSON.parse(localStorage.getItem(`@ConexaoGAM:cart`)) || [];
    yield localStorage.setItem(
      `@ConexaoGAM:cart#${user?.uuid}`,
      JSON.stringify(cart)
    );
  } else if (cart.length > 0 && cartUser.length > 0) {
    const newItems = cart.filter(
      (item) => !cartUser.find((itemUser) => itemUser.id === item.id)
    );

    let userItems = [];

    cartUser.forEach((itemUser) => {
      const itemFound = cart.find((item) => item.id === itemUser.id);

      itemFound ? userItems.push(itemFound) : userItems.push(itemUser);
    });

    newCart = [...newItems, ...userItems];

    yield localStorage.setItem(
      `@ConexaoGAM:cart#${user?.uuid}`,
      JSON.stringify(newCart)
    );
  } else {
    newCart = cartUser;
  }

  yield localStorage.removeItem(`@ConexaoGAM:cart`);
  yield put(CartActions.addProductSuccess(newCart));
  yield reorganizeBasket();
  yield setQuantityProductsFound();
  yield setQuantityBannerProducts();
  yield setQuantityProducts();
}

export function* getOrderFromStorage() {
  return yield JSON.parse(localStorage.getItem("@ConexaoGAM:checkout")) || null;
}

export function* removeProductsFromCart({ payload }) {
  const itemsOutOfStock = payload.itemsNotAccepted;
  const acceptedItems = payload.acceptedItems.filter(
    (item) => item.transfer === true
  );
  const cartItems = yield getCartFromStorage();

  const cartParcialUpdated = yield cartItems.filter(
    (itemCart) => !itemsOutOfStock.find((item) => item.id === itemCart.id)
  );

  const cartUpdated = yield cartParcialUpdated.map((item) => {
    const itemFound = acceptedItems.find(
      (acceptedItem) => acceptedItem.id === item.id
    );

    if (typeof itemFound !== "undefined") {
      return { ...item, quantidade: itemFound.estoque_loja };
    }

    return item;
  });

  if (cartUpdated.length == 0) {
    localStorage.removeItem("@ConexaoGAM:checkout");
  }

  yield saveCartStorage(cartUpdated);
  yield setCartInState();
  yield setQuantityProductsFound();
  yield setQuantityProducts();
  yield calculateAmount();
  yield put(OrderActions.resetError());
}

export function* checkOutOfStockProductsByStore() {
  const cartItems = yield select((state) => state.cart.data);
  const storeByUser = yield select((state) => state.storeByUser.data);

  if (cartItems.length === 0) return;
  if (storeByUser === null) return;

  const products = yield cartItems.map((item) => {
    return item.url;
  });

  const { data } = yield call(api.post, `/products/check-stock`, {
    store: storeByUser.uuid,
    products,
  });

  const outOfStockItems = yield data.filter((item) => item.estoque_loja === 0);

  const outOfStockItemsCart = yield cartItems.filter((itemCart) =>
    outOfStockItems.find((item) => item.id === itemCart.id)
  );

  yield put(StoreByUserActions.setOutOfStockItems(outOfStockItemsCart));

  if (outOfStockItems.length > 0) {
    yield put(StoreByUserActions.toggleOutOfStockModal());
  }
}

export function* deleteOutOfStockItemsFromCart() {
  const cartItems = yield select((state) => state.cart.data);
  const outOfStockItems = yield select(
    (state) => state.storeByUser.outOfStockItems
  );

  const newCart = yield cartItems.filter(
    (itemCart) => !outOfStockItems.find((item) => item.id === itemCart.id)
  );

  yield saveCartStorage(newCart);
  yield put(CartActions.addProductSuccess(newCart));
  yield put(StoreByUserActions.setOutOfStockItems([]));
  yield put(StoreByUserActions.toggleOutOfStockModal());
  yield put(CartActions.calculateAmount());
}

export function* reduceBasketProducts() {
  let finalBasket = [];
  const basket = yield getCartFromStorage();

  basket.forEach((product) => {
    const productFound = finalBasket.find((item) => item.id === product.id);

    if (!productFound) {
      const quantidade = product.quantidade + (product.brotherQuantity ?? 0);
      delete product?.promocao_aplicada;
      delete product?.preco_mostrar_cesta;
      delete product?.tipo_promocao;
      delete product?.brotherQuantity;

      finalBasket.push({ ...product, quantidade });
    }
  });

  return yield finalBasket;
}

export function* reorganizeBasket() {
  const participateRewardsProgram = yield select(
    (state) => state.user.profile?.programa_fidelidade ?? true
  );

  let reducedBasket = yield reduceBasketProducts();
  let basket = [];

  for (let currentProductAdded of reducedBasket) {
    const amountAdded = currentProductAdded.quantidade;
    const sum = false;

    //Pega quantidade existente do produto ou coloca zero
    const existingQuantity = basket.reduce((qty, basketProduct) => {
      if (basketProduct.id === currentProductAdded.id) {
        return qty + basketProduct.quantidade;
      }
      return 0;
    }, 0);

    //Verifica se o produto já está na cesta.
    let productFound = basket.find(
      (basketProduct) => basketProduct.id === currentProductAdded.id
    );

    //Tira o/os produtos já existentes na sexta
    basket = basket.filter(
      (basketProduct) => basketProduct.id !== currentProductAdded.id
    );

    //Aplica a quantidade final do produto na cesta.
    const finalQuantity = productFound
      ? sum
        ? existingQuantity + amountAdded
        : amountAdded
      : amountAdded;

    if (finalQuantity > 50) return;

    //Verifica se o produto adicionado tem promoção por quantidade Múltipla
    if (
      currentProductAdded.promocao_quantidade_multipla &&
      finalQuantity >=
      currentProductAdded.preco_quantidade_multipla[0]?.quantidade_multipla
    ) {
      const { quantityWithPromotion, quantityWithoutPromotion } =
        yield checksPricePromotionByMultipleQuantity(
          currentProductAdded,
          finalQuantity
        );

      const finalPriceWithRewardsProgram =
        currentProductAdded.preco_quantidade_multipla.find((preco) => {
          return preco.programa_fidelidade;
        });

      const finalPriceWithoutRewardsProgram =
        currentProductAdded.preco_quantidade_multipla.find((preco) => {
          return !preco.programa_fidelidade;
        });

      if (
        !participateRewardsProgram &&
        !isEmpty(finalPriceWithRewardsProgram) &&
        isEmpty(finalPriceWithoutRewardsProgram)
      ) {
        const newProduct = yield addProduct(
          currentProductAdded,
          finalQuantity,
          participateRewardsProgram
        );
        basket.push({
          ...newProduct,
          tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
        });
      } else {
        if (
          participateRewardsProgram &&
          !isEmpty(finalPriceWithRewardsProgram) &&
          quantityWithPromotion > 0
        ) {
          basket.push({
            ...currentProductAdded,
            promocao_aplicada: true,
            tipo_promocao: "PROMOCAO_QUANTIDADE_MULTIPLA",
            quantidade: quantityWithPromotion,
            preco_mostrar_cesta: finalPriceWithRewardsProgram,
            brotherQuantity: quantityWithoutPromotion,
          });
        } else if (
          quantityWithPromotion > 0 &&
          !isEmpty(finalPriceWithoutRewardsProgram)
        ) {
          basket.push({
            ...currentProductAdded,
            promocao_aplicada: true,
            tipo_promocao: "PROMOCAO_QUANTIDADE_MULTIPLA",
            quantidade: quantityWithPromotion,
            preco_mostrar_cesta: finalPriceWithoutRewardsProgram,
            brotherQuantity: quantityWithoutPromotion,
          });
        }

        if (quantityWithoutPromotion > 0) {
          const newProduct = yield addProduct(
            currentProductAdded,
            quantityWithoutPromotion,
            participateRewardsProgram
          );

          basket.push({
            ...newProduct,
            brotherQuantity: quantityWithPromotion,
            tipo_promocao: "SEM_PROMOCAO_POR_QUANTIDADE",
          });
        }
      }
    } else if (currentProductAdded.promocao_quantidade_minima) {
      const finalPriceWithRewardsProgram = findLast(
        currentProductAdded.preco_quantidade_minima,
        function (preco) {
          return (
            preco.quantidade_minima <= finalQuantity &&
            preco.programa_fidelidade
          );
        }
      );

      const finalPriceWithoutRewardsProgram = findLast(
        currentProductAdded.preco_quantidade_minima,
        function (preco) {
          return (
            preco.quantidade_minima <= finalQuantity &&
            !preco.programa_fidelidade
          );
        }
      );

      let productWithNewPrice = {};

      if (participateRewardsProgram && !isEmpty(finalPriceWithRewardsProgram)) {
        productWithNewPrice = {
          ...currentProductAdded,
          promocao_aplicada: true,
          quantidade: finalQuantity,
          preco_mostrar_cesta: finalPriceWithRewardsProgram,
          tipo_promocao: "PROMOCAO_QUANTIDADE_MINIMA",
        };
      } else {
        if (isEmpty(finalPriceWithoutRewardsProgram)) {
          productWithNewPrice = yield addProduct(
            currentProductAdded,
            finalQuantity,
            participateRewardsProgram
          );
        } else {
          let tipo_promocao = "PROMOCAO_QUANTIDADE_MINIMA";

          productWithNewPrice = {
            ...currentProductAdded,
            promocao_aplicada: true,
            quantidade: finalQuantity,
            preco_mostrar_cesta: finalPriceWithoutRewardsProgram,
            tipo_promocao,
          };
        }
      }

      basket.push(productWithNewPrice);
    } else {
      const newProduct = yield addProduct(
        currentProductAdded,
        finalQuantity,
        participateRewardsProgram
      );

      basket.push(newProduct);
    }
  }

  const basketOrdered = basket.sort(function (a, b) {
    if (a.nome < b.nome) {
      return -1;
    }
    if (a.nome > b.nome) {
      return 1;
    }
    return 0;
  });

  yield saveCartStorage(basketOrdered);
  yield put(CartActions.addProductSuccess(basketOrdered));
  yield calculateAmount();
}

export function* getProductListRequest(action) {
  const { requestedProductList, storeId } = action.payload;
  try {
    const resp = yield all(
      requestedProductList.map((e) => {
        return call(api.get, `/products/${e}`);
      })
    );
    const items = resp.map((e) => e.data);

    yield put(ProductsActions.getAlreadyRequestedProducts(items));
  } catch (err) {
    console.log(err);
    put(ErrorActions.setError("Não foi possível obter o produto"));
  }
}
