import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { setTitle } from "../../services/browser";
import { SignUpSchema, cpfMask, dateMask, phoneMask } from "./validation";

import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../components/Error";
import api from "../../services/api";
import { Creators as UserActions } from "../../store/ducks/user";
import {
  Column,
  Container,
  Content,
  FieldErrorMessage,
  FormContainer,
  FormGroup,
  InputField,
  Label,
  LoaderContainer,
  RadioGroup,
  SubmitButton
} from "./styles";

export default function Account() {
  const dispatch = useDispatch();

  const {
    loading
  } = useSelector(state => state.user);
  const [user, setUser] = useState(null);
  const [date, setDate] = useState("");

  useEffect(() => {
    setTitle("Meus Dados");

    async function getUser() {
      const { data } = await api.get(`/customers/account`);
      setUser(data);
      const date = data.data_nascimento.split("-");
      setDate(`${date[2]}/${date[1]}/${date[0]}`);
    }

    getUser();
  }, []);

  function handleSubmit({
    cpf,
    nome,
    email,
    senha,
    data_nascimento,
    celular: celularComFormatacao,
    genero
  }) {
    const celular = celularComFormatacao.replace(/[^a-zA-Z0-9]/g, "");

    dispatch(
      UserActions.updateUserRequest(
        cpf,
        nome,
        email,
        data_nascimento,
        celular,
        genero
      )
    );
  }

  return (
    <>
      <Container>
        <Content>
          <Error errorKey="user-update" style={{ marginBottom: 20 }} />
          <div className="form-container">
            {user && date ? (
              <Formik
                initialValues={{
                  cpf: user.cpf,
                  nome: user.nome,
                  email: user.email,
                  data_nascimento: date,
                  celular: user.celular,
                  genero: user.genero
                }}
                validationSchema={SignUpSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  handleSubmit,
                  isSubmitting
                }) => (
                    <FormContainer onSubmit={handleSubmit}>
                      <Column>
                        <FormGroup>
                          {errors.email && touched.email ? (
                            <FieldErrorMessage>{errors.email}</FieldErrorMessage>
                          ) : null}

                          <InputField
                            invalid={errors.email && touched.email}
                            id="email"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Ex: fulano@gmail.com"
                          />
                          <Label>E-mail*</Label>
                        </FormGroup>

                        <FormGroup>
                          {errors.nome && touched.nome ? (
                            <FieldErrorMessage>{errors.nome}</FieldErrorMessage>
                          ) : null}

                          <InputField
                            invalid={errors.nome && touched.nome}
                            id="nome"
                            type="text"
                            name="nome"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nome}
                            placeholder="Ex: Fulano da Silva"
                          />
                          <Label>Nome Completo*</Label>
                        </FormGroup>

                        <FormGroup>
                          {errors.cpf && touched.cpf ? (
                            <FieldErrorMessage>{errors.cpf}</FieldErrorMessage>
                          ) : null}

                          <MaskedInput
                            mask={cpfMask}
                            invalid={errors.cpf && touched.cpf}
                            id="cpf"
                            disabled
                            placeholder="Ex: 000.000.000-00"
                            name="cpf"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cpf}
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />
                          <Label htmlFor="cpf">CPF*</Label>
                        </FormGroup>

                        <FormGroup>
                          <MaskedInput
                            mask={dateMask}
                            invalid={
                              errors.data_nascimento && touched.data_nascimento
                            }
                            id="data_nascimento"
                            placeholder="Ex. 01/01/1999"
                            name="data_nascimento"
                            value={values.data_nascimento}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />

                          <Label htmlFor="data_nascimento">
                            Data de Nascimento*
                      </Label>
                        </FormGroup>

                        <FormGroup>
                          {errors.celular && touched.celular ? (
                            <FieldErrorMessage>{errors.celular}</FieldErrorMessage>
                          ) : null}

                          <MaskedInput
                            mask={phoneMask}
                            invalid={errors.celular && touched.celular}
                            id="celular"
                            placeholder="Ex: (00) 0000-0000"
                            name="celular"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.celular}
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />
                          <Label htmlFor="celular">Telefone*</Label>
                        </FormGroup>

                        <FormGroup>
                          {errors.genero && touched.genero ? (
                            <FieldErrorMessage>{errors.genero}</FieldErrorMessage>
                          ) : null}

                          <RadioGroup>
                            <div className="radio">
                              <input
                                id="radio-1"
                                value="M"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="genero"
                                type="radio"
                                checked={values.genero === "M" ? true : false}
                              />
                              <label htmlFor="radio-1" className="radio-label">
                                Masculino
                          </label>
                            </div>

                            <div className="radio">
                              <input
                                id="radio-2"
                                value="F"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="genero"
                                type="radio"
                                checked={values.genero === "F" ? true : false}
                              />
                              <label htmlFor="radio-2" className="radio-label">
                                Feminino
                          </label>
                            </div>
                          </RadioGroup>
                          <Label>Sexo*</Label>
                        </FormGroup>

                        <SubmitButton type="submit" disabled={loading}>
                          {loading ? "SALVANDO DADOS..." : "SALVAR DADOS"}
                        </SubmitButton>
                      </Column>
                    </FormContainer>
                  )}
              </Formik>
            ): <LoaderContainer>
              <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
            </LoaderContainer>}
          </div>
        </Content>
      </Container>
    </>
  );
}
