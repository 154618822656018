import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as StoreActions } from "../../store/ducks/store";

export function* getStore() {
  try {
    const { data } = yield call(api.get, `/stores/01741616000348`);

    yield put(StoreActions.getSuccess(data));
  } catch (err) { }
}
// RIP
