import React, { useState, useEffect } from "react";
import RewardsProgramTerm from "../../components/RewardsProgramTerm";
import { Creators as UserActions } from "../../store/ducks/user";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "./styles";
import api from "../../services/api";
import { setTitle } from "../../services/browser";
import Checkbox from "../../components/Checkbox";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function RewardsProgramAcceptance() {
  const dispatch = useDispatch();

  const user = useSelector(
    state => state.user.profile
  );

  const [checked, setChecked] = useState(user.programa_fidelidade);

  async function submitRewards(checkedInput) {
    setChecked(checkedInput);

    const { data } = await api.put(`/customers/${user.uuid}/rewards-program`, {
      programaFidelidade: checkedInput
    });

    if(checkedInput) {
      toast.success('Ótimo! Agora você faz parte do cartão vantagens');
    }else {
      toast.error('Que pena! Você não faz mais parte do cartão vantagens');
    }

    dispatch(UserActions.updateUserSuccess(data.cliente));
    dispatch(UserActions.updateRewardsProgram());
  }

  useEffect(() => {
    setTitle("Cartão Vantagens")
  }, [])

  return (
    <Container>
      <RewardsProgramTerm />
      <form className="rewards-form-acceptance">

        <label htmlFor="rewards-program-checkbox">
        <Checkbox
          id="rewards-program-checkbox"
          checked={checked}
          onChange={e => submitRewards(e.target.checked)}
        />

          <span style={{ marginLeft: 8 }}>Quero participar do cartão vantagens e estou de acordo com os termos descritos acima</span>
        </label>

      </form>
    </Container>
  );
}
