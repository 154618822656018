import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  AddressForm,
  Label,
  FormGroup,
  FieldErrorMessage,
  InputField,
  SubmitBtn
} from "./styles";
import { Formik } from "formik";
import { AddressSchema, phoneMask, cepMask } from "./validation";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AddressActions } from "../../store/ducks/address";
import MaskedInput from "react-text-mask";
import axios from "axios";
import Error from "../../components/Error";
import api from "../../services/api";
import { setTitle } from "../../services/browser";

export default function CreateUpdateAddress({
  history,
  match: {
    params: { addressId }
  }
}) {
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const { uuid } = useSelector(state => state.user.profile);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {


    async function getAddressById() {
      if (addressId) {
        setTitle("Atualizar Endereço");
        const { data } = await api.get(
          `/customers/${uuid}/address/${addressId}`
        );
        setInitialValues({
          nome_contato: data.nome_contato,
          telefone_contato: data.telefone_contato,
          cep: data.cep,
          uf: data.cidade.uf_sigla,
          cidade: data.cidade.nome,
          bairro: data.bairro,
          numero: data.numero,
          rua: data.endereco,
          complemento: data.complemento,
          ibge: data.cidade.ibge_id
        });
        setIsUpdate(true);
      } else {
        setTitle("Novo Endereço");
        setInitialValues({
          nome_contato: "",
          telefone_contato: "",
          cep: "",
          uf: "",
          cidade: "",
          bairro: "",
          numero: "",
          rua: "",
          complemento: "",
          ibge: ""
        });
      }
    }

    getAddressById();
  }, [addressId, uuid]);

  const { loading } = useSelector(state => state.address);

  function handleSubmit(values) {
    const {
      nome_contato,
      telefone_contato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge
    } = values;

    const telefone = telefone_contato.replace(/[^a-zA-Z0-9]/g, "");
    const cepWithoutMask = cep.replace(/[^a-zA-Z0-9]/g, "");
    if (!isUpdate) {
      dispatch(
        AddressActions.addressStoreRequest(
          {
            nome_contato,
            telefone_contato: telefone,
            cep: cepWithoutMask,
            uf,
            cidade,
            bairro,
            numero,
            rua,
            complemento,
            ibge
          },
          false
        )
      );
      return;
    }

    dispatch(
      AddressActions.addressUpdateRequest({
        nomeContato: nome_contato,
        telefoneContato: telefone,
        cep: cepWithoutMask,
        uf,
        cidade,
        bairro,
        numero,
        rua,
        complemento,
        ibge,
        addressId
      })
    );
  }

  return (
    <Container>
      <AddressForm>
        <Error errorKey="create-update-address" style={{ marginBottom: 20 }} />
        {initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={AddressSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleReset,
              isSubmitting,
              isValid
            }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      {errors.nome_contato && touched.nome_contato ? (
                        <FieldErrorMessage>
                          {errors.nome_contato}
                        </FieldErrorMessage>
                      ) : null}

                      <InputField
                        invalid={errors.nome_contato && touched.nome_contato}
                        id="nome_contato"
                        type="text"
                        name="nome_contato"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nome_contato}
                        placeholder="Ex: Fulano da Silva"
                      />
                      <Label>Nome para contato*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.telefone_contato && touched.telefone_contato ? (
                        <FieldErrorMessage>
                          {errors.telefone_contato}
                        </FieldErrorMessage>
                      ) : null}

                      <MaskedInput
                        name="telefone_contato"
                        type="text"
                        placeholder="Ex: (__) ____-____"
                        mask={phoneMask}
                        invalid={
                          errors.telefone_contato && touched.telefone_contato
                        }
                        id="registerNumber"
                        value={values.telefone_contato}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        render={(ref, props) => (
                          <InputField ref={input => ref(input)} {...props} />
                        )}
                      />

                      <Label>Telefone Contato*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.cep && touched.cep ? (
                        <FieldErrorMessage>{errors.cep}</FieldErrorMessage>
                      ) : null}

                      <MaskedInput
                        invalid={errors.cep && touched.cep}
                        id="cep"
                        type="text"
                        name="cep"
                        mask={cepMask}
                        onChange={handleChange}
                        onKeyUp={async () => {
                          const cep = values.cep.replace(/[^a-zA-Z0-9]/g, "");
                          if (cep.length === 8) {
                            const { data } = await axios.get(
                              `https://viacep.com.br/ws/${values.cep}/json`
                            );
                            setFieldValue("uf", data.uf || "");
                            setFieldValue("cidade", data.localidade || "");
                            setFieldValue("rua", data.logradouro || "");
                            setFieldValue("bairro", data.bairro || "");
                            setFieldValue("ibge", data.ibge || "");
                          }
                        }}
                        value={values.cep}
                        onBlur={handleBlur}
                        placeholder="Ex: _____-___"
                        render={(ref, props) => (
                          <InputField ref={input => ref(input)} {...props} />
                        )}
                      />

                      <Label>CEP*</Label>
                    </FormGroup>

                    <div></div>

                    <FormGroup>
                      {errors.uf && touched.uf ? (
                        <FieldErrorMessage>{errors.uf}</FieldErrorMessage>
                      ) : null}

                      <InputField
                        invalid={errors.uf && touched.uf}
                        id="uf"
                        type="uf"
                        name="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        value={values.uf}
                        placeholder="Ex: Santa Catarina"
                      />
                      <Label>Estado*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.cidade && touched.cidade ? (
                        <FieldErrorMessage>{errors.cidade}</FieldErrorMessage>
                      ) : null}

                      <InputField
                        disabled
                        invalid={errors.cidade && touched.cidade}
                        id="cidade"
                        type="text"
                        name="cidade"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cidade}
                        placeholder="Ex: Tubarão"
                      />
                      <Label>Cidade*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.bairro && touched.bairro ? (
                        <FieldErrorMessage>{errors.bairro}</FieldErrorMessage>
                      ) : null}

                      <InputField
                        invalid={errors.bairro && touched.bairro}
                        id="bairro"
                        type="text"
                        name="bairro"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bairro}
                        placeholder="Ex: Santo Antonio de Pádua"
                      />
                      <Label>Bairro*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.numero && touched.numero ? (
                        <FieldErrorMessage>{errors.numero}</FieldErrorMessage>
                      ) : null}

                      <InputField
                        invalid={errors.numero && touched.numero}
                        id="numero"
                        type="number"
                        name="numero"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numero}
                        placeholder="Ex: 1191"
                      />
                      <Label>Número*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.rua && touched.rua ? (
                        <FieldErrorMessage>{errors.rua}</FieldErrorMessage>
                      ) : null}

                      <InputField
                        invalid={errors.rua && touched.rua}
                        id="rua"
                        type="text"
                        name="rua"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.rua}
                        placeholder="Ex: Rua/Av. Fulano da Silva"
                      />
                      <Label>Rua/Avenida*</Label>
                    </FormGroup>

                    <FormGroup>
                      {errors.complemento && touched.complemento ? (
                        <FieldErrorMessage>
                          {errors.complemento}
                        </FieldErrorMessage>
                      ) : null}

                      <InputField
                        invalid={errors.complemento && touched.complemento}
                        id="complemento"
                        type="text"
                        name="complemento"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.complemento}
                        placeholder="Ex: Andar/Apartamento (opcional)"
                      />
                      <Label>Complemento</Label>
                    </FormGroup>

                    <SubmitBtn type="submit">
                      {loading ? "SALVANDO ENDEREÇO..." : "SALVAR ENDEREÇO"}
                    </SubmitBtn>
                  </Form>
                </>
              )}
          </Formik>
        )}
      </AddressForm>
    </Container>
  );
}
