import React from "react";
import closeRound from "../../assets/images/close-round.svg"
import { ContainerCloseRound } from "./styles.js";

const CloseRound = ({ onClick, className }) => {
    return (
        <ContainerCloseRound className={className} onClick={onClick} src={closeRound} alt="Icon Close Round"/>
    )
}

export default CloseRound;