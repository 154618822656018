import styled from 'styled-components';

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.black};
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
`;

export const Input = styled.input`
    height: 45px;
    background: #ffffff;
    border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    width: 100%;
    border-radius: 8px;

    + label {
    color: ${props => props.theme.colors.black};
    }

    :focus {
    border: 1px solid
        ${props =>
        props.invalid
            ? props.theme.colors.warning
            : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    :focus + label {
    color: ${props => props.theme.colors.black};
    }
`;