import * as Yup from "yup";
import axios from "axios";

const cpfValidation = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;


export const ThirdPartyWithdrawalSchema = Yup.object().shape({
  retirada_terceiro: Yup.string(),
  nome_completo: Yup.string()
  .when("retirada_terceiro", {
    is: (retirada_terceiro) => retirada_terceiro === 'true',
    then: Yup.string().required("Nome não pode ser vazio")
  }),
  cpf: Yup.string()
  .when("retirada_terceiro", {
    is: (retirada_terceiro) => retirada_terceiro === 'true',
    then: Yup.string()
    .required("CPF não pode ser vazio")
    .min(14, "O CPF deve conter 11 números com pontuação")
    .max(14, "O CPF deve conter 11 números com pontuação")
    .matches(cpfValidation, "Digite um CPF válido")
    .test("cpf", "Digite um CPF válido", (strCPF = "") => {
      strCPF = strCPF.replace(/[^\d]+/g, "");
      let soma;
      let resto;
      soma = 0;
      if (
        strCPF === "00000000000" ||
        strCPF === "11111111111" ||
        strCPF === "22222222222" ||
        strCPF === "33333333333" ||
        strCPF === "44444444444" ||
        strCPF === "55555555555" ||
        strCPF === "66666666666" ||
        strCPF === "77777777777" ||
        strCPF === "88888888888" ||
        strCPF === "99999999999"
      )
        return false;

      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(strCPF.substring(9, 10))) return false;

      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }),
  }),
  telefone_contato: Yup.string()
  .when("retirada_terceiro", {
    is: (retirada_terceiro) => retirada_terceiro === 'true',
    then: Yup.string().required("Telefone não pode ser vazio")
  }),
});

export const AddressSchema = Yup.object().shape({
  nome_contato: Yup.string().required("Nome não pode ser vazio"),
  rua: Yup.string().required("Rua não pode ser vazio"),
  bairro: Yup.string().required("Bairro não pode ser vazio"),
  numero: Yup.number("Deve ser um número")
    .required("Número não pode ser vazio")
    .integer("Número deve ser inteiro")
    .positive("Número não pode ser negativo"),
  complemento: Yup.string(),
  telefone_contato: Yup.string().test(
    "telefone_contato",
    "Telefone incompleto",
    strTelefone => {
      if (strTelefone) {
        const telefone = strTelefone.replace(/[^a-zA-Z0-9]/g, "");
        return telefone.length === 11 || telefone.length === 10;
      }
    }
  ),
  cep: Yup.string()
    .required("CEP não pode ser vazio")
    .test("cep", "CEP incompleto", async strCEP => {
      if (strCEP) {
        const cep = strCEP.replace(/[^a-zA-Z0-9]/g, "");
        return cep.length === 8;
      }
    })
    .test("cep", "O CEP informado é inexistente.", async strCEP => {
      try {
        const cep = strCEP.replace(/[^a-zA-Z0-9]/g, "");
        if (cep.length === 8) {
          const { data } = await axios.get(
            `https://viacep.com.br/ws/${strCEP}/json`
          );
          return data.erro === true ? false : true;
        }
      } catch (err) {
        return false;
      }
    })
});

export const moneyMask = [
  "R",
  "$",
  " ",
  /\d/,
  /\d/,
  /\d/,
  ",",
  /\d/,
  /\d/
];

export const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];