export function formatCPF(receivedCPF) {

  var cpf = receivedCPF.replace(/\D/g, ""); 

  if(cpf.length < 11) {
    while (cpf.length < 11) cpf = "0" + cpf;
  }
  
  cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
  
  return cpf;
}
