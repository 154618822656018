import React from 'react';
import { ButtonPrimary as ButtonPrimaryStyle } from './styles';

const ButtonPrimary = ({ text, onClick, className, disabled, style }) => {
    return (
        <ButtonPrimaryStyle disabled={disabled} type="submit" onClick={onClick} className={className} style={style}>
            {text}
        </ButtonPrimaryStyle>
    );
};

export default ButtonPrimary;