import React from "react";
import { Container } from "./styles";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

export default function RewardsProgramTerm() {
  const { programa_fidelidade_texto } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  return <Container>{ReactHtmlParser(programa_fidelidade_texto)}</Container>;
}
