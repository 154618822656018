import { darken } from "polished";
import styled from "styled-components";
import { device } from "../../styles/device";


export const Center = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const CategoryContainer = styled.div`
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  p {
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 10px;
    color: ${props => props.theme.colors.primary};
    text-align:center;

    -webkit-touch-callout: none;  /* iPhone OS, Safari */
    -webkit-user-select: none;    /* Chrome, Safari 3 */
    -khtml-user-select: none;     /* Safari 2 */
    -moz-user-select: none;       /* Firefox */
    -ms-user-select: none;        /* IE10+ */
    user-select: none;
  }

  @media (max-width: 600px) {
    padding: 0;

    p {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  svg {
      color: ${props => props.theme.colors.primary};
      animation: fadein 0.7s;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
`;

export const CategoryModalContainer = styled.nav`
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 445px;
  background: #fff;
  border-radius: 8px;
  position: fixed;
  padding: 32px 64px 32px 64px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.36);
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const LeftSideCategories = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
  height: 100%;
  min-height: 460px;
  border-right: 1px solid #eaeaea;
  list-style: none;

  a {
    font-size: 18px;
    line-height: 32px;
    color: #909090;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 15px;
    display: block;
    margin: 4px 0 4px 0;
  }
`;

export const RightSideCategories = styled.ul`
  list-style: none;
  width: 100%;

  li {
    margin: 12px 12px;
    height: auto;
    display: inline-flex;
  }

  a {
    padding: 12px;
  }
`;

export const CategoriesMobile = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  list-style: none;

  max-height: 600px;
  overflow: auto;


  #category {
    font-size: 18px;
    line-height: 32px;
    border-bottom: 1px solid #eaeaea;
    color: #909090;
    text-transform: uppercase;
    text-decoration: none;
    margin: 12px;
    display: block;
  }
`;


export const ListSubcategoriesMobile = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: 24px;
  padding: 12px;
`;

export const Signed = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }

  strong {
    span {
      font-weight: 400;
    }
    font-size: 1.7rem;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.4rem;
`;

export const Menu = styled.header`
  display: ${props => (props.hidden ? "none" : "block")};
  position: relative;
  top: 0px;
  width: 100%;
  background: ${props => props.theme.colors.background};
  transition: 0.4s;
  z-index: 2000;
  padding-top: 19px;
  padding-bottom: 2px;
  border-bottom: 1px solid var(--neutral-50, #F3F3F4);
  background: #FFF;
  margin-bottom: 24px;
  padding-bottom: 10px;

  .menu-btn-abrir{
    display: none;
    gap: 4px;
    margin-top: 42px;
    margin-bottom: 24px;
    span{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      img{
        width:24px;
      }
      h2{
        color: var(--neutral-900, #151922);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    @media (max-width: 768px) {
      display: flex;
    }
  }

  .menu-categorias{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
    .menu-topo{
      display: none;
    }
    ul{
      list-style: none;
      display:flex;
      justify-content: space-between;
      align-items: flex-start;
      li{
        padding: 10px 0;
        a{
          color: var(--neutral-900, #151922);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          &:hover{
            text-decoration: underline;
            cursor: pointer;
          }
        }
        @media ${device.laptop} {
          padding: 10px 0;
        }

      }
    }

    @media (max-width: 768px) {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      margin:0;
      padding: 30px;
      .menu-topo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--neutral-50, #F3F3F4);
        padding-bottom: 10px;
        margin-bottom: 10px;
        h2{
          color: #151922;
        }
        img{
          width: 36px;
        }
      }
      ul{
        display:inline-flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: flex-start;
      }
    }
  }

  .showMenuMobile{
    display: flex;
  }

  img {
    width: 50px;
    transition: 0.4s;
  }

  ${Center} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #menu {
    svg {
      color: ${props => props.theme.colors.primary};
    }
    display: none;
    margin: 0 20px 0 10px;
    background: transparent;
    border: none;
  }

  @media (max-width: 600px) {
    #menu {
      margin: 0 10px 0 10px;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .point {
      background: #F35C19;
      border-radius: 50%;
      width: 10px;
      height: 10px;

      position: absolute;
      top: 30px;
      margin-left: 6px;

      @media (max-width: 900px) {
        top: 16px;
        margin-top: 4px;
        margin-left: 8px;
      }
    }

    a {
      img {
        width: 134px;

        /* @media (max-width: 900px) {
          width: 100%;
        }

        @media (max-width: 660px) {
          display: none;
        } */
      }
    }
  }

  @media (max-width: 900px) {
    padding-left: 16px;
    padding-right: 16px;

    #menu svg {
      color: ${props => props.theme.colors.primary};
    }

    #menu {
      /* display: flex; */
      margin: 0 20px 0 10px;
    }

    @media (max-width: 600px) {
      #menu {
        margin: 0 10px 0 10px;
      }
    }


    /* img {
      width: 120px;
    } */
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
  min-width:240px;

  a {
    align-self: center;
  }

  @media (max-width: 900px) {
    justify-content: flex-end;
    padding: 0 45px;
    min-width:0px;
  }

  @media (max-width: 1370px) {
    padding: 0 17px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const LoginButton = styled.div`
  display: flex;

  > a {
    margin-right: 4px;
    display: flex;
    background: #FDFDFD;
    color: #7a797a;
    align-items: center;
    padding: 1.2rem;
    border-radius: 50px;
    font-weight: 500;
    font-size: 1.6rem;
    transition: 0.2s all;
  }

  > a:hover {
    background: #ebebeb;
  }

  > a > svg {
    color: rgba(122, 121, 122, 1);
    margin-right: 0.5rem;
    font-size: 2.6rem;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex:1;
  flex-direction:row;
  font-size: 14px;
  gap:10px;

  .user-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
    > a {
      font-size: 14px;
      font-style: italic;
      color: ${props => props.theme.colors.bodyLight};
      display: block;
      width: 50px;
    }
  }

  @media (max-width: 900px) {
    .user-text {
      display: none;
    }
  }
  `;

export const Cart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > a {
    margin-right: 8px;
    display: flex;
    border-radius: 50px;
    transition: background 0.3s, color 0.3s;
    position: relative;

    :hover {
      background: #ebebeb;
    }

    > svg {
      color: ${props => props.theme.colors.bodyLight};
    }

    > span {
      background: #143C97;
      align-items: center;
      justify-content: center;
      display: flex;
      color: #fff;
      border-radius: 50px;
      width: 19px;
      height: 19px;
      right: -2px;
      top: -2px;
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      border: solid 1px #FFF;
    }
  }

  .amount {
    > b {
      font-weight: 500;
      font-size: 1.6rem;
      padding: 0.2rem 0;
      color: ${props => props.theme.colors.primary};
    }
  }



  @media (max-width: 900px) {
    > a {
      padding: 1rem;
      > span {
        top: 1px;
        right: 1px;
      }
    }

  }

  @media (max-width: 600px) {
    > a {
      padding: 0.75rem;
    }

  }
`;

export const ToggleSearch = styled.button`
  margin-right: 8px;
  display: flex;
  border: none;
  background: #FDFDFD;
  padding: 1rem;
  border-radius: 50px;
  transition: background 0.3s, color 0.2s;
  position: relative;

  :hover {
    background: #ebebeb;
  }

  > svg {
    color: ${props => props.theme.colors.bodyLight};
    font-size: 2.6rem;
  }

  > span {
    background: ${props => props.theme.colors.secondary};
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    border-radius: 50px;
    width: 21px;
    height: 21px;
    right: 5px;
    top: 5px;
    position: absolute;
    font-size: 1.3rem;
    font-weight: 500;
  }

  @media (max-width: 600px) {
    padding: 0.75rem;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const SearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-left: 70px;
  margin-right: 84px;
  flex-direction: column;

  .search-container {
    position: relative;
    width: 100%;
    border: 1px solid #c1c1c1;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 8px;
    border: 1px solid var(--neutral-200, #D0D1D3);
    padding-left: 24px;
    padding-right: 24px;

    input {
      width: 100%;
      border: none;
      outline: none;
      color: #666666;
      border-radius: 4px 0 0 4px;
      margin-left:15px;
      font-size: 16px;
      padding-right: 10px;
      background-color: #FFFFFF;

      [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    img {
      outline: none;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
      width: 24px;

      svg {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Suggest = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -1px;
  top: 40px;
  padding-top: 10px;
  background: #fafafa;
  width: calc(100% + 2px);
  list-style: none;
  border: 1px solid #c1c1c1;
  border-top: 0;
  z-index: 2000 !important;

  li {
    font-size: 1.6rem;
    padding: 0.8rem 0.7rem 0.8rem 1.6rem;
    color: #666666;
    cursor: pointer;
    background: #fafafa;
    justify-content: space-between;
    display: flex;
    align-items: center;

    span {
      text-transform: capitalize;
    }

    svg {
      color: #dddddd;
      font-size: 2.2rem;
    }

    :hover {
      background: ${darken(0.05, "#fff")};
    }
  }
`;

export const Helper = styled.div`
  display: none;
  height: 85px;

  @media (max-width: 900px) {
    top: 41px;
    height: ${props => (props.visible ? "125px" : "65px")};
  }
`;

export const ContainerSearchMobile = styled.div`
  background-color: #FFF;
  margin-top: 15px;
  display: none;
  @media (max-width: 900px) {
      display: flex;
      transition: height 0.2s ease-out;
  }
`;

export const MobileSearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: #fff;
  width: 100%;

  .search-container {
    position: relative;
    width: 100%;
    border: 1px solid #c1c1c1;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 24px;
    padding-left: 24px;
    padding-right: 24px;

    input {
      width: 100%;
      border: none;
      outline: none;
      color: #666666;
      border-radius: 4px 0 0 4px;
      margin-left:15px;
      font-size: 16px;
      padding-right: 10px;
      background-color: #FFFFFF;

      [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    img {
      outline: none;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
      width: 24px;

      svg {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .hide {
    max-height: 0;
    pointer-events: none;
  }


`;
