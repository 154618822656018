import { Link } from "react-router-dom";
import styled from "styled-components";
import ButtonPrimary from "../../components/ButtonPrimary";
import { device } from "../../styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledLinkLogo = styled(Link)`
  margin-top: 40px;
  align-self: center;
`;

export const Form = styled.form`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.neutral100};
  border-radius: 16px;
  padding: 35px;
  margin: 130px 10px 0px 10px;
  max-width: 556px;

  @media (${device.laptop}){
    padding: 64px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 2.5rem;
    font-weight: font-weight: 700;
    color: ${(props) => props.theme.colors.neutral900};
    text-align: center;
  }

  span {
    text-align: center;
    padding: 10px 0;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.neutral900};
  }
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: 24px;
`;

export const IrParaLogin = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
`;
