import React from "react";
import Select from "react-select";
import moveDropdown from "../../assets/images/move-dropdown.svg"

export const DropdownSelectOrdenation = ({ options, onChange }) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 16,
            fontSize: '16px',
            color: '#000000'
        }),
        control: provided => ({
            ...provided,
            margin: 4,
            border: 0,
            boxShadow: 'none',
            background: 'transparent',
            color: '#143C97',
            fontSize: '14px',
            fontWeight: '700'
        }),
        menuList: provided => ({
            ...provided
        })
    };

    const formatOptionLabel = (props) => {
        return props.data.value !== '' ? `Ordenar por: ${props.data.label}` : props.data.label;
    }
    const dropdownIndicator = () => {
        return (
            <img src={moveDropdown} alt={"Move Dropdown"} />
        );
    }

    const components = {
        SingleValue: formatOptionLabel,
        DropdownIndicator: dropdownIndicator,
        IndicatorSeparator: null,
    }

    return (
        <Select
            styles={customStyles}
            isSearchable={false}
            options={options}
            components={components}
            defaultValue={options[0]}
            onChange={e => {
                onChange(e.value);
            }}
        />
    );
}