import React from 'react';
import { ButtonSecundary as ButtonSecundaryStyle } from './styles';

const ButtonSecundary = ({ text, className, onClick, disabled }) => {
    return (
        <ButtonSecundaryStyle type="submit" className={className} disabled={disabled} onClick={onClick}>
            {text}
        </ButtonSecundaryStyle>
    );
};

export default ButtonSecundary;