import React, { useEffect, useState } from 'react';
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { formatPrice } from "../../util/format";
import { Container, Discount, Item, ItemsList, Modal, ModalHeader } from './styles';

function CartItensModal(props) {
  const [open, setOpen] = useState(props.isOpen);

  const {
    showProductConditions,
    data: basket,
    amount,
  } = useSelector((state) => state.cart);

  const [frontBasket, setFrontBasket] = useState([]);

  useEffect(() => {
    setFrontBasket(basket);
  }, [basket]);

  useEffect(() => {
    const subtotal = basket?.reduce(
      (acc, product) =>
        acc +
        parseFloat(
          product?.preco_mostrar_cesta?.preco_de || product?.preco_mostrar_cesta
        ) * product.quantidade, 0);

  }, [amount, basket]);

  useEffect(() => {
     setOpen(props.isOpen);
  }, [props.isOpen]);

  function handleClose() {
    props.handleClose();
  }

  return (
    <>
    {open && (
        <Container>
          <Modal>
            <ModalHeader>
              <div>
                <h1>Itens do pedido</h1>
              </div>
              <button onClick={handleClose}><FiX /></button>
            </ModalHeader>

            <div className="wrap" >
              <ItemsList>
                <h2>Minha cesta</h2>
                  {frontBasket.map((item) => (
                  <Item key={item.id}>
                    <div className="content-area">
                      <img
                        src={item.imagens[0].caminho_imagem}
                        alt={item.nome}
                      />
                      <div className="text-area">
                        <strong>{item.nome}</strong>
                        <span>Qtde: {item.quantidade}</span>
                        {(item.preco_mostrar_cesta.preco_de !== item.preco_mostrar_cesta.preco_por) && (
                          <>
                            <span className="oldValue">De: {formatPrice(item.preco_mostrar_cesta?.preco_de)}</span>
                            <span className="value">Por: {formatPrice(item.preco_mostrar_cesta?.preco_por)}</span>
                          </>
                        )}
                        {(item.preco_mostrar_cesta.preco_de === item.preco_mostrar_cesta.preco_por) && (
                          <>
                            <span className="value">{formatPrice(item.preco_mostrar_cesta?.preco_por)}</span>
                          </>
                        )}

                      </div>
                      {item?.preco_mostrar_cesta?.percentual_desconto > 0 && (
                        <Discount>
                    <span>
                      {parseFloat(
                        item?.preco_mostrar_cesta?.percentual_desconto * -1
                      ).toLocaleString("pt-BR")}
                      %
                    </span>
                    </Discount>
                )}
                    </div>
                  </Item>
                ))}

                {frontBasket.length === 0 && (
                  <span className="none-accepted">A cesta está vazia</span>
                )}
              </ItemsList>
            </div>
          </Modal>
        </Container>
         )}
    </>
  );
}

export default CartItensModal;
