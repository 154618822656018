import React from "react";
import { FormGroup, RadioGroup, FieldErrorMessage, Label } from "./styles";

const InputRadioCustom = ({ description, name, value, options, handleChange, handleBlur, isInvalid, errors }) => {
    return (
        <FormGroup>
            <Label>{description}</Label>
            <RadioGroup>
                {options?.map((item, index) => (
                    <div className="radio" key={index}>
                        <input
                            id={`radio-${index}`}
                            value={item.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={name}
                            type="radio"
                            checked={value === item.value ? true : false}
                        />
                        <label htmlFor={`radio-${index}`} className="radio-label">
                            {item.label}
                        </label>
                    </div>
                ))}
            </RadioGroup>
            {isInvalid && (
                <FieldErrorMessage>{errors}</FieldErrorMessage>
            )}
        </FormGroup>
    );
};

export default InputRadioCustom;