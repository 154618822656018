import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductsOutOfStockModal from "../ ProductsOutOfStockModal";
import StoreIcon from "../../assets/images/store.svg";
import SelectStoreModal from "../SelectStoreModal";
import { Container, Content } from "./styles";

export default function SelectStoreHeader() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data: store, showOutOfStockModal } = useSelector(
    state => state.storeByUser
  );

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalIsOpen]);

  function closeModal() {
    setModalIsOpen(!modalIsOpen);
  }

  return (
    <Container id="searchStore">
      {showOutOfStockModal && <ProductsOutOfStockModal />}
      <Content>
        <img src={StoreIcon} alt="loja" title="loja" onClick={() => setModalIsOpen(!modalIsOpen)} />
        {store !== null && (
          <div className="selected">
            <span onClick={() => setModalIsOpen(!modalIsOpen)}>
              {store.nome_fantasia} {"(alterar)"}
            </span>
            {/* <button onClick={() => setModalIsOpen(!modalIsOpen)}>
              Alterar
              <MdKeyboardArrowDown />
            </button> */}
          </div>
        )}

        {store === null && (
          <div className="no-selected">
            <button onClick={() => setModalIsOpen(!modalIsOpen)}>
              Escolha a farmácia de sua preferência
            </button>
          </div>
        )}
      </Content>
      {modalIsOpen && <SelectStoreModal closeModal={closeModal} />}
    </Container>
  );
}
