import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as SuggestActions } from "../ducks/suggest";

export function* getSuggestions() {
  try {
    const { searchTerm } = yield select(state => state.search);
    const { offset, limit } = yield select(state => state.suggest);

    const { data } = yield call(
      api.post,
      `/products/suggestions?_offset=${offset}&_limit=${limit}`,
      {
        termo: searchTerm
      }
    );

    yield put(SuggestActions.getSuggestionsSuccess(data));
  } catch (err) {
    yield put();
  }
}

export function* clearSuggestions() {
  yield put(SuggestActions.clearSuggestions());
}
