export const Types = {
  GET_REQUEST: "banners/GET_REQUEST",
  GET_SUCCESS: "banners/GET_SUCCESS",
  GET_FAILURE: "banners/GET_FAILURE",
  GET_BANNER_PRODUCTS_REQUEST: "banners/GET_BANNER_PRODUTOS_REQUEST",
  GET_BANNER_PRODUCTS_SUCCESS: "banners/GET_BANNER_PRODUCTS_SUCCESS"
};

const INITIAL_STATE = {
  data: [],
  bannerProducts: [],
  loading: true,
  error: null,
  loaderSize: 25,
  windowSize: 480
};

export default function banners(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return { data: action.payload.data, loading: false, error: null };
    case Types.GET_FAILURE:
      return { data: [], loading: false, error: action.payload.message };
    case Types.GET_BANNER_PRODUCTS_REQUEST:
      return { loading: true };
    case Types.GET_BANNER_PRODUCTS_SUCCESS:
      return { bannerProducts: action.payload.data, loading: false };
    default:
      return state;
  }
}

export const Creators = {
  getBannersRequest: () => ({
    type: Types.GET_REQUEST
  }),

  getBannersSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  getBannersFailure: message => ({
    type: Types.GET_FAILURE,
    payload: { message }
  }),

  getBannersProductsRequest: uuid => ({
    type: Types.GET_BANNER_PRODUCTS_REQUEST,
    payload: { uuid }
  }),

  getBannersProductsSuccess: data => ({
    type: Types.GET_BANNER_PRODUCTS_SUCCESS,
    payload: { data }
  })
};
