import styled from "styled-components";


export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.4;
  z-index: 10000;
`;

export const Container = styled.footer`
  padding: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutral-100, #E8E8E9);
  background: #FFF;
  max-width: 1309px;
  margin: 0 auto;

  position: fixed;
  z-index: 10001;
  bottom: 66px;
  right: 66px;
  left: 66px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: space-between;
  gap: 24px;
  button{
    min-width: 229px;
  }


  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    height: 463px;
  }

  @media (max-width: 360px) {
    max-width: 100vw;
    height: 496px;
  }

  @media (max-width: 500px) {
    max-width: 100vw;
    height: 463px;
    justify-content: flex-start;
    flex-direction: flex-start;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media (max-height: 720px) {
    height: 463px;
  }

  @media (max-height: 520px) {
    height: 55%;
  }

  @media (max-height: 350px) {
    height: 80%;
  }
`;

export const Content = styled.div`
  h2 {
    color: #00b6ad
  }

  p {
    max-width: 120ch;
    color: #7a797a;
  }
`;
