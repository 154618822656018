export const defaultBanners = {
  1025: [
    {
      img_url:
        "https://via.placeholder.com/1163x344/309F85/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    },
    {
      img_url:
        "https://via.placeholder.com/1163x344/C93131/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    }
  ],
  1024: [
    {
      img_url:
        "https://via.placeholder.com/1024x384/309F85/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    },
    {
      img_url:
        "https://via.placeholder.com/1024x384/C93131/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    }
  ],
  767: [
    {
      img_url:
        "https://via.placeholder.com/736x380/309F85/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    },
    {
      img_url:
        "https://via.placeholder.com/736x380/C93131/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    }
  ],

  480: [
    {
      img_url:
        "https://via.placeholder.com/414x380/309F85/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    },
    {
      img_url:
        "https://via.placeholder.com/414x380/C93131/FFFFFF/?text=MAXXI%20ECON%C3%94MICA"
    }
  ]
};
