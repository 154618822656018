import { call, put, select } from "redux-saga/effects";
import { Creators as PasswordActions } from "../ducks/password";
import { Creators as ErrorActions } from "../ducks/error";
import history from "../../services/history";
import { toast } from "react-toastify";
import api from "../../services/api";

export function* recoverPassword({ payload }) {
  try {
    const { email } = payload;

    const { data } = yield call(api.post, "customers/forgot-password", {
      email
    });

    toast.success(data.message);
    yield put(PasswordActions.recoverPasswordSuccess());
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(
      PasswordActions.recoverPasswordFailure(err.response.data.message)
    );
  }
}

export function* newPassword({ payload }) {
  try {
    const { password, token } = payload;
    const { data } = yield call(api.put, "customers/password", {
      password,
      token
    });

    toast.success(data.message);
    yield put(PasswordActions.newPasswordSuccess());
    history.push("/entrar");
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(PasswordActions.newPasswordFailure(err.response.data.message));
  }
}

export function* verifyToken({ payload }) {
  try {
    const { token } = payload;

    yield call(api.post, "customers/forgot-password-token", {
      token
    });

    yield put(PasswordActions.verifyTokenSuccess());
  } catch (err) {
    history.push("/");
    toast.error(err.response.data.message);
    yield put(PasswordActions.verifyTokenFailure());
  }
}

export function* updatePassword({ payload }) {
  try {
    const { uuid } = yield select(state => state.user.profile);
    const { senhaAtual, senhaNova, confirmacaoSenhaNova } = payload;

    const { data } = yield call(api.put, `customers/${uuid}/password`, {
      senhaAtual,
      senhaNova,
      confirmacaoSenhaNova
    });

    yield put(PasswordActions.updatePasswordSuccess(data.message));
    history.push("/painel");
  } catch (err) {
    yield put(PasswordActions.updatePasswordFailure());
    yield put(ErrorActions.setError("update-password", err.response.data.user));
  }
}
