import React, { createContext, useState } from "react";

export const OrdenationContext = createContext({ordenation: '', setOrdenations: () => null})

export function OrdenationProvider({ children }) {
  const [ordenation, setOrdenation] = useState('');

  function setOrdenations(ordenacao) {
    setOrdenation(ordenacao)
  }

  return (
    <OrdenationContext.Provider value={{ ordenation, setOrdenations}}>
      {children}
    </OrdenationContext.Provider>
  )
}
