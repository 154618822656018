import React, { useEffect } from "react";

import ItemList from "../../components/ItemList";

import { Container } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { Creators as ProductsActions } from "../../store/ducks/products";
import { NoResultsFound } from "../SearchResultProducts/styles";
import MainLayout from "../../components/MainLayout";

export default function SearchResultProductsNews({ history }) {
  const dispatch = useDispatch();
  const { dataNewProducts: products, loading, hasMore } = useSelector(
    state => state.products
  );

  useEffect(() => {
    dispatch(ProductsActions.getNewProductsRequest());
  }, [dispatch]);

  function loadMore() {
    dispatch(ProductsActions.getNewProductsRequest());
  }

  if (products && products.length === 0 && !loading) {
    return (
      <NoResultsFound>
        <span>
          <b>Ah não :(</b> Não encontramos o produto que você pesquisou
        </span>
      </NoResultsFound>
    );
  }
  return (
    <MainLayout history={history}>
      <Container>
        <ItemList
          products={products}
          hasMore={hasMore}
          loading={loading}
          loadMore={loadMore}
        />
      </Container>
    </MainLayout>
  );
}
