import React, { useState } from "react";
import { Container, ContainerCircle, CountIncrement } from "./styles";


const IncrementItem = ({quantity, setQuantity}) => {

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    }

    return (
        <Container>
            <ContainerCircle onClick={handleDecrement}>
                <span>-</span>
            </ContainerCircle>
            <CountIncrement>{quantity}</CountIncrement>
            <ContainerCircle onClick={handleIncrement}>
                <span>+</span>
            </ContainerCircle>
        </Container>
    );
};

export default IncrementItem;