export const Types = {
  ADD_PRODUCT: "cart/ADD_PRODUCT_CART",
  ADD_PRODUCT_LIST: "cart/ADD_PRODUCT_LIST_CART",
  ADD_PRODUCT_SUCCESS: "cart/ADD_PRODUCT_SUCCESS",
  REMOVE_PRODUCT: "cart/REMOVE_PRODUCT_CART",
  REMOVE_PRODUCT_SUCCESS: "cart/REMOVE_PRODUCT_SUCCESS",
  GET_STORAGE_CART: "cart/GET_STORAGE_CART",
  GET_STORAGE_SUCCESS: "cart/GET_STORAGE_SUCCESS",
  SET_ORDER_STORAGE: "cart/SAVE_ORDER_STORAGE",
  SET_ORDER_STORAGE_SUCCESS: "cart/SAVE_ORDER_STORAGE_SUCCESS",
  GET_ORDER_CART_STORAGE: "cart/GET_ORDER_CART_STORAGE",
  GET_ORDER_CART_STORAGE_SUCCESS: "cart/GET_ORDER_CART_STORAGE_SUCCESS",
  SEND_ORDER_API_SUCCESS: "cart/SEND_ORDER_API_SUCCESS",
  SET_ERROR_ORDER: "cart/SET_ERROR_ORDER",
  SHAKE_ANIMATION_START: "cart/SHAKE_ANIMATION_START",
  SHAKE_ANIMATION_END: "cart/SHAKE_FINISH",
  TOGGLE_PRODUCT_CONDITIONS: "cart/TOGGLE_PRODUCT_CONDITIONS",
  SET_AMOUNT: "cart/SET_AMOUNT",
  RESET_DEFAULT: "cart/RESET_DEFAULT",
  CALCULATE_AMOUNT: "cart/CALCULATE_AMOUNT",
  REMOVE_PRODUCTS_CART: "cart/REMOVE_PRODUCTS_CART",
  REMOVE_PRODUCTS_CART_SUCCESS: "cart/REMOVE_PRODUCTS_CART_SUCCESS",
  GET_SELECTED_REQUEST: "cart/GET_SELECTED_REQUEST"
};

const INITIAL_STATE = {
  data: JSON.parse(localStorage.getItem("@ConexaoGAM:cart")) || [],
  loading: false,
  productAddedId: null,
  order: { cart: [], data: null },
  orderId: null,
  error: null,
  shake: false,
  showProductConditions: false,
  product: {},
  deliveryTax: 6.99,
  amount: 0
};

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_PRODUCT:
      return {
        ...state,
        loading: true,
        productAddedId: action.payload.currentProductAdded.id
      };
    case Types.ADD_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
        currentProductListAdded: action.payload.currentProductListAdded
      };

    case Types.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        productAddedId: null
      };

    case Types.SHAKE_ANIMATION_START:
      return { ...state, shake: true };

    case Types.SHAKE_ANIMATION_END:
      return { ...state, shake: false };

    case Types.REMOVE_PRODUCT:
      return {
        ...state,
        loading: true
      };

    case Types.REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };

    case Types.GET_STORAGE_CART:
      return { ...state, loading: true };

    case Types.GET_STORAGE_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };

    case Types.GET_SELECTED_REQUEST:
      return {
        ...state,
        requestedProductList: action.payload.requestedProductList
      };
    case Types.SET_ORDER_STORAGE:
      return { ...state };
    case Types.SET_ORDER_STORAGE_SUCCESS:
      return { ...state, order: action.payload.order };
    case Types.GET_ORDER_CART_STORAGE:
      return { ...state };
    case Types.GET_ORDER_CART_STORAGE_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        data: action.payload.cart
      };
    case Types.SEND_ORDER_API_SUCCESS:
      localStorage.removeItem("@ConexaoGAM:cart");
      localStorage.removeItem("@ConexaoGAM:checkout");
      return {
        ...state,
        loading: false,
        data: [],
        amount: 0,
        error: null,
        order: [],
        orderId: action.payload.data.orderId
      };
    case Types.SET_ERROR_ORDER:
      return { ...state, error: action.payload.message, loading: false };
    case Types.TOGGLE_PRODUCT_CONDITIONS:
      return {
        ...state,
        showProductConditions: !state.showProductConditions,
        product: action.payload.product
      };
    case Types.SET_AMOUNT:
      return { ...state, amount: action.payload.amount, loading: false };
    case Types.REMOVE_PRODUCTS_CART:
      return { ...state, loading: true };
    case Types.REMOVE_PRODUCTS_CART_SUCCESS:
      return { ...state, loading: false };
    case Types.RESET_DEFAULT:
      return {
        data: [],
        loading: false,
        order: { cart: [], data: null },
        orderId: null,
        error: null,
        shake: false,
        showProductConditions: false,
        product: {},
        deliveryTax: 6.99,
        amount: 0
      };
    case Types.CALCULATE_AMOUNT:
      return { ...state, loading: true };
    case "@auth/SIGN_OUT":
      return { ...state, data: [], loading: false, amount: 0 };
    default:
      return state;
  }
}

export const Creators = {
  addProduct: (currentProductAdded, qty = 1, sum = true, hasDelay = true, orderAgain = false, isUpdate = false) => ({
    type: Types.ADD_PRODUCT,
    payload: { currentProductAdded, qty, sum, hasDelay, orderAgain, isUpdate }
  }),

  addProductList: currentProductListAdded => ({
    type: Types.ADD_PRODUCT_LIST,
    payload: { currentProductListAdded }
  }),

  setAmount: amount => ({
    type: Types.SET_AMOUNT,
    payload: { amount }
  }),

  shakeAnimationStart: () => ({
    type: Types.SHAKE_ANIMATION_START
  }),

  shakeAnimationEnd: () => ({
    type: Types.SHAKE_ANIMATION_END
  }),

  addProductSuccess: data => ({
    type: Types.ADD_PRODUCT_SUCCESS,
    payload: { data }
  }),

  removeProduct: (currentProductRemoved, key) => ({
    type: Types.REMOVE_PRODUCT,
    payload: { currentProductRemoved, key }
  }),

  removeProductSuccess: data => ({
    type: Types.REMOVE_PRODUCT_SUCCESS,
    payload: { data }
  }),

  removeProductsCart: (acceptedItems, itemsNotAccepted) => ({
    type: Types.REMOVE_PRODUCTS_CART,
    payload: { acceptedItems, itemsNotAccepted }
  }),

  removeProductsCartSuccess: () => ({
    type: Types.REMOVE_PRODUCT_SUCCESS
  }),

  getStorage: () => ({
    type: Types.GET_STORAGE_CART
  }),

  getStorageSuccess: data => ({
    type: Types.GET_STORAGE_SUCCESS,
    payload: { data }
  }),

  setOrderStorage: order => ({
    type: Types.SET_ORDER_STORAGE,
    payload: { order }
  }),

  setOrderStorageSuccess: order => ({
    type: Types.SET_ORDER_STORAGE_SUCCESS,
    payload: { order }
  }),

  getOrderAndCartFromStorage: () => ({
    type: Types.GET_ORDER_CART_STORAGE
  }),

  getOrderAndCartFromStorageSuccess: ({ order, cart }) => ({
    type: Types.GET_ORDER_CART_STORAGE_SUCCESS,
    payload: { order, cart }
  }),

  sendOrderApiSuccess: data => ({
    type: Types.SEND_ORDER_API_SUCCESS,
    payload: { data }
  }),

  setErrorOrder: message => ({
    type: Types.SET_ERROR_ORDER,
    payload: { message }
  }),

  toggleProductConditions: product => ({
    type: Types.TOGGLE_PRODUCT_CONDITIONS,
    payload: { product }
  }),

  resetDefault: () => ({
    type: Types.RESET_DEFAULT
  }),

  calculateAmount: () => ({
    type: Types.CALCULATE_AMOUNT
  }),

  getProductListRequest: (requestedProductList, storeId) => ({
    type: Types.GET_SELECTED_REQUEST,
    payload: { requestedProductList, storeId }
  })
};
