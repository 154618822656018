import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}) {

  const isAuthenticated = useSelector(state => state.auth.signed);

  if (!isAuthenticated && isPrivate) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={Component} />;
}
