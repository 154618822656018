import styled from "styled-components";
import { darken, lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  overflow-y: auto;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100%;
  max-width: calc(800px - 40px);
  background: #fff;
  border-radius: 8px;
  position: absolute;
  margin-top: 4%;
  margin-bottom: 4%;

  div.wrap {
    max-width: calc(100% - 64px);
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 16px;
    max-height: 70vh;
    overflow: auto;
  }

  @media (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  max-width: calc(100% - 64px);
  margin: 32px auto 16px auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;

  button {
    border: none;
    background: none;
    position: absolute;
    right: -20px;
    top: -20px;

    @media (max-width: 600px) {
      right: -10px;
      top: -24px;
    }

    svg {
      stroke: ${props => props.theme.colors.primary};
      font-size: 30px;
    }
  }

  h1 {
    font-size: 2rem;
    color: ${props => props.theme.colors.headingLight};
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    font-size: 1.6rem;
    color: ${props => props.theme.colors.bodyLight};
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 32px);
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  max-width: calc(100% - 64px);
  margin: 32px auto;
  display: flex;
  justify-content: flex-end;

  button.btn-confirm {
    background: ${props => props.theme.colors.primary};
    box-shadow: 0px 2px 0px ${props => darken(0.06, props.theme.colors.primary)};
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    color: #fff;
    border: none;
    height: 40px;

    :disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  button.btn-clear {
    background: ${props => props.theme.colors.warning};
    box-shadow: 0px 2px 0px ${props => darken(0.05, props.theme.colors.warning)};
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    color: #fff;
    border: none;
    height: 40px;

    :disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    max-width: calc(100% - 32px);

    button.btn-confirm {
      width: 100%;
      max-width: 100%;
      font-size: 1.4rem;
    }
  }
`;

export const ItemsList = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: 100%;  
  padding-bottom: 30px;

  h2 {
    font-size: 1.6rem;
    color: ${props => darken(0.05, props.theme.colors.success)};
    margin-bottom: 16px;
    margin-top: 16px;
    position: relative;
    margin-left: 26px;

    &::after {
      content: "";
      width: 18px;
      height: 18px;
      background: red;
      left: -26px;
      position: absolute;
      background: ${props => darken(0.05, props.theme.colors.success)};
    }
  }

  span.none-accepted {
    color: ${props => props.theme.colors.bodyLight};
    font-size: 1.6rem;
    font-weight: 400;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 32px);
  }
`;

export const Discount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #FFF;
    background: ${props => props.theme.colors.success};
    text-align: center;
    padding: 5px 10px;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: 2px;
  }

  @media (max-width: 660px) {
    order: 4;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    span {
      width: 145px;
      padding: 5px 0;
    }
  }
`;

export const Item = styled.li`
  display: flex;
  border: 1px solid ${props => lighten(0.1, props.theme.colors.success)};
  border-radius: 4px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  flex-direction: column;

  + li {
    margin-top: 16px;
  }

  img {
    object-fit: contain;
    width: 53px;
    height: 53px;
    margin: 8px;
    align-self: center;
  }

  div {
    display: flex;
    flex-direction: column;
    margin: 8px;

    button.btn-undo {
      background: transparent;
      color: ${props => props.theme.colors.success};
      border-radius: 4px;
      font-size: 1.4rem;
      border: 1px solid ${props => props.theme.colors.success};
      padding: 4px 0;
      width: 100%;
    }

    strong {
      color: ${props => darken(0.05, props.theme.colors.bodyLight)};
      font-weight: 500;
    }    
  }

  div.content-area {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-self: start;
    width: 100%;
  }

  div.text-area {
    display: flex;
    flex-direction: column;
    margin: 8px;
    width: 100%;
    align-self: center;

    span.oldValue{
      font-size: 12px;
    }

    span {
      color: ${props => darken(0.05, props.theme.colors.bodyLight)};
    }

    span.value {
      color: ${props => props.theme.colors.primary};
    }
  }  

  div.btn-area {
    display: flex;
    flex-direction: row;
    margin: 8px;
    margin-top: 0;
    width: 90%;

    button.btn-transfer {
      background: transparent;
      color: ${props => props.theme.colors.primary};
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.primary};
      padding: 4px 0;
      margin-right: 8px;
      font-size: 1.4rem;
      width: inherit;
    }

    button.btn-remove {
      background: transparent;
      color: ${props => props.theme.colors.disabled};
      border-radius: 4px;
      font-size: 1.4rem;
      border: 1px solid ${props => props.theme.colors.disabled};
      padding: 4px 0;
      width: 150px;      
    }
  }
`;
