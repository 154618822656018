import React from "react";
import { Container, ContainerDescription } from "./styles";

import iconInfo from "../../assets/images/icon-info.svg";

const ProductAlert = ({ text }) => {
    return (
        <Container>
            <img src={iconInfo} alt="Icon Info" />
            <ContainerDescription>
                <p>{text}</p>
            </ContainerDescription>
        </Container>
    );
};

export default ProductAlert;