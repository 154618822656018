import styled from "styled-components";

export const ContainerSpinner = styled.div`
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContainerBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    ul{
      display:inline-flex;
      list-style: none;
      justify-content: center;
      align-content: center;
      align-items: center;
      gap: 8px;
      li{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        cursor: pointer;
        span{
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: var(--neutral-200, #D0D1D3);
          transition: all 0.2s ease-in-out;
          &.active{
            width: 32px;
            background: var(--primary-500, #143C97);
          }
        }
      }
    }
  }

`;
