import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import { Formik } from "formik";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import MaskedInput from "react-text-mask";
import { ThemeContext } from "styled-components";
import * as Yup from "yup";
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import CartItemsModal from "../../components/CartItemsModal";
import CollapseList from "../../components/CollapseList";
import ModalConfirm from "../../components/ModalConfirm";
import PartlyFulfilledOrderModal from "../../components/PartlyFulfilledOrderModal";
import ShowDeliverySelectedStoreModal from "../../components/ShowDeliverySelectedStoreModal";
import ShowDeliveryStoreModal from "../../components/ShowDeliveryStoreModal";
import ShowOpeningHours from "../../components/ShowOpeningHours";
import SubHeader from "../../components/SubHeader";
import api from "../../services/api";
import { setTitle } from "../../services/browser";
import { Creators as OrderActions } from "../../store/ducks/order";
import { Creators as UserActions } from "../../store/ducks/user";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from "../../util/cardUtils";
import { formatPrice } from "../../util/format";
import { ContentWrap, PriceInfoWrap, TitleWrap, Wrap } from "../Cart/styles";
import {
  ButtonFooterWrap,
  CheckoutPage,
  Container,
  CreditCard,
  ErrorContainer,
  FieldErrorMessage,
  Form,
  FormGroup,
  Image,
  InputField,
  Label,
  LoaderContainer,
  OrderSubmittingOverlay,
  PaymentContainer,
  PaymentDetails,
  PriceInfoContent,
  RadioConteinerGroup,
  Tabs
} from "./styles";
import { phoneMask } from "./validation";

export default function CheckoutPayment({ history }) {
  const dispatch = useDispatch();
  const { colors } = useContext(ThemeContext);
  const { showConfirmOrderModal, orderConfirmationMessage, customerAcceptOrder } = useSelector(state => state.order);
  const store = useSelector(state => state.storeByUser.data);
  const [buttonText, setButtonText] = useState('');
  const [installments, setInstallments] = useState({});
  const [optionsInstallments, setOptionsInstallments] = useState([])
  const [defaultValueInstallment, setDefaultValueInstallment] = useState(null)

  const [loadingButtonText, setLoadingButtonText] = useState('');
  const [focus, setFocus] = useState("");
  const [abrirPopup, setAddModalOpen] = useState(false);
  const [pagarComCartao, setCreditCardPayment] = useState(true);
  const [address, setAddress] = useState("");
  const [deliveryData, setDeliveryData] = useState(null);

  const cart = useSelector((state) =>
    state.cart.data.map((product) => {
      return {
        ...product,
        preco_mostrar_cesta: {
          ...product.preco_mostrar_cesta,
          preco_por: formatPrice(
            parseFloat(product.preco_mostrar_cesta.preco_por)
          ),
        },
        precos: product.precos.map((preco) => {
          return {
            ...preco,
            preco_por: formatPrice(parseFloat(preco.preco_por)),
            preco_de: formatPrice(parseFloat(preco.preco_de)),
          };
        }),
      };
    })
  );
  const subtotal = useSelector((state) =>
    state.cart.data.reduce(
      (acc, current) =>
        acc + parseFloat(current.precos[0].preco_de) * current.quantidade,
      0
    )
  );
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleClose = () => {
    setAddModalOpen(false);
  };

  const handleUseCreditcard = (e) => {
    setCreditCardPayment(true);
  };

  const handleDontUseCreditcard = (e) => {
    setCreditCardPayment(false);
  };

  const amount = useSelector((state) => state.cart.amount);
  const { message: errorMessage, loading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    setTitle("Checkout / Pagamento");
    dispatch(UserActions.updateRewardsProgram());

    return () => {
      dispatch(OrderActions.resetError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreditCardSchema = Yup.object().shape({
    cvc: Yup.string().required("CVV não pode ser vazio"),
    expiry: Yup.string().required("Data de validade não pode ser vazio"),
    name: Yup.string()
      .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/, "Nome deve conter apenas letras")
      .required("Nome não pode ser vazio"),
    number: Yup.string().required("Número do cartão não pode ser vazio")
  });

  const PaymentSchema = Yup.object().shape({
    paymentMode: Yup.string().required("Por favor, escolha uma forma de pagamento para poder finalizar o pedido"),
    paymentValue: Yup.string().when('paymentMode', {
      is: (paymentMode) => paymentMode === "DI",
      then: Yup.string().matches(/R[$]\W[1-9]/, "Por favor, informe um valor válido a ser pago em dinheiro")
        .required("Por favor, informe o valor a ser pago em dinheiro"),
      otherwise: Yup.string()
    }),
    clientNameCE: Yup.string().when('paymentMode', {
      is: (paymentMode) => paymentMode === "CE",
      then: Yup.string().required("Por favor, informe o nome e o sobrenome do cliente")
        .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/, "Por favor, informe um nome de cliente válido")
        .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]{3}/, "Por favor, informe um nome de cliente válido")
        .min(3, "O nome do cliente deve conter pelo menos 3 caracteres"),
      otherwise: Yup.string()
    }),
    phoneCE: Yup.string().when('paymentMode', {
      is: (paymentMode) => paymentMode === "CE",
      then: Yup.string().required("Por favor, informe o número do telefone")
        .matches(/^\([1-9]{2}\)\W(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/, "Por favor, informe um número de telefone válido"),
      otherwise: Yup.string()
    }),
    companyName: Yup.string().when('paymentMode', {
      is: (paymentMode) => paymentMode === "CV",
      then: Yup.string().required("Por favor, informe o nome da empresa do convênio")
        .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/, "Por favor, informe um nome de cliente válido")
        .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]{3}/, "Por favor, informe um nome de cliente válido")
        .min(3, "O nome da empresa do convênio deve conter pelo menos 3 caracteres"),
      otherwise: Yup.string()
    }),
    clientNameCV: Yup.string().when('paymentMode', {
      is: (paymentMode) => paymentMode === "CV",
      then: Yup.string().required("Por favor, informe o nome e o sobrenome do cliente")
        .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/, "Por favor, informe um nome de cliente válido")
        .matches(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]{3}/, "Por favor, informe um nome de cliente válido")
        .min(3, "O nome do cliente deve conter pelo menos 3 caracteres"),
      otherwise: Yup.string()
    }),
    phoneCV: Yup.string().when('paymentMode', {
      is: (paymentMode) => paymentMode === "CV",
      then: Yup.string().required("Por favor, informe o número do telefone")
        .matches(/^\([1-9]{2}\)\W(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/, "Por favor, informe um número de telefone válido"),
      otherwise: Yup.string()
    })
  })

  useEffect(() => {
    const { dadosEntrega } = localStorage.getItem("@ConexaoGAM:checkout") ? JSON.parse(localStorage.getItem("@ConexaoGAM:checkout")) : { dadosEntrega: { tipoEntrega: "RECEBER_EM_CASA" } };

    if (deliveryData?.lojaSelecionada !== null || dadosEntrega.tipoEntrega === 'RETIRAR_NA_LOJA') {
      setButtonText('FINALIZAR PEDIDO');
      setLoadingButtonText('FINALIZANDO PEDIDO...');
    } else {
      if (customerAcceptOrder) {
        setButtonText('FINALIZAR PEDIDO');
        setLoadingButtonText('FINALIZANDO PEDIDO...');
      } else {
        setButtonText('PROSSEGUIR');
        setLoadingButtonText('ESCOLHENDO LOJA PARA ENTREGA...');
      }
    }

  }, [customerAcceptOrder, loading, store]);

  useEffect(() => {
    const { address, dadosEntrega } = JSON.parse(localStorage.getItem("@ConexaoGAM:checkout")) || {};
    setAddress(address);
    setDeliveryData(dadosEntrega);
  }, []);

  function handleInputFocus(e) {
    setFocus(e.target.name);
  }

  useEffect(() => {
    api.get('/companies/parcelamento').then(response => {
      setInstallments(response.data)
    })
  }, [])

  useEffect(() => {
    if (amount) {
      let priceDelivery = 0;
      if (deliveryData && deliveryData.tipoEntrega === "RECEBER_EM_CASA" && deliveryData.lojaSelecionada && deliveryData.lojaSelecionada.custo_entrega)
        priceDelivery = parseFloat(deliveryData.lojaSelecionada.custo_entrega)

      for (var i = 1; i <= installments.limite_parcelamento; i++) {
        if (i == 1) {
          setDefaultValueInstallment({
            value: `${i}`, label: `${i}x de ${formatPrice(((amount + priceDelivery) / i).toFixed(2))}`
          })
        }

        if ((amount / i) >= installments.valor_minimo_parcelamento) {
          optionsInstallments.push({
            value: `${i}`, label: `${i}x de ${formatPrice(((amount + priceDelivery) / i).toFixed(2))}`
          })
        }
      }
    }

  }, [amount, deliveryData, installments.limite_parcelamento, installments.valor_minimo_parcelamento, optionsInstallments, store])

  useEffect(() => {
    if (cart.length === 0) {
      history.push("/");
    }
  }, [cart.length])

  return (
    <BuyStepsTemplate step={3} history={history}>
      <CheckoutPage>
        <SubHeader
          title="Forma de pagamento"
        />
        <PartlyFulfilledOrderModal />
        <CartItemsModal isOpen={abrirPopup} handleClose={handleClose} />
        <ShowDeliveryStoreModal />
        <ShowOpeningHours />
        <ShowDeliverySelectedStoreModal />

        {errorMessage !== null && (
          <ErrorContainer>
            {ReactHtmlParser(errorMessage) ||
              "Ops! Ocorreu um erro ao finalizar seu pedido. Tente novamente!"}
          </ErrorContainer>
        )}
        <Container>
          <ContentWrap>
            <Wrap>
              <PaymentContainer>
                <Tabs>
                  {deliveryData?.lojaSelecionada?.formaPagamento.online.length > 0 && (
                    <>
                      <Image
                        id="pagamento-online"
                        onClick={handleUseCreditcard}
                      >
                        <span className={pagarComCartao ? "selected" : ""}>Pagar Online</span>
                      </Image>
                    </>
                  )}
                  {deliveryData?.lojaSelecionada?.formaPagamento.presencial.length > 0 && (
                    <>
                      <Image
                        id="pagamento-local"
                        onClick={handleDontUseCreditcard}
                      >
                        <span className={pagarComCartao ? "" : "selected"}>Pagar na {deliveryData?.tipoEntrega === "RECEBER_EM_CASA" ? "Entrega" : "Farmácia"}</span>
                      </Image>
                    </>
                  )}
                </Tabs>
                <PaymentDetails>
                  {(pagarComCartao || deliveryData?.lojaSelecionada?.formaPagamento.presencial.length == 0) && deliveryData?.lojaSelecionada?.formaPagamento.online.length > 0 && (
                    <>
                      <Formik
                        initialValues={{
                          cvc: "",
                          expiry: "",
                          name: "",
                          number: "",
                          installments: "1",
                          paymentMode: "CR",
                          paymentPlace: "O"
                        }}
                        validate={(values) => { }}
                        validationSchema={CreditCardSchema}
                        onSubmit={(values) => {
                          dispatch(OrderActions.orderRequest(values));
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          errors,
                          touched,
                          handleSubmit,
                          setFieldValue,
                          handleReset,
                          isSubmitting,
                          isValid,
                        }) => (deliveryData && (
                          <>
                            <Form id="payment-form" onSubmit={handleSubmit}>
                              <div className={`payment-form-type pag-card`}>
                                <h1>Informe os dados do seu cartão</h1>
                                <CreditCard>
                                  <Cards
                                    locale={{ valid: "validade" }}
                                    placeholders={{ name: "SEU NOME AQUI" }}
                                    cvc={values.cvc}
                                    expiry={values.expiry}
                                    name={values.name}
                                    focused={focus}
                                    onFocus={handleInputFocus}
                                    number={values.number}
                                  />
                                </CreditCard>
                                <FormGroup>
                                  {errors.number && touched.number ? (
                                    <FieldErrorMessage>{errors.number}</FieldErrorMessage>
                                  ) : null}

                                  <InputField
                                    invalid={pagarComCartao && errors.number && touched.number}
                                    id="number"
                                    type="text"
                                    name="number"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "number",
                                        formatCreditCardNumber(e.target.value)
                                      )
                                    }
                                    onBlur={handleBlur}
                                    onFocus={handleInputFocus}
                                    value={values.number}
                                    placeholder="Número do cartão"
                                    width={"300px"}
                                  />
                                  <Label>Número do cartão</Label>
                                </FormGroup>
                                <div className="cardGroup">
                                  <FormGroup>
                                    {errors.name && touched.name ? (
                                      <FieldErrorMessage>{errors.name}</FieldErrorMessage>
                                    ) : null}

                                    <InputField
                                      invalid={pagarComCartao && errors.name && touched.name}
                                      id="name"
                                      type="text"
                                      name="name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onFocus={handleInputFocus}
                                      value={values.name}
                                      placeholder="Nome do titular"
                                    />
                                    <Label>Nome impresso no cartão</Label>
                                  </FormGroup>
                                  <FormGroup className="menorCampo">
                                    {errors.expiry && touched.expiry ? (
                                      <FieldErrorMessage className="segundaCol">{errors.expiry}</FieldErrorMessage>
                                    ) : null}

                                    <InputField
                                      invalid={pagarComCartao && errors.expiry && touched.expiry}
                                      id="expiry"
                                      type="text"
                                      name="expiry"
                                      onBlur={handleBlur}
                                      onFocus={handleInputFocus}
                                      value={values.expiry}
                                      className="segundaCol"
                                      onChange={(e) =>
                                        setFieldValue(
                                          "expiry",
                                          formatExpirationDate(e.target.value)
                                        )
                                      }
                                      placeholder="Validade"
                                    />
                                    <Label className="segundaCol">Validade</Label>
                                  </FormGroup>
                                </div>
                                <div className="cardGroup">
                                  <FormGroup className="menorCampo">
                                    {errors.cvc && touched.cvc ? (
                                      <FieldErrorMessage>{errors.cvc}</FieldErrorMessage>
                                    ) : null}

                                    <InputField
                                      invalid={pagarComCartao && errors.cvc && touched.cvc}
                                      id="cvc"
                                      type="text"
                                      name="cvc"
                                      onBlur={handleBlur}
                                      onFocus={handleInputFocus}
                                      value={values.cvc}
                                      pattern="\d{3,4}"
                                      onChange={(e) =>
                                        setFieldValue("cvc", formatCVC(e.target.value))
                                      }
                                      placeholder="CVV"
                                    />
                                    <Label>{"Código de segurança (CVV)"}</Label>
                                  </FormGroup>
                                  <FormGroup>
                                    {amount && defaultValueInstallment && (
                                      <Select
                                        name="installments"
                                        defaultValue={defaultValueInstallment}
                                        isSearchable={false}
                                        placeholder="Número de parcelas"
                                        options={optionsInstallments}
                                        className="combobox segundaCol"
                                        onChange={(e) => {
                                          setFieldValue("installments", e.value);
                                        }}
                                      />
                                    )}
                                    <Label className="segundaCol">Número de parcelas</Label>
                                  </FormGroup>
                                </div>
                              </div>
                            </Form>
                            <ButtonFooterWrap style={{ display: "none" }} id="button-finalizar-pagamento" disabled={loading} type="submit" form="payment-form">
                              {loading ? (<><Loader type="Oval" color="#FFFFFF" height={16} width={16} /> {loadingButtonText}</>) : buttonText}
                            </ButtonFooterWrap>
                          </>
                        ))}
                      </Formik>
                    </>
                  )}
                  {(!pagarComCartao || deliveryData?.lojaSelecionada?.formaPagamento.online.length == 0) && deliveryData?.lojaSelecionada?.formaPagamento.presencial.length > 0 && (
                    <>
                      <Formik initialValues={{
                        paymentPlace: "P",
                        paymentMode: "",
                        paymentValue: "",
                        clientNameCE: "",
                        phoneCE: "",
                        companyName: "",
                        clientNameCV: "",
                        phoneCV: ""
                      }}
                        validate={(values) => { }}
                        validationSchema={PaymentSchema}
                        onSubmit={(values) => {
                          dispatch(OrderActions.orderRequest(values));
                        }}>
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          errors,
                          touched,
                          handleSubmit,
                          handleReset,
                          setFieldValue,
                          isSubmitting,
                          isValid,
                        }) => (deliveryData && deliveryData?.lojaSelecionada?.formaPagamento.presencial.length > 0 && (
                          <>
                            <Form id="payment-form" onSubmit={handleSubmit}>
                              <div className={`payment-form-type pag-local`}>
                                <h1>Trabalhamos com várias formas de pagamento</h1>
                                <RadioConteinerGroup>
                                  {deliveryData?.lojaSelecionada?.formaPagamento.presencial.map(payment => (
                                    <>
                                      {payment === "PI" && (
                                        <>
                                          <div key={payment} id="pagamento-pix" className={values.paymentMode === "PI" ? "radio selected" : "radio"}>
                                            <input className="" id="radio-1" value="PI" onChange={(e) => {
                                              handleReset();
                                              handleChange(e);
                                            }}
                                              onBlur={handleBlur}
                                              name="paymentMode" type="radio" checked={values.paymentMode === "PI" ? true : false} />
                                            <label htmlFor="radio-1" className="radio-label">PIX</label>
                                          </div>
                                        </>
                                      )}

                                      {payment === "DI" && (
                                        <>
                                          <div key={payment} id="pagamento-dinheiro" className={values.paymentMode === "DI" ? "radio selected" : "radio"}>
                                            <input id="radio-2" value="DI" onChange={(e) => {
                                              handleReset();
                                              handleChange(e);
                                            }}
                                              onBlur={handleBlur} name="paymentMode"
                                              type="radio" checked={values.paymentMode === "DI" ? true : false} />

                                            <label htmlFor="radio-2" className="radio-label">Dinheiro</label>
                                            {values.paymentMode === "DI" && (
                                              <>
                                                <span>Precisa de troco? Digite o valor que você vai pagar em dinheiro.</span>
                                                {errors.paymentValue && touched.paymentValue ? (
                                                  <FieldErrorMessage>{errors.paymentValue}</FieldErrorMessage>
                                                ) : null}
                                                <div className="textLine">
                                                  <div className="textArea">
                                                    <span>Informe o valor:</span>
                                                    <InputField id="paymentValue" placeholder="Ex: R$ 000,00" name="paymentValue"
                                                      invalid={errors.paymentValue && touched.paymentValue}
                                                      onChange={handleChange} onBlur={(e) => {
                                                        setFieldValue(
                                                          "paymentValue",
                                                          formatPrice(e.target.value.replace(',', '.').replace(/[^0-9\.]/g, ""))
                                                        )
                                                      }}
                                                      value={values.paymentValue} />
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}

                                      {payment === "CR" && (
                                        <>
                                          <div key={payment} id="pagamento-credito" className={values.paymentMode === "CR" ? "radio selected" : "radio"}>
                                            <input id="radio-3" value="CR" onChange={(e) => {
                                              handleReset();
                                              handleChange(e);
                                            }}
                                              onBlur={handleBlur} name="paymentMode"
                                              type="radio"
                                              checked={values.paymentMode === "CR" ? true : false} />
                                            <label htmlFor="radio-3" className="radio-label">
                                              Cartão de Crédito
                                            </label>
                                          </div>
                                        </>
                                      )}

                                      {payment === "CD" && (
                                        <>
                                          <div key={payment} id="pagamento-debito" className={values.paymentMode === "CD" ? "radio selected" : "radio"}>
                                            <input id="radio-4" value="CD" onChange={(e) => {
                                              handleReset();
                                              handleChange(e);
                                            }}
                                              onBlur={handleBlur} name="paymentMode"
                                              type="radio"
                                              checked={values.paymentMode === "CD" ? true : false}
                                            />
                                            <label htmlFor="radio-4" className="radio-label">
                                              Cartão de Débito
                                            </label>
                                          </div>
                                        </>
                                      )}

                                      {payment === "CE" && (
                                        <>
                                          <div key={payment} id="pagamento-crediario" className={values.paymentMode === "CE" ? "radio selected" : "radio"}>
                                            <input id="radio-5" value="CE" onChange={(e) => {
                                              handleReset();
                                              handleChange(e);
                                            }}
                                              onBlur={handleBlur} name="paymentMode"
                                              type="radio" checked={values.paymentMode === "CE" ? true : false}
                                            />
                                            <label htmlFor="radio-5" className="radio-label">Crediário</label>
                                            {values.paymentMode === "CE" && (
                                              <>
                                                <span style={{ display: (values.paymentMode === "CE" ? "block" : "none") }}>
                                                  Disponível apenas para clientes já cadastrados, com limite disponível e apresentação de documento de identificação.
                                                </span>
                                                <div style={{ display: (values.paymentMode === "CE" ? "block" : "none"), width: "100%" }}>
                                                  {values.paymentMode === "CE" && errors.clientNameCE && touched.clientNameCE ? (
                                                    <FieldErrorMessage>{errors.clientNameCE}</FieldErrorMessage>
                                                  ) : null}
                                                  {values.paymentMode === "CE" && errors.phoneCE && touched.phoneCE ? (
                                                    <FieldErrorMessage>{errors.phoneCE}</FieldErrorMessage>
                                                  ) : null}
                                                  <div className="textLine">
                                                    <div className="textArea">
                                                      <span>Nome:</span>
                                                      <InputField
                                                        id="clientNameCE"
                                                        invalid={(values.paymentMode === "CE") && errors.clientNameCE && touched.clientNameCE}
                                                        placeholder="Nome e sobrenome do cliente"
                                                        name="clientNameCE"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={values.paymentMode === "CE" ? false : true}
                                                      />
                                                    </div>
                                                    <div className="textArea">
                                                      <span style={{ paddingLeft: "0" }}>Telefone:</span>
                                                      <MaskedInput
                                                        mask={phoneMask}
                                                        invalid={values.paymentMode === "CE" && errors.phoneCE && touched.phoneCE}
                                                        id="phoneCE"
                                                        placeholder="Ex: (00) 0000-0000"
                                                        name="phoneCE"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        render={(ref, props) => (
                                                          <InputField ref={input => ref(input)} {...props} />
                                                        )}
                                                        disabled={values.paymentMode === "CE" ? false : true}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}

                                      {payment === "CV" && (
                                        <>
                                          <div key={payment} id="pagamento-convenio" className={values.paymentMode === "CV" ? "radio selected" : "radio"}>
                                            <input id="radio-6" value="CV" onChange={(e) => {
                                              handleReset();
                                              handleChange(e);
                                            }}
                                              onBlur={handleBlur} name="paymentMode"
                                              type="radio" checked={values.paymentMode === "CV" ? true : false}
                                            />
                                            <label htmlFor="radio-6" className="radio-label">Convênio</label>
                                            {values.paymentMode === "CV" && (
                                              <>
                                                <span style={{ display: (values.paymentMode === "CV" ? "block" : "none") }}>
                                                  Disponível apenas para clientes já cadastrados, com limite disponível e apresentação de documento de identificação.
                                                </span>
                                                <div style={{ display: (values.paymentMode === "CV" ? "block" : "none"), width: "100%" }}>
                                                  {values.paymentMode === "CV" && errors.companyName && touched.companyName ? (
                                                    <FieldErrorMessage>{errors.companyName}</FieldErrorMessage>
                                                  ) : null}
                                                  <div className="textLine">
                                                    <div className="textArea">
                                                      <span>Nome do convênio</span>
                                                      <InputField
                                                        id="companyName"
                                                        invalid={values.paymentMode === "CV" && errors.companyName && touched.companyName}
                                                        placeholder="Nome do empresa do convênio"
                                                        name="companyName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={values.paymentMode === "CV" ? false : true}

                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div style={{ display: (values.paymentMode === "CV" ? "block" : "none"), width: "100%" }}>
                                                  {values.paymentMode === "CV" && errors.clientNameCV && touched.clientNameCV ? (
                                                    <FieldErrorMessage>{errors.clientNameCV}</FieldErrorMessage>
                                                  ) : null}
                                                  {values.paymentMode === "CV" && errors.phoneCV && touched.phoneCV ? (
                                                    <FieldErrorMessage>{errors.phoneCV}</FieldErrorMessage>
                                                  ) : null}
                                                  <div className="textLine">
                                                    <div className="textArea">
                                                      <div>Nome completo</div>
                                                      <InputField
                                                        id="clientNameCV"
                                                        invalid={values.paymentMode === "CV" && errors.clientNameCV && touched.clientNameCV}
                                                        placeholder="Nome e sobrenome do cliente"
                                                        name="clientNameCV"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={values.paymentMode === "CV" ? false : true}
                                                      />
                                                    </div>
                                                    <div className="textArea">
                                                      <span style={{ paddingLeft: "0" }}>Telefone</span>
                                                      <MaskedInput
                                                        mask={phoneMask}
                                                        invalid={values.paymentMode === "CV" && errors.phoneCV && touched.phoneCV}
                                                        id="phoneCV"
                                                        placeholder="Ex: (00) 0000-0000"
                                                        name="phoneCV"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        render={(ref, props) => (
                                                          <InputField ref={input => ref(input)} {...props} />
                                                        )}
                                                        disabled={values.paymentMode === "CV" ? false : true}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))}
                                </RadioConteinerGroup>
                              </div>
                            </Form>
                            <ButtonFooterWrap style={{ display: "none" }} id="button-finalizar-pagamento" disabled={loading} type="submit" form="payment-form">
                              {loading ? (<><Loader type="Oval" color="#FFFFFF" height={16} width={16} /> {loadingButtonText}</>) : buttonText}
                            </ButtonFooterWrap>
                          </>
                        ))}
                      </Formik>
                    </>
                  )}
                </PaymentDetails>
              </PaymentContainer>
            </Wrap>
            <PriceInfoWrap>
              <TitleWrap>Resumo do Pedido</TitleWrap>
              <PriceInfoContent>
                <span className="price-description">Subtotal ({cart.length} itens)</span>
                <span className="price-value">{formatPrice(subtotal)}</span>
              </PriceInfoContent>

              {(subtotal - amount) > 0 && (
                <PriceInfoContent>
                  <span className="price-description">Descontos</span>
                  <span className="price-value">{formatPrice(subtotal - amount)}</span>
                </PriceInfoContent>
              )}

              {(deliveryData?.lojaSelecionada !== null && deliveryData?.tipoEntrega === "RECEBER_EM_CASA") && (
                <PriceInfoContent>
                  <span className="price-description">Custo de entrega</span>
                  <span className="price-value">{formatPrice(deliveryData.lojaSelecionada.custo_entrega)}</span>
                </PriceInfoContent>
              )}

              <PriceInfoContent className="total">
                <span className="price-description total">
                  Total
                </span>
                <span className="price-value total">{formatPrice(amount + ((deliveryData?.lojaSelecionada !== null && deliveryData?.tipoEntrega === "RECEBER_EM_CASA") ? parseFloat(deliveryData?.lojaSelecionada.custo_entrega) : 0))}</span>
              </PriceInfoContent>

              <ButtonFooterWrap
                id="button-finalizar-pagamento"
                form="payment-form"
                type="submit">
                Finalizar pedido
              </ButtonFooterWrap>
              <a className="linkCompra" href="/">Continuar comprando</a>

              <CollapseList />
            </PriceInfoWrap>
          </ContentWrap>
          <ModalConfirm
            message={orderConfirmationMessage}
            visible={showConfirmOrderModal}
            confirm={() => dispatch(OrderActions.confirmOrderRequest(true))}
            cancel={() => dispatch(OrderActions.hideConfirmOrderModal())}
          />
        </Container>
        {loading && (
          <OrderSubmittingOverlay>
            <div>
              <LoaderContainer className="loader-container">
                <Loader
                  type="ThreeDots"
                  color={colors.primary}
                  height={25}
                  width={25}
                />
              </LoaderContainer>

              <span>{loadingButtonText.toLowerCase()}</span>
            </div>
          </OrderSubmittingOverlay>
        )}
      </CheckoutPage>
    </BuyStepsTemplate>
  );
}
