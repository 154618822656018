import styled from 'styled-components';
import { transparentize, darken } from "polished";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 6000 !important;
  overflow-y: auto;
  top: 0;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100%;
  max-width: calc(705px - 40px);
  min-height: 445px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  margin-top: 6%;
  margin-bottom: 4%;
  padding:0px 64px 32px 64px;

  @media (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
    padding:0px 32px 16px 32px;
  }

  @media (max-width: 300px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
    padding:0px 16px 8px 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    color: ${props => props.theme.colors.headingLight}
  }

  svg {
    color: ${props => props.theme.colors.primary}
  }
`;


export const Content = styled.div`
  display: flex;
  margin-bottom: 32px;

  ul {
      display: grid;
      list-style: none;
      flex-direction: column;
      width: 100%;
      max-width: 460px;
      align-items: center;


      li {
        margin-top: 32px;
        display: flex;
        align-items: center;
        width: 100%;

        .card-icon-bg {
          min-width: 40px;
          margin-right: 16px;
          height: 40px;
          border-radius: 50px;
          background-color: ${props => transparentize(.9, props.theme.colors.primary, 0.05)};
          display: flex;
          align-items: center;

          svg {
            fill: ${props => props.theme.colors.primary};
            margin-left: -5px;
          }

        }

        .card-info {
          display: flex;
          flex-direction: column;

          span.card-title {
            font-weight: 500;
            color: #5E5E5E;
          }

          span.card-description {
            color: #A7A7A7;
            font-weight: normal;
            margin-top: 8px;

            strong {
              font-weight: 500;
            }
          }
        }

        @media(max-width: 360px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .card-icon-bg {
            margin-right: 0px;
            margin-bottom: 8px;
          }

          .card-info {
            span.card-title {
                text-align: center;
            }

            span.card-description {
              text-align: center;
            }
          }
        }
      }
    }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  border-top: 1px solid #EEF1F1;
  flex-wrap: wrap;

  .order-amount {
    .order-amount-label {
      font-size: 1.6rem;
      font-weight: 300;
      color: ${props => props.theme.colors.bodyLight}
    }

    .order-amount-value {
      margin-left: 4px;
      font-size: 2.5rem;
      font-weight: 500;
      color: ${props => props.theme.colors.bodyLight}
    }
  }

  button {
    width: 100%;
    max-width: 255px;
    height: 40px;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.primary};
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 0px ${props => darken(0.05, props.theme.colors.primary)};

    > div {
      margin-right: 12px;
      margin-top: 5px;
    }

    @media(max-width: 560px) {
      margin-top: 32px;
      max-width: 100%;
    }
  }
`;
