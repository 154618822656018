import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import imgFechar from "../../assets/images/close-popup.svg";
import ErrorOrder from "../../assets/images/warning.svg";
import { Creators as CartActions } from "../../store/ducks/cart";
import { Creators as ProductsActions } from "../../store/ducks/products";
import {
  Badge, Container, Content, ErrorMessage, Header, LoaderContainer, ProductContent, ProductInfoOrder, ProductQtd, ProductTitleOrder,
  RequestButton, ThumbnailWrapOrder
} from "./styles";

export default function OrderAgainModal({ order, closeModal }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { requestedProducts, error, loading } = useSelector(
    state => state.products
  );
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);
  const [productList, setProductList] = useState();

  useEffect(() => {
    setIsLoadingVisible(loading);
  }, [loading]);

  useEffect(() => {
    const selectedStore = JSON.parse(
      localStorage.getItem("@ConexaoGAM:selectedStore")
    );

    const groupProduct = _.groupBy(
      _.unionBy(requestedProducts, "id"),
      product => {
        const stock = product?.estoques?.filter(
          e =>
            e.loja_id === (selectedStore ? selectedStore.id : order.loja.id) &&
            e.quantidade > 0
        );
        if (_.isEmpty(product)) return;
        return !_.isEmpty(stock) && product.ativo
          ? "inventory"
          : "no_inventory";
      }
    );

    setProductList(groupProduct);
  }, [order, requestedProducts]);

  useEffect(() => {
    const productList = order.itens.map(item => item.produto.url);
    const selectedStore = JSON.parse(
      localStorage.getItem("@ConexaoGAM:selectedStore")
    );
    dispatch(
      CartActions.getProductListRequest(
        productList,
        selectedStore?.uuid || order.loja.uuid
      )
    );
    return () => dispatch(ProductsActions.clearSuggestionsRequest());
  }, [order, dispatch]);

  async function addProductToCart() {
    await productList["inventory"]?.map(async product => {
      const { quantityToBeAdded } = checkStock(product);
      dispatch(CartActions.addProduct(product, quantityToBeAdded, true, false, true));
    });
    closeModal();

    orderAgainStore();

    history.push("/carrinho");
  }

  function orderAgainStore() {
    localStorage.setItem("@ConexaoGAM:checkout", JSON.stringify({
      address: order.endereco_entrega,
      dadosEntrega: {
        tipoEntrega: order.tipo_entrega,
        lojaSelecionada: order.loja,
        dadosTerceiro: order.cpf_retirada_loja === null ? null : { nomeCompleto: order.nome_retirada_loja, cpf: order.cpf_retirada_loja?.replace(/[^\d]+/g, ""), telefone: order.telefone_retirada_loja?.replace(/[^\d]+/g, "") },
        enderecoCliente: order.endereco_entrega,
      },
    }));
  }

  function renderProductList(inventory) {
    return (
      _.has(productList, [inventory]) &&
      productList[inventory]?.map((product, i) => {
        const { quantityToBeAdded, stock, sumByProductQnt } = checkStock(
          product
        );
        return (
          <ProductInfoOrder
            key={i}
            className={inventory === "no_inventory" && "secondary"}
          >
            {inventory === "inventory" &&
              stock?.quantidade < sumByProductQnt && (
                <Badge>Estoque limitado</Badge>
              )}
            <ThumbnailWrapOrder>
              <img
                src={
                  product?.imagens?.length >= 1 &&
                  product?.imagens[0].caminho_imagem
                }
                alt={product.nome}
                title={product.nome}
              />
            </ThumbnailWrapOrder>
            <div>
              <p>{product.nome}</p>
              <ProductQtd
                className={inventory === "no_inventory" && "secondary"}
              >
                {inventory === "inventory" &&
                  `Qtd. à ser adicionada: ${quantityToBeAdded}`}
              </ProductQtd>
            </div>
          </ProductInfoOrder>
        );
      })
    );
  }

  function checkStock(product) {
    const productOrder = order.itens.filter(
      curr => curr.produto.id === product.id
    );
    const sumByProductQnt = _.sumBy(productOrder, "quantidade");
    const selectedStore = JSON.parse(
      localStorage.getItem("@ConexaoGAM:selectedStore")
    );
    const stock = product?.estoques?.find(
      e =>
        e.loja_id === (selectedStore ? selectedStore.id : order.loja.id) &&
        e.quantidade > 0
    );
    const quantityToBeAdded =
      stock?.quantidade >= sumByProductQnt
        ? sumByProductQnt
        : stock?.quantidade;

    return { quantityToBeAdded, stock, sumByProductQnt };
  }

  const loaderComponent = () => (
    <LoaderContainer>
      <Loader type="Oval" color="#00786A" height={35} width={35} />
    </LoaderContainer>
  );

  if (error) {
    return (
      <Container>
        <Content>
          <Header>
            <button className="close" onClick={() => closeModal()}>
              <MdClose />
            </button>
          </Header>
          <ErrorMessage>
            <span className="titulo">Desculpe!</span>
            <img
              src={ErrorOrder}
              alt="Erro ao buscar itens do pedido"
              title="Erro ao buscar itens do pedido"
            />
            <span className="descricao">
              Ocorreu um problema ao buscar os itens do pedido
            </span>
            <span className="descricao-menor">
              Tente novamente, caso continue o problema, entre em contato
              conosco
            </span>
          </ErrorMessage>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Header>
          <h1>Comprar novamente</h1>
          <button className="close" onClick={() => closeModal()}>
              <img src={imgFechar} alt={"Fechar"} />
          </button>
        </Header>
        <p>Veja abaixo quais produtos estão com estoque e prossiga para a compra.</p>
        <ProductContent>
          {isLoadingVisible
            ? loaderComponent()
            : ["inventory", "no_inventory"].map((inventory, index) => {
                if (_.has(productList, [inventory]))
                  return (
                    <div key={index}>
                      <ProductTitleOrder
                        className={inventory === "no_inventory" && "secondary"}
                      >
                        {inventory === "inventory"
                          ? "Produtos com estoque"
                          : "Produtos sem estoque"}
                      </ProductTitleOrder>
                      {renderProductList(inventory)}
                    </div>
                  );
              })}
        </ProductContent>
        <RequestButton
          disabled={!_.has(productList, ["inventory"])}
          onClick={() => addProductToCart()}
        >
          Adicionar os produtos na cesta
        </RequestButton>
      </Content>
    </Container>
  );
}
