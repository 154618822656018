import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";

import { Creators as BannersActions } from "../ducks/banners";
import { setQuantityProducts } from "./products";

export function* getBanners() {
  try {
    const response = yield call(api.get, "banners");
    yield put(BannersActions.getBannersSuccess(response.data));
  } catch (err) {
    yield put(
      BannersActions.getBannersFailure("Não foi possível obter os banners")
    );
  }
}

export function* setQuantityBannerProducts() {
  try {
    let { data: bannerProducts } = yield select(state => state.banners);

    if(bannerProducts.length > 0) {
      bannerProducts = yield setQuantityProducts(bannerProducts);

      yield put(BannersActions.getBannersProductsSuccess(bannerProducts));
    }

  } catch (err) {
    yield put(
      BannersActions.getBannersFailure("Não foi possível setar a quantidade nos produtos relacionados ao banner")
    );
  }
}



export function* getBannersProducts(action) {
  try {
    const response = yield call(api.get, `products/banner/${action.payload.uuid}`);
    let bannerProducts = response.data;


    if(bannerProducts.length > 0) {
      bannerProducts = yield setQuantityProducts(bannerProducts)
    }

    yield put(BannersActions.getBannersProductsSuccess(bannerProducts));
  } catch (err) {
    yield put(
      BannersActions.getBannersFailure("Não foi possível obter os produtos relacionados ao banner")
    );
  }
}
