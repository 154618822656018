/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Creators as SearchActions } from "../../store/ducks/search";
import { Container, ContainerButton, ContainerCheckFilter, ContainerCloseRound, ContainerFilters, ContainerInfoFilter, Content, Header, Title } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { OrdenationContext } from "../../contexts/OrdenationContext";
import api from "../../services/api";

import { FilterContext } from "../../contexts/FilterContext";
import ButtonPrimary from "../ButtonPrimary";
import ButtonSecundary from "../ButtonSecundary";
import CloseRound from "../CloseRound";

export default function Dropdown({ params, isModal, setIsOpen }) {
  const dispatch = useDispatch();
  const searchTerm = params.term || "";
  const store = useSelector(
    (state) => state.storeByUser.data
  );
  const { productsFound } = useSelector(
    state => state.search
  );
  const { ordenation } = useContext(OrdenationContext)
  const { setFilters } = useContext(FilterContext)

  const itemsDefault = [
    {
      title: "Valores",
      val: [{
        label: "Promoção",
        key: "desconto",
        value: true,
        selected: false
      }, {
        label: "Cartão Vantagem",
        key: "programaFidelidade",
        value: true,
        selected: false
      }],
      visible: false
    },
    {
      title: "Faixa de preço",
      val: [{
        label: "Até R$ 29,99",
        key: "preco",
        value: "{\"min\":0,\"max\":29.99}",
        selected: false
      }, {
        label: "De R$ 30,00 a R$ 59,99",
        key: "preco",
        value: "{\"min\":30,\"max\":59.99}",
        selected: false
      }, {
        label: "De R$ 60,00 a R$ 99,99",
        key: "preco",
        value: "{\"min\":60,\"max\":99.99}",
        selected: false
      }, {
        label: "Acima de R$ 100,00",
        key: "preco",
        value: "{\"min\":100,\"max\":null}",
        selected: false
      }]
    }
  ];

  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsCache, setItemsCache] = useState(itemsDefault)
  const [items, setItems] = useState(itemsDefault)

  useEffect(() => {
    callHandleSearchFilters();
  }, [dispatch, searchTerm, selectedItems, ordenation, params.ctg, params.subCtg])

  useEffect(() => {
    if (productsFound.length >= 1) {
      const hasGeneric = productsFound.find(product => product.generico === true)
      const hasNonGeneric = productsFound.find(product => product.generico === false)

      const existMedicine = items.find(item => item.title === "Medicamento")

      if (existMedicine) {
        const filteredItems = items.filter(item => item.title !== "Medicamento")

        saveItems(filteredItems, true);

        return
      }

      if (hasGeneric && hasNonGeneric) {

        saveItems([...items, {
          title: "Medicamento",
          val: [{
            label: "Genérico",
            key: "generico",
            value: true,
            selected: false
          }, {
            label: "Não Genérico",
            key: "generico",
            value: false,
            selected: false
          }],
          visible: false
        }], true);
      }
    }
  }, [productsFound])

  const handleSearchFilters = async () => {
    const removeAccents = searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    const removeUpperCase = removeAccents.toLowerCase()

    await api.post('/products/search/filters', {
      termo: removeUpperCase ? removeUpperCase : '',
      store: store ? store : null
    }, {
      params: {
        categoria: params.ctgId ? params.ctgId : null,
        subcategoria: params.subCtgId ? params.subCtgId : null
      }
    }).then(response => {
      // Filtro por fabricantes

      const filteredProducers = response.data.producers.map(e => {
        return {
          label: e.fabricante,
          key: "fabricante",
          value: e.fabricante,
          selected: false
        }
      })

      const finalObjectProducers = {
        title: "Fabricante",
        val: filteredProducers,
        visible: false
      }

      const alreadyExistentProducer = items.find(e => e.title === "Fabricante")

      if (alreadyExistentProducer) {
        let unique = items.filter(e => {
          return e.title !== alreadyExistentProducer.title
        });

        saveItems([...unique, finalObjectProducers], true);
      }

      if (!alreadyExistentProducer) {
        items.push(finalObjectProducers)

        saveItems(items, true);
      }

      // Filtro por tipos de produtos

      const filteredProductTypes = response.data.typeProduct.map(e => {
        return {
          label: e.descricao,
          key: "tipoProduto",
          value: e.tipo,
          selected: false
        }
      })

      const finalObject = {
        title: "Tipo Produto",
        val: filteredProductTypes,
        visible: false
      }

      const alreadyExistentProductType = items.find(e => e.title === "Tipo Produto")

      if (alreadyExistentProductType) {
        let unico = items.filter(e => {
          return e.title !== alreadyExistentProductType.title
        });

        saveItems([...unico, finalObject], true);
      }

      if (!alreadyExistentProductType) {
        items.push(finalObject)

        saveItems(items, true);
      }

      // Filtro por caracteristicas
      let objectsCharacteristics = []

      response.data.characteristics.map(responseCaracteristicas => {
        responseCaracteristicas.caracteristicas.map(caracteristica => {
          const alreadyExistentTitle = objectsCharacteristics.find(e => e.title === caracteristica.titulo)

          const alreadyExistentDescription = objectsCharacteristics.find(e => {
            return e.description.map(e => e === caracteristica.descricao)
          })

          if (alreadyExistentTitle) {
            if (alreadyExistentDescription.title === alreadyExistentTitle.title) {
              return
            }

            alreadyExistentTitle.description.push(caracteristica.descricao)

            return
          }

          objectsCharacteristics.push({
            title: caracteristica.titulo,
            description: [caracteristica.descricao]
          })
        })
      })

      objectsCharacteristics.map((e) => {
        const uniqueDescriptions = [...new Set(e.description)]

        const filteredCharacteristics = uniqueDescriptions.map(element => {
          return {
            label: element,
            key: `caracteristicas`,
            value: `[{"descricao":"${element}"}]`,
            selected: false
          }
        })

        const finalObject = {
          title: e.title,
          val: filteredCharacteristics,
          visible: false
        }

        const alreadyExistent = items.find(e => e.title === finalObject.title)

        if (alreadyExistent) {
          let characteristics = items.filter(e => {
            return e.title !== alreadyExistent.title
          });

          saveItems([...characteristics, finalObject], true);
        }

        if (!alreadyExistent) {
          items.push(finalObject)

          saveItems(items, true);
        }
      })
    })

  }

  const callHandleSearchFilters = () => {
    setFilters(selectedItems)
    handleSearchFilters().then(() => {
      if (!isModal)
        callSearchFilters();
    })
  }

  const callSearchFilters = () => {
    dispatch(SearchActions.setSearchFilters(
      selectedItems,
      searchTerm,
      ordenation,
      params.ctg ? params.ctgId : null,
      params.subCtg ? params.subCtgId : null
    ))
  }

  const handleChangeSelected = (value, indexCategory, categoria) => {
    categoria.val.map(filter => {
      if (filter.label === value.label)
        filter.selected = !filter.selected;

      return filter;
    })
    itemsCache[indexCategory] = categoria;
    saveItems([...itemsCache], !isModal);
    handleChangeSelectedModal([...itemsCache]);
  }

  const handleChangeSelectedModal = (itemsTarget) => {
    if (itemsTarget.length > 0) {
      const itemSelected = [];
      itemsTarget.forEach(item => {
        item.val.forEach(val => {
          if (val.selected)
            itemSelected.push(val)
        })
      });
      setSelectedItems(itemSelected)
    }
  }

  const saveItems = (param, saveOriginalItems) => {
    if (saveOriginalItems)
      setItems(param);
    setItemsCache(param);
  }

  const handleFilterApply = () => {
    setItems(itemsCache);
    handleChangeSelectedModal(itemsCache);
    setIsOpen();
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
    items.map(item => {
      item.val.map(val => {
        val.selected = false
      })
    })
    saveItems(items);
    handleFilterApply();
  }

  const closeDropdownFilter = () => {
    setItemsCache(items);
    setIsOpen();
  }

  return (
    <Container id="dropdownContainer">

      {isModal && (
        <ContainerCloseRound>
          <CloseRound onClick={closeDropdownFilter} className={"closeRound"} />
        </ContainerCloseRound>
      )}

      <ContainerInfoFilter hasPadding={isModal}>
        <Title>Filtros</Title>

        <ContainerFilters>
          {itemsCache && itemsCache.map((item, index) => {
            let Items;
            Items = item.val.map((e, indexItem) => (
              <ContainerCheckFilter key={index + indexItem}>
                <input id={`filter${indexItem}`} checked={e.selected} type="checkbox" name={`filter${indexItem}`} onClick={() => handleChangeSelected(e, index, item)} />
                <label htmlFor={`filter${indexItem}`}>{e.label}</label>
              </ContainerCheckFilter>
            ))


            return (
              <div>
                {
                  itemsCache[index].val.length >= 1 && (
                    <Header>
                      {itemsCache[index].val.length >= 1 && item.title}
                    </Header>
                  )
                }
                <div>
                  <Content>{Items}</Content>
                </div>
              </div>
            )
          })}
        </ContainerFilters>
      </ContainerInfoFilter>


      {isModal && (
        <ContainerButton>
          <ButtonPrimary className={"buttonFilter"} text={"Aplicar filtros"} disabled={selectedItems.length == 0} onClick={handleFilterApply} />
          <ButtonSecundary className={"buttonFilter"} text={"Limpar filtros"} disabled={selectedItems.length == 0} onClick={clearSelectedItems} />
        </ContainerButton>
      )}
    </Container>
  )
}
