import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  background-color: #ffa86a;
  justify-content: center;
  align-items: center;

`;

export const Label = styled.span`
  color: #08183C;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  span.mobile{
    display:none;
  }

  @media (max-width: 900px) {
    span.mobile {
      display: flex;
    }

    span.desktop{
      display: none;
    }
  }
`;
