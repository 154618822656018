import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import imgBanhoHigiene from '../../assets/images/categories/banho-e-higiene.png';
import imgBebeCrianca from '../../assets/images/categories/bebe-e-crianca.png';
import imgCabelo from '../../assets/images/categories/cabelo.png';
import imgCorpo from '../../assets/images/categories/corpo.png';
import imgCuidadoAdulto from '../../assets/images/categories/cuidado-adulto.png';
import imgCuidadoIntimo from '../../assets/images/categories/cuidado-intimo.png';
import imgDermocosmetico from '../../assets/images/categories/dermocosmetico.png';
import imgGestanteMaternidade from '../../assets/images/categories/gestante-e-maternidade.png';
import imgHigieneBucal from '../../assets/images/categories/higiene-bucal.png';
import imgMaosPes from '../../assets/images/categories/maos-pes.png';
import imgMaquiagem from '../../assets/images/categories/maquiagem.png';
import imgMedicamentos from '../../assets/images/categories/medicamentos.png';
import imgNossasMarcas from '../../assets/images/categories/nossas-marcas.png';
import imgRosto from '../../assets/images/categories/rosto.png';
import imgSaudaveisFuncionais from '../../assets/images/categories/saudaveis-e-funcionais.png';
import imgSetaAvancar from '../../assets/images/seta-avancar.svg';
import imgSetaVoltar from '../../assets/images/seta-voltar.svg';
import { Container } from './styles';


export default function Categories({ }) {

  const getPageSize = (divisor) => {
    const size = Math.abs(15 / divisor);
    const intSize = parseInt(size);
    if (size > intSize)
      return intSize + 1;
    else
      return intSize;
  };

  const [indexScroll, setIndexScroll] = useState(0)
  const [pageSize, setPageSize] = useState(getPageSize(8))

  const divCategories = React.createRef();

  const handleWindowResize = () => {
    if (window.innerWidth >= 1025) {
      setPageSize(getPageSize(8));
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
      setPageSize(getPageSize(6));
    } else if (window.innerWidth >= 481 && window.innerWidth <= 767) {
      setPageSize(getPageSize(4));
    } else if (window.innerWidth >= 320 && window.innerWidth <= 480) {
      setPageSize(getPageSize(2));
    }

    setIndexScroll(0);
  };

  const handleNextPage = () => {
    if (indexScroll < (pageSize - 1))
      handlePage(indexScroll + 1);
  }

  const handleBackPage = () => {
    if (indexScroll > 0)
      handlePage(indexScroll - 1);
  }

  const handlePage = (index) => {
    setIndexScroll(index);
    const left = (divCategories.current.scrollWidth / pageSize) * index;

    divCategories.current.scrollTo({
      left: left,
      behavior: 'smooth',
      duration: 7000
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <>
        <h2>{"Categorias"}</h2>
        <div ref={divCategories} className='categories'>
          <Link to={"/produtos/subcategoria/bebe-e-crianca/2"}>
            <div className='category'>
              <img src={imgBebeCrianca} alt={"Bebê e criança"} />
            </div>
            {"Bebê e criança"}
          </Link>
          <Link to={"/produtos/subcategoria/cabelo/8"}>
            <div className='category'>
              <img src={imgCabelo} alt={"Cabelo"} />
            </div>
            {"Cabelo"}
          </Link>
          <Link to={"/produtos/subcategoria/higiene-bucal/4"}>
            <div className='category'>
              <img src={imgHigieneBucal} alt={"Higiene bucal"} />
            </div>
            {"Higiene bucal"}
          </Link>
          <Link to={"/produtos/subcategoria/cuidado-adulto/5"}>
            <div className='category'>
              <img src={imgCuidadoAdulto} alt={"Cuidado adulto"} />
            </div>
            {"Cuidado adulto"}
          </Link>
          <Link to={"/produtos/subcategoria/cuidado-intimo/6"}>
            <div className='category'>
              <img src={imgCuidadoIntimo} alt={"Cuidado íntimo"} />
            </div>
            {"Cuidado íntimo"}
          </Link>
          <Link to={"/produtos/subcategoria/maos-e-pes/9"}>
            <div className='category'>
              <img src={imgMaosPes} alt={"Mãos e pés"} />
            </div>
            {"Mãos e pés"}
          </Link>
          <Link to={"/produtos/subcategoria/banho-e-higiene/7"}>
            <div className='category'>
              <img src={imgBanhoHigiene} alt={"Banho e higiene"} />
            </div>
            {"Banho e higiene"}
          </Link>
          <Link to={"/produtos/subcategoria/corpo/10"}>
            <div className='category'>
              <img src={imgCorpo} alt={"Corpo"} />
            </div>
            {"Corpo"}
          </Link>
          <Link to={"/produtos/subcategoria/rosto/11"}>
            <div className='category'>
              <img src={imgRosto} alt={"Rosto"} />
            </div>
            {"Rosto"}
          </Link>
          <Link to={"/produtos/subcategoria/saudaveis-e-funcionais/14"}>
            <div className='category'>
              <img src={imgSaudaveisFuncionais} alt={"Saudáveis e funcionais"} />
            </div>
            {"Saudáveis funcionais"}
          </Link>
          <Link to={"/produtos/subcategoria/dermocosmetico/12"}>
            <div className='category'>
              <img src={imgDermocosmetico} alt={"Dermocosmético"} />
            </div>
            {"Dermocosmético"}
          </Link>
          <Link to={"/produtos/subcategoria/maquiagem/13"}>
            <div className='category'>
              <img src={imgMaquiagem} alt={"Maquiagem"} />
            </div>
            {"Maquiagem"}
          </Link>
          <Link to={"/produtos/subcategoria/gestante-e-maternidade/3"}>
            <div className='category'>
              <img src={imgGestanteMaternidade} alt={"Gestante e maternidade"} />
            </div>
            {"Gestante maternidade"}
          </Link>
          <Link to={"/produtos/subcategoria/medicamentos/1"}>
            <div className='category'>
              <img src={imgMedicamentos} alt={"Medicamentos"} />
            </div>
            {"Medicamentos"}
          </Link>
          <Link to={"/produtos/subcategoria/nossas-marcas/15"}>
            <div className='category'>
              <img src={imgNossasMarcas} alt={"Nossas Marcas"} />
            </div>
            {"Nossas Marcas"}
          </Link>
        </div>
        <div className='actions'>
          <ul>
            <li onClick={handleBackPage}>
              <img src={imgSetaVoltar} alt="Voltar" />
            </li>
            {pageSize && (
              Array.from(Array(parseInt(pageSize)).keys()).map((item, index) => (
                <li key={index} onClick={() => handlePage(index)} >
                  <span key={index} className={indexScroll == index ? "active" : ""}></span>
                </li>
              ))
            )}
            <li onClick={handleNextPage}>
              <img src={imgSetaAvancar} alt="Avançar" />
            </li>
          </ul>
        </div>
      </>
    </Container>
  );
}
