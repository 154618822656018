import styled from "styled-components";

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  z-index: 5000;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  padding: 6.4rem;
  max-width: 100%;
  width: 500px;
  display: flex;
  background: #fff;
  font-size: 1.6rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 2rem;

  button {
    padding: 5px;
    background: transparent;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;

    svg {
      font-size: 2.5rem;
      stroke-width: 2;
      color: ${props => props.theme.colors.primary};
    }
  }

  @media (max-width: 480px) {
    padding: 3.5rem 3rem;

    button {
      top: 0px;
      right: 0px;

      svg {
        font-size: 2.8rem;
      }
    }
  }

  table {
    width: 100%;
    border: 1px solid #e7e7e7;
    border-collapse: collapse;

    .discount {
      img {
        width: 30px;
        height: 18px;
        margin-left: 5px;
      }
    }

    th,
    td {
      border-bottom: 1px solid #e7e7e7;
    }

    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
    }

    td {
      padding: 10px;
    }

    th {
      text-align: left;
      padding: 10px;
      background-color: ${props => props.theme.colors.primary};
      color: #fff;
    }
  }
`;
