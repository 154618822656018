import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as StoreCreators } from "../ducks/store";
import { Creators as DomainCreators } from "../ducks/domain";

export function* getStoreByDomain() {
  try {
    let domain = process.env.REACT_APP_DOMAIN_CONFIG;

    domain = domain.replace(/:/g, '%3A').replace(/\//g, '%2F');

    const response = yield call(api.get, `/stores/domain/${domain}`);
    yield put(StoreCreators.getSuccess(response.data));
    yield put(DomainCreators.getDomainSuccess());
  } catch (err) {
    yield put(DomainCreators.getDomainFailure(err.response.data.user));
  }
}