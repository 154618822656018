import { addDays, format } from 'date-fns';
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { MdArrowBack } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import CheckSuccess from "../../assets/images/check_success.svg";
import ErrorOrder from "../../assets/images/warning.svg";
import { setTitle } from "../../services/browser";
import { Creators as OrderActions } from "../../store/ducks/order";
import {
  Container, Content, ErrorMessage, KeepBuying,
  KeepBuyingLink,
  LoaderContainer
} from "./styles";
import storeIcon from "../../assets/images/storeIcon.svg";
import responsibleIcon from "../../assets/images/responsibleIcon.svg";
import timerIcon from "../../assets/images/timerIcon.svg";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import cardIcon from "../../assets/images/cardIcon.svg";
import { FormaPagamento, TipoPagamento, obterDescricaoFormaPagamento } from "../../util/payment";
import BuyStepsTemplate from '../../components/BuyStepsTemplate';

export default function OrderSuccess({ history }) {
  const { loading, error, orderId } = useSelector(state => state.cart);
  const { data } = useSelector(state => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data === null) return history.push('/');
    setTitle("Pedido Realizado!")
    return () => {
      dispatch(OrderActions.resetDeliveryMessage());
    };
  }, [data, dispatch, history]);

  if (loading) {
    return (
      <LoaderContainer>
        <Loader type="Oval" color="#007d44" height={40} width={40} />
      </LoaderContainer>
    );
  }

  if (error === null) {
    return (
      <BuyStepsTemplate step={4} history={history}>
        <Container>
          <Content>
            <img src={CheckSuccess} className="success-icon" alt="sucesso" />
            <div className='header'>
              <span className='title'>Compra finalizada</span>
              <span className="subTitle">Nº do pedido: <strong>#{orderId}</strong></span>
            </div>
            <span className='successText'>Sua compra foi realizada e seu pedido está sendo preparado. Abaixo, estão os dados para retirada.</span>
            <ul>
              <li>
                <div className='card-icon-bg'>
                  <img src={storeIcon} title={"Retirada dos Produtos"}></img>
                </div>


                <div className="card-info">
                  <span className="card-description">
                    <strong>{data?.store?.nome_fantasia}</strong> | {data?.store?.endereco} {data?.store?.endereco_numero}, {data?.store?.cidade} - {data?.store?.uf} | CNPJ  {data?.store?.cnpj?.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    )} | AFE - {data?.store?.caminho_afe}
                  </span>
                </div>
              </li>

              <li>
                <div className='card-icon-bg'>
                  <img src={responsibleIcon} title={"Responsible icon"}></img>
                </div>

                <div className="card-info">
                  <span className="card-title">Farmacêutico Responsável</span>
                  <span className="card-description">
                    {data?.store?.farmaceutico_nome} | Inscrição nº {data?.store?.farmaceutico_inscricao}
                  </span>
                </div>
              </li>

              {(data?.storeIsOpen) ? (
                <li>
                  <div className='card-icon-bg'>
                    <img src={timerIcon} title={"Timer icon"}></img>
                  </div>

                  <div className="card-info">
                    <span className="card-title">Tempo {data?.tipoEntrega === "RECEBER_EM_CASA" ? "de Entrega" : "para Retirada"}</span>
                    <span className="card-description">
                      Você {data?.tipoEntrega === "RECEBER_EM_CASA" ? "receberá" : "poderá retirar"} o seu pedido
                      {" "}
                      <b>
                        {(data?.store?.abertoAgora) ? "Hoje" : (data?.storeIsOpen.dia ? data?.storeIsOpen.dia.descricao[0].toUpperCase() + data?.storeIsOpen.dia.descricao.substr(1) : format(addDays(new Date(data?.storeIsOpen.data), 1), 'dd/MM/yyyy'))}
                      </b>
                      {" "}
                      {(data?.store?.abertoAgora) ? (data?.tipoEntrega === "RECEBER_EM_CASA" ? "até às" : "em") : "à partir das"}
                      {" "}
                      <b>{(data?.store?.abertoAgora) ? (data?.tipoEntrega === "RECEBER_EM_CASA" ? data?.storeIsOpen.hora_fim_entrega.slice(0, -3) : data.store?.tempo_para_retirada) : (data?.tipoEntrega === "RECEBER_EM_CASA" ? data?.storeIsOpen.hora_inicio_entrega.slice(0, -3) : data?.storeIsOpen.hora_inicio_retirada.slice(0, -3))}</b>
                    </span>
                  </div>
                </li>
              ) : (data?.deliveryMessage) && (
                <li>
                  <div className='card-icon-bg'>
                    <img src={timerIcon} title={"Timer icon"}></img>
                  </div>

                  <div className="card-info">
                    <span className="card-title">Tempo para {data?.tipoEntrega === "RECEBER_EM_CASA" ? "Entrega" : "Retirada"}</span>
                    <span className="card-description">
                      {ReactHtmlParser(data?.deliveryMessage)}
                    </span>
                    {data?.tipoEntrega !== "RECEBER_EM_CASA" && (
                      <span className="withdrawal-time">
                        {`Retirada disponível em ${data.store?.tempo_para_retirada}`}
                      </span>
                    )}
                  </div>
                </li>
              )}

              {data?.store?.telefone && (
                <li>
                  <div className='card-icon-bg'>
                    <img src={phoneIcon} title={"Phone icon"}></img>
                  </div>

                  <div className="card-info">
                    <span className="card-title">Telefone para Contato</span>
                    <span className="card-description">
                      {data?.store?.telefone.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3")}
                    </span>
                  </div>
                </li>
              )}

              {data?.payment?.formaPagamento && data?.payment?.tipoPagamento && (
                <li>
                  <div className='card-icon-bg'>
                    <img src={cardIcon} title={"Card icon"}></img>
                  </div>

                  <div className="card-info">
                    <span className="card-title">Forma de pagamento</span>
                    <span className="card-description">
                      {obterDescricaoFormaPagamento(data?.payment?.formaPagamento)} {data?.payment?.formaPagamento === FormaPagamento.CartaoCredito ? `(${data?.payment?.tipoPagamento === TipoPagamento.Online ? 'online' : 'presencial'})` : null}
                    </span>
                  </div>
                </li>
              )}

            </ul>

            <div className="actions">
              <KeepBuying havebg={"true"} to="/painel/meus-pedidos">
                <span>Meus Pedidos</span>
              </KeepBuying>
              <KeepBuyingLink to="/">Continuar comprando</KeepBuyingLink>
            </div>
          </Content>
        </Container>
      </BuyStepsTemplate>
    );
  } else {
    return (
      <BuyStepsTemplate step={4} history={history}>
        <Container>
          <ErrorMessage>
            <span className="titulo">Desculpe!</span>
            <img src={ErrorOrder} alt="Erro ao finalizar pedido" title="Erro ao finalizar Pedido" />
            <span className="descricao">
              Ocorreu um problema ao finalizar seu pedido
            </span>
            <span className="descricao-menor">
              Tente novamente, caso continue o problema, entre em contato conosco
            </span>

            <KeepBuying to="/carrinho">
              <MdArrowBack /> <span>Voltar para o carrinho</span>
            </KeepBuying>
          </ErrorMessage>
        </Container>
      </BuyStepsTemplate>

    );
  }
}
