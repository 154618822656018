import { lighten } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ButtonPrimary from "../../components/ButtonPrimary";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledLinkLogo = styled(Link)`
  margin-top: 40px;
  align-self: center;
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: ${props => props.theme.colors.bodyDark};
  padding: 5px 10px;
  border-radius: 3px;
  background: ${props => props.theme.colors.warning};
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const FormTitle = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  border: none;
  background: none;
  outline: none;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2.3rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.headingLight};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.white};
  border: 1px solid #E8E8E9;
  border-radius: 4px;
  margin: 40px 0px;
  padding: 64px;
`;

export const ForgotPassword = styled(Link)`
  align-self: flex-end;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-bottom: 24px;
  font-weight: 700;
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
`;

export const SignUp = styled.span`
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  margin: 32px auto 0;

  a {
    outline: none;
    cursor: pointer;
    font-size: 16px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary};
    margin-left: 5px;

    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
