import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import axios from 'axios';
import {format} from 'date-fns'
import { Container, Content, ParagraphContainer } from './styles';
import TermsOfUseModal from '../TermsOfUseModal';
import PrivacyPolicyModal from '../PrivacyPolicyModal';
import { useSelector } from 'react-redux';

function LgpdAcceptModal({ visible, user }) {
  const [showTermsOfUse, setTermsOfUse] = useState(false);
  const [buttonText, setButtonText] = useState("Li e aceito");
  const [showPrivacyPolicy, setPrivacyPolicy] = useState(false);


  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  async function handleUpdateLgpd() {
    setButtonText("Carregando...")
    const res = await axios.get('https://geolocation-db.com/json/')
    await api.put(`/customers/${user.uuid}/lgpd-accept`, {
      lgpd_aceite: true,
      ip: res.data.IPv4,
      lgpd_data_aceite: format(new Date(), 'yyyy-MM-dd')
    }).then(() => {
      setButtonText("Li e aceito")
      window.location.reload()
    })
  }

  return (
    <>
      <TermsOfUseModal visible={showTermsOfUse} closeModal={() => setTermsOfUse(false)} />
      <PrivacyPolicyModal visible={showPrivacyPolicy} closeModal={() => setPrivacyPolicy(false)} />

      {visible && (
        <Container>
          <Content>
            <div>
              <ParagraphContainer>
                <div>
                <h2>
                Políticas de Privacidade
                </h2>
                <p>
                  Para a proteção dos seus dados, apresentamos a nossa
                  {" "}
                  <a href="#" onClick={() => setPrivacyPolicy(true)}>
                    Políticas de Privacidade
                  </a>
                  {" "}
                  e
                  {" "}
                  <a href="#" onClick={() => setTermsOfUse(true)} >
                    Termos de uso
                  </a>
                </p>
                </div>
                <button onClick={() => handleUpdateLgpd()}>
                  {buttonText}
                </button>
              </ParagraphContainer>
            </div>
          </Content>
        </Container>
      )}
    </>
  );
}

export default LgpdAcceptModal;
