import produce from "immer";

export const Types = {
  SET_SELECTED_STORE: "@storeByUser/SET_SELECTED_STORE",
  TOGGLE_OUT_OF_STOCK_MODAL: "@storeByUser/TOGGLE_OUT_OF_STOCK_MODAL",
  CHECK_ITEMS_OUT_OF_STOCK: "@storeByUser/CHECK_ITEMS_OUT_OF_STOCK",
  SET_OUT_OF_STOCK_ITEMS: "@storeByUser/SET_OUT_OF_STOCK_ITEMS",
  DELETE_OUT_OF_STOCK_ITEMS: "@storeByUser/DELETE_OUT_OF_STOCK_ITEMS"
};

const INITIAL_STATE = {
  data: JSON.parse(localStorage.getItem("@ConexaoGAM:selectedStore")) || null,
  showOutOfStockModal: false,
  outOfStockItems: []
};

export default function storeByUser(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_SELECTED_STORE: {
        draft.data = action.payload.store;
        localStorage.setItem(
          "@ConexaoGAM:selectedStore",
          JSON.stringify(action.payload.store)
        );
        if(action.payload.reload) {
          window.location.reload(false);
        }
        break;
      }
      case Types.TOGGLE_OUT_OF_STOCK_MODAL: {
        draft.showOutOfStockModal = !draft.showOutOfStockModal;
        break;
      }
      case Types.SET_OUT_OF_STOCK_ITEMS: {
        draft.outOfStockItems = action.payload.outOfStockItems;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  setStore: (store, reload = true) => ({
    type: Types.SET_SELECTED_STORE,
    payload: { store, reload }
  }),

  toggleOutOfStockModal: store => ({
    type: Types.TOGGLE_OUT_OF_STOCK_MODAL
  }),

  checkItemsOutOfStock: () => ({
    type: Types.CHECK_ITEMS_OUT_OF_STOCK
  }),

  setOutOfStockItems: outOfStockItems => ({
    type: Types.SET_OUT_OF_STOCK_ITEMS,
    payload: { outOfStockItems }
  }),

  deleteOutOfStockItems: () => ({
    type: Types.DELETE_OUT_OF_STOCK_ITEMS
  })
};
