import styled from "styled-components";

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const ContainerCircle = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;

    span{
        font-size: 26px;
        color: ${props => props.theme.colors.primary};
    }
`;

export const CountIncrement = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.colors.black};
    margin: 0 10px;
`;