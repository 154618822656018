import React from "react";
import { useSelector } from "react-redux";
import packageJson from "../../../package.json";
import compraSeguraBlindado from "../../assets/images/comprasegura-blindado.png";
import compraSeguraGoogle from "../../assets/images/comprasegura-google.png";
import compraSeguraNorton from "../../assets/images/comprasegura-norton.png";
import { Container } from "./styles";

export default function FooterCompact() {
  const { data } = useSelector(state => state.store);
  return (
    <Container>
      <div className="footer-pags">
        <div>
          <div className="payment">
            <strong>Formas de pagamento</strong>
            {data?.empresa?.plataforma_pagamento?.nome && data?.empresa?.plataforma_pagamento?.nome !== 'undefined' && (
              <ul>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.visa.svg`}
                    alt="visa"
                    title="Visa"
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.mastercard.svg`}
                    alt="mastercard"
                    title="Mastercard"
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.elo.svg`}
                    alt="elo"
                    title="Elo"
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.amex.svg`}
                    alt="amex"
                    title="Amex"
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
        <div>
          <strong>Compra segura!</strong>
          <ul>
            <li>
              <img
                src={compraSeguraGoogle}
                alt="Compra segura"
              />
            </li>
            <li>
              <img
                src={compraSeguraBlindado}
                alt="Compra segura"
              />
            </li>
            <li>
              <img
                src={compraSeguraNorton}
                alt="Compra segura"
              />
            </li>
          </ul>
        </div>
        <div>
          <div className="logo-policy">
            <div className="logo">
              <img
                src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
                alt={`${data?.empresa?.nome}`}
              />
            </div>
            <div className="policy">
              <p>
                © 2023{" "} {data?.empresa?.nome}
                <br />
                Todos os direitos reservados - v.{packageJson.version}
              </p>
            </div>
          </div>
        </div>

      </div>
    </Container>
  );
}
