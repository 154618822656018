import React from 'react';
import { Helmet } from 'react-helmet';

function HelmetCustom(props) {

  return (
    <Helmet>
      {props.children}
      <meta name={props.name || "description"} content={props.content || "Os melhores produtos e as melhores ofertas você só encontra aqui! Medicamentos, remédios genéricos, dermocosméticos, itens de cuidado diário e muito mais!"} />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:url" content={props.fbUrl || "https://associadasapp.com.br"} />
      <meta property="og:title" content={props.fbTitle || "Farmácias Associadas"} />
      <meta property="og:site_name" content="Farmácias Associadas" />
      <meta property="og:description" content={props.fbDescription || "Os melhores produtos e as melhores ofertas você só encontra aqui! Medicamentos, remédios genéricos, dermocosméticos, itens de cuidado diário e muito mais!"} />
      <meta property="og:image" content={props.fbImage || "https://i1.wp.com/www.febrafar.com.br/wp-content/uploads/2018/05/farmacias-associadas.png?fit=850%2C497&ssl=1"} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="600" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}

export default HelmetCustom;
