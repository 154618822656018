import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import bag from "../../assets/images/bag.svg";
import OrderAgainModal from "../../components/OrderAgainModal";
import { setTitle } from "../../services/browser";
import { Creators as MyOrderActions } from "../../store/ducks/myOrders";
import { formatPrice } from "../../util/format";
import { Container, LoaderContainer, NoOrders, Wrap } from "./styles";

export default function MyOrders({ history }) {
  const { data: orders, loading } = useSelector(state => state.myOrders);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const dispatch = useDispatch();

  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    setTitle("Meus Pedidos");
    dispatch(MyOrderActions.getRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Wrap>
          {loading && orders.length === 0 ? (
            <LoaderContainer>
              <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
              <span>Carregando pedidos...</span>
            </LoaderContainer>
          ) : (
            <>
              {orders && orders.length === 0 && (
                <>
                  <NoOrders>
                    <p>Você ainda não posssui pedidos realizados</p>
                  </NoOrders>
                </>
              )}
              {orders &&
                orders.map(order => (
                  <div key={order.id} className="item-content">
                    <div>
                      <div className="header-content"
                        onClick={() => {
                          setTitle("Pedido #" + order.id);
                          dispatch(MyOrderActions.setOrderSelected(order));
                          history.push("/painel/detalhes-pedido");
                        }}>
                        <div className="items-name">
                          {order?.itens[0] == undefined ? "Este pedido não possui itens" : order?.itens.map((i) => i.produto.nome).join().replace(/[\w\W]*/, (i) => { return i.length > 62 ? i.substring(0, 62) + `...` : i; })}
                        </div>
                        <div className="item-status">
                          <span className={order?.status}>{order?.status_cliente}</span>
                        </div>
                      </div>
                      <div className="info-content">
                        <div className="info-area">
                          <div className="item-id">
                            <span>
                              Nº do pedido
                            </span>
                            <span>
                              <strong>{order.id}</strong>
                            </span>
                          </div>

                          <div className="item-date">
                            <span>
                              Data da compra
                            </span>
                            <span>
                              <strong>
                                {" "}
                                {format(
                                  new Date(order?.data_criacao),
                                  "dd/MM/yyyy",
                                  {
                                    locale: pt
                                  }
                                )}
                              </strong>
                            </span>
                          </div>

                          <div className="item-amount">
                            <span>
                              Valor
                            </span>
                            <span>
                              <strong>
                                {formatPrice(order?.pagamento.valor_total)}
                              </strong>
                            </span>
                          </div>
                          <div className="button-area">
                            <div className="item-button repeat-order">
                              <button
                                onClick={() => {
                                  setSelectedOrder(order);
                                  setModalIsOpen(!modalIsOpen);
                                }}
                              >
                                <img src={bag} alt="Pedido" />
                                <span>Comprar novamente</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </Wrap>
      </Container>
      {modalIsOpen && (
        <OrderAgainModal order={selectedOrder} closeModal={closeModal} />
      )}
    </>
  );
}
