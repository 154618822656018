import styled from 'styled-components';

export const Container = styled.div`
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 8px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-width: 341px;
`;

export const FirstColumn = styled.div`
    display: inline-flex;
    align-items: center;

    img {
        margin-left: 16px;
        height: 29px;
        width: 44px;
    }
`;

export const ContainerInfoUnits = styled.div`
    span{
        color: ${props => props.theme.colors.black};
    }
`;

export const SecondColumn = styled.div`
    align-self: center;

    span{
        color: ${props => props.theme.colors.primary};
    }
`;