import { transparentize } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 355px);
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 64px;
  flex-shrink: 1;
  padding: 16px;
  -webkit-animation: linear alternate;
  -webkit-animation-name: delivery;
  -webkit-animation-duration: 1s;

    @keyframes delivery {
    0% {
      left: -250px;
      top: 0px;
    }
    70% {
      left: 10px;
      top: 0px;
    }
    100% {
      left: 0;
      top: 0px;
    }
  }

  .header {
    display: flex;
    max-width: 460px;
    width: 100%;

    .title {
      width: 100%;
      font-weight: 700;
      font-size: 25px;
      color: ${props => props.theme.colors.black};
    }

    .subTitle {
      width: 100%;
      text-align: right;
      font-size: 16px;
      color: ${props => props.theme.colors.neutral900};
      align-self: center;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 64px;
  }

  >.success-icon {
    align-self: start;
    padding-left: 56px;
    padding-bottom: 15px;
  }

  >.successText {
    max-width: 460px;
    width: 100%;
    padding-top: 30px;
    color: ${props => props.theme.colors.neutral900};
  }

  h1 {
    color: ${props => props.theme.colors.primary};
    font-size: 2.2rem;
    text-transform: uppercase;
    margin-top: 64px;
    font-weight: 300;

    strong {
      font-weight: 500;
    }
  }

  > ul {
    display: grid;
    list-style: none;
    flex-direction: column;
    width: 100%;
    max-width: 460px;
    align-items: center;


    li {
      margin-top: 32px;
      display: flex;
      align-items: center;
      width: 100%;

      .card-icon-bg {
        margin-right: 16px;
      }

      .card-info {
        display: flex;
        flex-direction: column;

        span.card-title {
          font-weight: 700;
          color: ${props => props.theme.colors.neutral900};
        }

        span.card-description {
          color: ${props => props.theme.colors.neutral900};
          font-weight: normal;
          margin-top: 8px;
          font-weight: 500;
          
          strong {
            font-weight: 700;
          }
        }

        span.withdrawal-time {
          color: #57B85B;
          font-weight: 400;

          strong {
            font-weight: 600;
            color: #57B85B;
          }
        }
      }

      @media(max-width: 360px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card-icon-bg {
          margin-right: 0px;
          margin-bottom: 8px;
        }

        .card-info {
          span.card-title {
              text-align: center;
          }

          span.card-description {
            text-align: center;
          }
        }
      }
    }
  }
`;

export const KeepBuying = styled(Link)`
  display: flex;
  font-size: 16px;
  padding: 15px 25px;
  width: 200px;
  max-width: 460px;
  justify-content: center;
  text-decoration: none;
  margin-right: 20px;
  color: ${props => (props.havebg === "true" ? "#ffffff" : props.theme.colors.primary)};
  align-items: center;
  border: 1px solid
    ${props => (props.havebg === "true" ? "#ffffff" : props.theme.colors.primary)};
  font-family: 'Red Hat Display', sans-serif;;
  border-radius: 4px;
  transition: 0.2s;
  font-weight: 500;
  background-color: ${props =>
    props.havebg === "true" ? props.theme.colors.primary : "transparent"};
  cursor: pointer;
  border-radius: 40px;
  span {
    display: flex;
    align-self: center;
  }

  svg {
    display: flex;
    margin-right: 5px;
  }
`;

export const SuccessMessage = styled.div`
  padding: 50px;
  height: 90%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;

  h1 {
    color: ${props => props.theme.colors.primary};
    font-weight: 600;
    text-transform: uppercase;
    font-size: 25px;
    padding: 10px;
  }

  span.order-number {
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 5px;
    color: ${props => props.theme.colors.headingLight};
    font-size: 22px;
  }

  .deliveryMessage {
    font-weight: 400;
    padding: 5px;
    color: red;
    font-size: 1.8rem;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 130px;
  }

  span.titulo {
    font-size: 22px;
    margin-bottom: 20px;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${props => props.theme.colors.headingLight};
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 500;
  }

  span.descricao {
    font-size: 15px;
    text-align: center;
    width: 70%;
    margin-top: 12px;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${props => props.theme.colors.bodyLight};
    text-transform: uppercase;
    letter-spacing: 1.3px;
  }

  span.descricao-menor {
    font-size: 14px;
    text-align: center;
    width: 70%;
    margin-top: 10px;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${props => props.theme.colors.bodyLight};
    letter-spacing: 1.3px;
  }
`;

export const KeepBuyingLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
`;