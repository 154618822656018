import React from "react"
import BuySteps from "../BuySteps"
import { FooterCompact } from "../Footer"
import { Container } from "./styles"

const BuyStepsTemplate = ({ children, history, step }) => {
    return (
        <Container>
            <BuySteps step={step} history={history} />
            {children}
            <FooterCompact />
        </Container>
    )
}

export default BuyStepsTemplate;