import styled from "styled-components";

export const Container = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
`;

export const ContainerCircle = styled.div`
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.white};
    margin-right: 8px;
    cursor: pointer;
    border: 1px solid #72757A;

    &:hover{
        border-color: ${props => props.theme.colors.primary};
        span{
            color: ${props => props.theme.colors.primary};
        }
    }

    &[aria-disabled="true"]{
        border: none;
        background: ${props => props.theme.colors.grayLight};
        cursor: auto;
    }

    &[aria-selected="true"]{
        background: ${props => props.theme.colors.primary};
        span{
            color: ${props => props.theme.colors.white};
        }
    }

    span{
        color: #72757A;
        user-select: none;
    }

    img{
        user-select: none;
    }
`;

export const ConatinerExpand = styled.div`
    background: ${props => props.theme.colors.grayLight};
`;