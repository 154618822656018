import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 50px;
  p {
    margin-top: 5px;
    width: 100%;
  }

  .address-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: 700;
      padding: 16px 32px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      background: ${props => darken(0.05, props.theme.colors.primary)};
      color: ${props => props.theme.colors.bodyDark};
      border-radius: 2px;
      font-size: 16px;
      border: none;
      border-radius: 40px;
      justify-content: center;
    }
  }

  @media (max-width: 480px) {
    .address-panel-header {
      flex-direction: column;
    }

    .new-address-button {
      margin-top: 15px;
      width: 100%;
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const AddressesManagement = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  background: #fafafa;
  padding: 30px 25px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.neutral100};
  flex-direction: column;

  .address-card-owner {
    display: flex;
    flex-direction: column;

    span {
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.colors.neutral600};
    }

    strong {
      font-size: 16px;
      font-weight: 700;
      color: ${props => props.theme.colors.neutral900};
    }
  }

  .address-card-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.theme.colors.neutral900};
  }

  .address-buttons{
    display: flex;
    align-self: flex-end;

    img {
      margin-right: 5px;
    }

    svg {
      margin-right: 5px;
    }

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-self: auto;
      margin-top: 24px;
    }
  }

`;

export const UpdateAddressButton = styled.button`
  color: ${props => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
`;

export const RemoveAddressButton = styled.button`
  color: ${props => props.theme.colors.error};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-self: center;
  align-items: end;
  >svg{
    font-size: 20px;
  }
  margin-left: 10px;
`;

export const RemoveAddressConfirmation = styled.div`
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.1);

  > div {
    width: 500px;
    height: 180px;
    margin: 0 10px;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.07);

    .content {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      h1 {
        color: ${props => props.theme.colors.headingLight};
        padding: 10px 30px;
        font-size: 2.2rem;
        font-weight: 400;
        width: 100%;
      }

      p {
        color: ${props => props.theme.colors.bodyLight};
        padding: 10px 30px;
        font-size: 1.8rem;
        font-weight: 300;
        width: 100%;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 70px;
      border-radius: 0 0 4px 4px;
      background: #fff;
      border-top: ${props => props.theme.colors.primary};

      .remove-btn {
        border: none;
        height: 40px;
        min-height: 40px;
        font-weight: 500;
        background: ${props => props.theme.colors.warning};
        font-size: 1.6rem;
        color: ${props => props.theme.colors.bodyDark};
        padding: 5px 10px;
        border-radius: 4px;
        margin-right: 10px;
        margin-left: 10px;

        :active {
          background: ${props => darken(0.1, props.theme.colors.warning)};
        }
      }

      .cancel-btn {
        border: none;
        height: 40px;
        font-weight: 500;
        background: transparent;
        font-size: 1.6rem;
        color: ${props => props.theme.colors.primary};
        padding: 5px 10px;
        border: 1px solid ${props => props.theme.colors.primary};
        border-radius: 4px;

        :active {
          background: ${props => props.theme.colors.primary};
          color: ${props => props.theme.colors.bodyDark};
        }
      }
    }
  }
`;

export const AddressForm = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  padding: 50px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  align-items: center;

  h1 {
    color: ${props => props.theme.colors.neutral900};
    font-weight: 700;
    font-size: 20px;
    font-family: 'Red Hat Display', sans-serif;
    padding-bottom: 40px;
    padding-top: 25px;
  }

  >div{
    width: 600px;
    background: #FFF;
    padding: 30px 30px 45px 30px;
    border-radius: 16px;

    >div {
      >img {
        float: right;
        cursor: pointer;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 100vh;
      border-radius: 0;
      top:0;
      left: 0;
      position: fixed;
      overflow-y: auto;
    }
  }
`;

export const ButtonFooterWrap = styled.button`
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background: none;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;

  &:hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.8;
  }

  > div {
    margin-top: 5px;
    margin-right: 12px;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    width: 100%;

  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }

  >.btnArea{
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  >.groupLine {
    display: flex;
    width: 100%;

    >.smallField {
      width: 30%;
    }

    >.marginLeft{
      margin-left: 20px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
      >.smallField {
        width: 100%;
      }
      >.marginLeft{
        margin-left: 0px;
      }
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const InputField = styled.input`
  height: 45px;
  background: #ffffff;
  border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;

  + label {
    font-size: 12px;
    font-weight: 700;
    color: ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.neutral900};
  }

  :focus {
    border: 1px solid
      ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${props =>
    props.invalid ? "red" : props => props.theme.colors.primary};
  }

  ::placeholder {
    color: ${props => props.theme.colors.bodyLight};
  }

  :disabled {
    background: #f1f1f1;
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.headingLight};
`;

export const SubmitBtn = styled.button`
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: none;
  color: ${props => props.theme.colors.bodyDark};
  background: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  order: 2;

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  :disabled {
    background: ${props => props.theme.colors.neutral200};
    color: ${props => props.theme.colors.neutral800};
  }

  @media (max-width: 760px) {
    margin-top: 0;
  }
`;

export const CancelBtn = styled.button`
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: none;
  color: ${props => props.theme.colors.primary500};
  font-size: 16px;
  font-weight: 700;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  order: 2;

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  :disabled {
    background: ${props => props.theme.colors.neutral200};
    color: ${props => props.theme.colors.neutral800};
  }

  @media (max-width: 760px) {
    margin-top: 0;
  }
`
