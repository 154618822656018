import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

:root {
  --alertwarning: rgba(243, 203, 60, 1);
  --alertsuccess: rgba(48, 211, 113, 1);
  --alertsuccesshover: rgba(38, 168, 90, 1);
  --alerterror: rgba(222, 22, 22, 1);
  --alerterro-hover: rgba(182, 5, 5, 1);
  --neutral-900: rgba(21, 25, 34, 1);
  --neutral-800: rgba(68, 71, 78, 1);
  --neutral-600: rgba(114, 117, 122, 1);
  --neutral-400: rgba(161, 163, 167, 1);
  --neutral-200: rgba(208, 209, 211, 1);
  --neutral-100: rgba(232, 232, 233, 1);
  --neutral-50: rgba(243, 243, 244, 1);
  --primary-900: rgba(8, 24, 60, 1);
  --primary-700: rgba(12, 36, 90, 1);
  --primary-500: rgba(20, 60, 151, 1);
  --primary-300: rgba(114, 138, 192, 1);
  --primary-100: rgba(161, 177, 213, 1);
  --primary-50: rgba(208, 216, 234, 1);
  --primary-40: rgba(231, 235, 245, 1);
  --primary-30: rgba(241, 243, 249, 1);
  --primary-20: rgba(248, 249, 252, 1);
  --contrast-900: rgba(87, 231, 222, 1);
  --title-h1-font-family: "RedHat Display", Helvetica;
  --title-h1-font-weight: 700;
  --title-h1-font-size: 25px;
  --title-h1-letter-spacing: 0px;
  --title-h1-line-height: normal;
  --title-h1-font-style: normal;
  --subtitle-h2-font-family: "RedHat Display", Helvetica;
  --subtitle-h2-font-weight: 700;
  --subtitle-h2-font-size: 20px;
  --subtitle-h2-letter-spacing: 0px;
  --subtitle-h2-line-height: normal;
  --subtitle-h2-font-style: normal;
  --subtitle-h3-font-family: "RedHat Display", Helvetica;
  --subtitle-h3-font-weight: 700;
  --subtitle-h3-font-size: 16px;
  --subtitle-h3-letter-spacing: 0px;
  --subtitle-h3-line-height: normal;
  --subtitle-h3-font-style: normal;
  --subtitle-h4-font-family: "RedHat Display", Helvetica;
  --subtitle-h4-font-weight: 700;
  --subtitle-h4-font-size: 14px;
  --subtitle-h4-letter-spacing: 0px;
  --subtitle-h4-line-height: normal;
  --subtitle-h4-font-style: normal;
  --paragraph-1-font-family: "RedHat Display", Helvetica;
  --paragraph-1-font-weight: 500;
  --paragraph-1-font-size: 16px;
  --paragraph-1-letter-spacing: 0px;
  --paragraph-1-line-height: 26.5px;
  --paragraph-1-font-style: normal;
  --paragraph-2-font-family: "RedHat Display", Helvetica;
  --paragraph-2-font-weight: 500;
  --paragraph-2-font-size: 14px;
  --paragraph-2-letter-spacing: 0px;
  --paragraph-2-line-height: normal;
  --paragraph-2-font-style: normal;
  --link-font-family: "RedHat Display", Helvetica;
  --link-font-weight: 700;
  --link-font-size: 14px;
  --link-letter-spacing: 0px;
  --link-line-height: normal;
  --link-font-style: normal;
  --label-font-family: "RedHat Display", Helvetica;
  --label-font-weight: 700;
  --label-font-size: 12px;
  --label-letter-spacing: 0px;
  --label-line-height: normal;
  --label-font-style: normal;
  --cards: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
}


 * {
   margin:0;
   padding: 0;
   box-sizing:border-box;
   outline:0;
 }

 html {
   font-size: 62.5%;
 }

#root {
  position: relative;
  font-size: 1.6rem;
}


 html, body, #root {
   background: ${props => props.theme.colors.background} !important;
 }



 .toast {
   font-size: 1.4rem;
 }

 .Toastify__toast {
  font-size: 1.4rem;
 }

 .Toastify__toast--error{
  font-size: 1.4rem;
 }


 body{
   font-family: 'Red Hat Display', sans-serif;;
   text-rendering: optimizeLegibility !important;
   -webkit-font-smoothing: antialised !important;
   overflow-x: hidden;
   color: ${props => props.theme.colors.bodyLight};
 }

 button {
   cursor: pointer;
   font-family: 'Red Hat Display', sans-serif;;
   font-size: 1.6rem;
   color: ${props => props.theme.colors.bodyLight};
 }

 a {
    text-decoration: none;
    color: inherit;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyle;
