import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Form,
  IrParaLogin,
  Title,
  StyledLinkLogo,
  StyledButtonPrimary,
} from "./styles";
import { Creators as PasswordActions } from "../../store/ducks/password";
import { setTitle } from "../../services/browser";
import InputCustom from "../../components/InputCustom";
import { ToastContainer } from "react-toastify";

export default function ForgotPassword({ history }) {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { error, success } = useSelector(state => state.password);
  const { data } = useSelector(state => state.store);

  useEffect(() => {
    if (error !== null && error?.type === "INVALID_EMAIL_ERROR") {
      formikRef.current.setFieldError("email", error.message);
    }
  }, [error]);

  useEffect(() => {
    setTitle("Recuperar Senha");
  }, []);

  function handleSubmit({ email }) {
    dispatch(PasswordActions.recoverPasswordRequest(email));
    // setEnviou(true);
  }

  const ChangePasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Informe um e-mail válido")
      .required("E-mail não pode ser vazio"),
  });

  const handleGoToLogin = () => {
    history.push("/entrar");
  }

  return (
    <Container>
      <ToastContainer autoClose={2500} />
      {data && data.empresa && data.empresa.url && (
        <StyledLinkLogo to="/">
          <img
            src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
            title={data?.empresa?.nome}
            alt={`${data?.empresa?.nome}`}
          />
        </StyledLinkLogo>
      )}
      {!success || error !== null ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ChangePasswordSchema}
          onSubmit={handleSubmit}
          ref={formikRef}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isValid,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>
                <h2>Recuperar senha</h2>
                <span>Informe seu e-mail de cadastro para recuperar sua senha.</span>
              </Title>
              <InputCustom id={"email"} name={"email"} type={"email"} description={"E-mail"} value={values.email} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.email && touched.email} errors={errors.email} />
              <StyledButtonPrimary text="Enviar e-mail" disabled={isSubmitting || !isValid} />
              <IrParaLogin to="/entrar">Voltar</IrParaLogin>
            </Form>
          )}
        </Formik>
      ) : (
        <Form>
          <Title>
            <h2>E-mail enviado!</h2>
            <span>Enviamos um link para você cadastrar uma nova senha</span>
          </Title>

          <StyledButtonPrimary text="Ir para o login" onClick={handleGoToLogin}/>
        </Form>
      )}
    </Container>
  );
}
