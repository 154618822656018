import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  padding: 32px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  .rewards-form-acceptance {
    display: flex;
    align-items: center;
    margin-top: 16px;

    label {
      font-weight: 500;
      display: flex;
      align-items: center;
      cursor: pointer;

      input {
        margin-right: 10px;
      }
    }
  }
`;
