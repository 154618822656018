import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 0;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 64px;
  max-width: 800px;
  margin-left: 20px;
  margin-right: 20px;
  background: #fff;
  border-radius: 8px;
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
    }
  }

  button {
    width: 40%;
    background: ${props => props.theme.colors.primary};
    height: 50px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 1.4rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    margin: 20px;

    :active {
      background: ${props => darken(0.03, props.theme.colors.primary)};
    }

    @media (max-width: 900px) {
      max-width: 100%;
      width: 250px;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 12px;
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.bodyLight};
    padding-bottom: 16px;
    line-height: 3rem;
    text-align: left;
  }

  b {
    color: ${(props) => props.theme.colors.bodyLight};
    font-weight: 400;
  }

  a {
    background: transparent;
    border: none;
    color: ${props => props.theme.colors.primary};
    font-weight: 500;
    text-decoration: none;
  }
`;
