import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import DeliveryTruck from "../../assets/images/delivery-truck.svg";
import Error from "../../components/Error";
import SubHeader from "../../components/SubHeader";
import api from "../../services/api";
import { setTitle } from "../../services/browser";
import { Creators as AddressActions } from "../../store/ducks/address";
import { Creators as ErrorActions } from "../../store/ducks/error";
import { Creators as StoreByUserActions } from "../../store/ducks/storeByUser";
import {
  AddressCard, AddressForm, AddressList, Container, ContinueButton, FieldErrorMessage, Form, FormGroup, InputField, Label, LoaderContainer, NewAddressButton, Radio, RemoveAddressConfirmation, SubmitBtn, Wrapper
} from "./styles";
import { AddressSchema, cepMask, phoneMask } from "./validation";
import MainLayout from "../../components/MainLayout";


export default function SelectAddress({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const cart = useSelector((state) => state.cart.data);
  const store = useSelector(state => state.storeByUser.data);
  const { data: addresses, loading, newAddressLoading } = useSelector(state => state.address);
  const [formVisible, setFormVisible] = useState(false);
  const [addressSelected, setAddressSelected] = useState(null);
  const [addressToRemove, setAddressToRemove] = useState(null);
  const [removeAddressLoading, setRemoveAddressLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(AddressActions.getAddressesRequest());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (addresses.length === 0) {
      setFormVisible(true);
    } else {
      setFormVisible(false);
    }

  }, [addresses.length]);

  useEffect(() => {
    setTitle("Checkout / Endereço");
    if (cart.length === 0) {
      history.push("/");
    }
  }, [cart.length, history]);

  function handleSubmit(values, { resetForm }) {
    const {
      nome_contato,
      telefone_contato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge,
    } = values;

    const telefone = telefone_contato.replace(/[^a-zA-Z0-9]/g, "");
    const cepWithoutMask = cep.replace(/[^a-zA-Z0-9]/g, "");

    dispatch(
      AddressActions.addressStoreRequest({
        nome_contato,
        telefone_contato: telefone,
        cep: cepWithoutMask,
        uf,
        cidade,
        bairro,
        numero,
        rua,
        complemento,
        ibge,
      })
    );

    resetForm({
      nome_contato: "",
      telefone_contato: "",
      cep: "",
      uf: "",
      cidade: "",
      bairro: "",
      numero: "",
      rua: "",
      complemento: "",
      ibge: "",
    });
    setFormVisible(!formVisible);
    window.scrollTo(0, 0);
  }

  function handleAddNewAddress() {
    setFormVisible(!formVisible);
    setAddressSelected(null);
  }

  async function handleContinueWithAddressSelected() {
    const address = addresses.find((address) => parseInt(address.id) === parseInt(addressSelected));

    try {
      setContinueLoading(true)
      const { data } = await api.get(
        `/customers/${user.uuid}/address/${address.id}/check-store-delivery${store !== null ? `/${store.uuid}` : ''}`
      );

      const custo_entrega = data.custo_entrega ?? null;

      if (store !== null && custo_entrega !== null) {
        dispatch(StoreByUserActions.setStore({ ...store, custo_entrega }, false))
      }
      localStorage.setItem("@ConexaoGAM:checkout", JSON.stringify({ address }));
      history.push("/checkout/pagamento");
      setContinueLoading(false)
    } catch (err) {
      window.scrollTo(0, 0);
      dispatch(ErrorActions.setError("deliver-address", err.response.data.user));
      setContinueLoading(false)
    }
  }


  async function handleRemoveAddress() {
    setRemoveAddressLoading(true)
    await api.delete(`customers/${user.uuid}/address/${addressToRemove.id}`);
    const addressesUpdated = addresses.filter(
      (address) => address.id !== addressToRemove.id
    );
    dispatch(AddressActions.getAddressesSuccess(addressesUpdated));
    setRemoveAddressLoading(false)
    setModal(false);
  }

  function handleSetAddressModal(address) {
    setAddressToRemove(address);
    setModal(true);
  }

  return (
    <MainLayout history={history}>
      <SubHeader
        withIcon
        withDescription
        withBackButton
        icon={DeliveryTruck}
        title="Endereço de Entrega"
        description="Receba seu pedido em casa"
        backDescription="Voltar"
        uri="/carrinho"
      />

      <Container>
        {(loading || newAddressLoading) && (
          <LoaderContainer>
            <Loader type="Oval" color="#00786A" height={40} width={40} />
          </LoaderContainer>
        )}

        {addresses.length > 0 && !loading && !newAddressLoading && (
          <>
            <AddressList>

              <h1>Selecione um endereço de entrega</h1>

              <Error errorKey="deliver-address" style={{ marginBottom: 20 }} />
              <Wrapper>
                {addresses &&
                  addresses.map((address, index) => (
                    <AddressCard key={index}>
                      <Radio>
                        <div className="radio">
                          <input
                            value={address.id}
                            name="address"
                            type="radio"
                            id={address.id}
                            checked={parseInt(address.id) === parseInt(addressSelected)}
                            onChange={(e) => {
                              setAddressSelected(e.target.value);
                              setFormVisible(false);
                            }}
                          />
                          <label htmlFor={address.id} className="radio-label">
                            {address.nome_contato}
                          </label>
                        </div>
                      </Radio>
                      <p>
                        {address.endereco}, {address.numero}, {address.bairro},{" "}
                        {address.complemento ? address.complemento + "," : null}{" "}
                        {address.cidade.nome}/{address.cidade.uf_sigla}
                      </p>
                      <button onClick={() => handleSetAddressModal(address)}>
                        REMOVER ENDEREÇO
                      </button>
                    </AddressCard>
                  ))}
              </Wrapper>
            </AddressList>
            <NewAddressButton onClick={() => handleAddNewAddress()}>
              Cadastrar novo endereço
            </NewAddressButton>
          </>
        )}

        <AddressForm haveAddresses={addresses.length > 0} visible={formVisible && !loading && !newAddressLoading}>
          <Formik
            initialValues={{
              nome_contato: "",
              telefone_contato: "",
              cep: "",
              uf: "",
              cidade: "",
              bairro: "",
              numero: "",
              rua: "",
              complemento: "",
              ibge: "",
            }}
            validate={(values) => { }}
            validationSchema={AddressSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleReset,
              isSubmitting,
              isValid,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    {errors.nome_contato && touched.nome_contato ? (
                      <FieldErrorMessage>
                        {errors.nome_contato}
                      </FieldErrorMessage>
                    ) : null}

                    <InputField
                      invalid={errors.nome_contato && touched.nome_contato}
                      id="nome_contato"
                      type="text"
                      name="nome_contato"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome_contato}
                      placeholder="Ex: Fulano da Silva"
                    />
                    <Label>Nome para contato*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.telefone_contato && touched.telefone_contato ? (
                      <FieldErrorMessage>
                        {errors.telefone_contato}
                      </FieldErrorMessage>
                    ) : null}

                    <MaskedInput
                      name="telefone_contato"
                      type="text"
                      placeholder="Ex: (__) ____-____"
                      mask={phoneMask}
                      value={values.telefone_contato}
                      invalid={
                        errors.telefone_contato && touched.telefone_contato
                      }
                      id="registerNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      render={(ref, props) => (
                        <InputField ref={(input) => ref(input)} {...props} />
                      )}
                    />

                    <Label>Telefone Contato*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.cep && touched.cep ? (
                      <FieldErrorMessage>{errors.cep}</FieldErrorMessage>
                    ) : null}

                    <MaskedInput
                      invalid={errors.cep && touched.cep}
                      id="cep"
                      type="text"
                      name="cep"
                      mask={cepMask}
                      onChange={handleChange}
                      onKeyUp={async () => {
                        const cep = values.cep.replace(/[^a-zA-Z0-9]/g, "");
                        if (cep.length === 8) {
                          const { data } = await axios.get(
                            `https://viacep.com.br/ws/${values.cep}/json`
                          );
                          setFieldValue("uf", data.uf || "");
                          setFieldValue("cidade", data.localidade || "");
                          setFieldValue("rua", data.logradouro || "");
                          setFieldValue("bairro", data.bairro || "");
                          setFieldValue("ibge", data.ibge || "");
                        }
                      }}
                      value={values.cep}
                      onBlur={handleBlur}
                      placeholder="Ex: _____-___"
                      render={(ref, props) => (
                        <InputField ref={(input) => ref(input)} {...props} />
                      )}
                    />

                    <Label>CEP*</Label>
                  </FormGroup>

                  <div></div>

                  <FormGroup>
                    {errors.uf && touched.uf ? (
                      <FieldErrorMessage>{errors.uf}</FieldErrorMessage>
                    ) : null}

                    <InputField
                      invalid={errors.uf && touched.uf}
                      id="uf"
                      type="text"
                      name="uf"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      value={values.uf}
                      placeholder="Ex: Santa Catarina"
                    />
                    <Label>Estado*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.cidade && touched.cidade ? (
                      <FieldErrorMessage>{errors.cidade}</FieldErrorMessage>
                    ) : null}

                    <InputField
                      disabled
                      invalid={errors.cidade && touched.cidade}
                      id="cidade"
                      type="text"
                      name="cidade"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cidade}
                      placeholder="Ex: Tubarão"
                    />
                    <Label>Cidade*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.bairro && touched.bairro ? (
                      <FieldErrorMessage>{errors.bairro}</FieldErrorMessage>
                    ) : null}

                    <InputField
                      invalid={errors.bairro && touched.bairro}
                      id="bairro"
                      type="text"
                      name="bairro"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bairro}
                      placeholder="Ex: Santo Antonio de Pádua"
                    />
                    <Label>Bairro*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.numero && touched.numero ? (
                      <FieldErrorMessage>{errors.numero}</FieldErrorMessage>
                    ) : null}

                    <InputField
                      invalid={errors.numero && touched.numero}
                      id="numero"
                      type="text"
                      name="numero"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.numero}
                      placeholder="Ex: 1191"
                    />
                    <Label>Número*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.rua && touched.rua ? (
                      <FieldErrorMessage>{errors.rua}</FieldErrorMessage>
                    ) : null}

                    <InputField
                      invalid={errors.rua && touched.rua}
                      id="rua"
                      type="text"
                      name="rua"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rua}
                      placeholder="Ex: Rua/Av. Fulano da Silva"
                    />
                    <Label>Rua/Avenida*</Label>
                  </FormGroup>

                  <FormGroup>
                    {errors.complemento && touched.complemento ? (
                      <FieldErrorMessage>
                        {errors.complemento}
                      </FieldErrorMessage>
                    ) : null}

                    <InputField
                      invalid={errors.complemento && touched.complemento}
                      id="complemento"
                      type="text"
                      name="complemento"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.complemento}
                      placeholder="Ex: Andar/Apartamento (opcional)"
                    />
                    <Label>Complemento</Label>
                  </FormGroup>

                  <div></div>
                  <SubmitBtn type="submit">{newAddressLoading ? "CADASTRANDO NOVO ENDEREÇO..." : "CADASTRAR E CONTINUAR"}</SubmitBtn>
                </Form>
              </>
            )}
          </Formik>
        </AddressForm>
        {addressSelected !== null && (
          <ContinueButton onClick={() => handleContinueWithAddressSelected()}>
            {continueLoading ? "Salvando endereço..." : "Continuar"}
          </ContinueButton>
        )}

        {modal && (
          <RemoveAddressConfirmation>
            <div>
              <div className="content">
                <h1>Excluir endereço</h1>

                <p>Você realmente deseja excluir este endereço?</p>
              </div>

              <div className="footer">
                <button onClick={() => setModal(false)} className="cancel-btn">
                  Cancelar
                </button>
                <button
                  onClick={() => handleRemoveAddress()}
                  className="remove-btn"
                >
                  {removeAddressLoading ? (<>
                    <Loader type="Oval" color="#FFFFFF" height={14} width={14} />
                    Removendo Endereço...
                  </>) : "Sim, desejo"}
                </button>
              </div>
            </div>
          </RemoveAddressConfirmation>
        )}
      </Container>
    </MainLayout>
  );
}
