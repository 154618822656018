import React from "react";

import { Switch, Redirect } from "react-router-dom";
import Route from "./Route";
import Main from "../pages/Main";
import SearchResultProducts from "../pages/SearchResultProducts";
import SearchResultProductsNews from "../pages/SearchResultProductsNews";
import ProductDetail from "../pages/ProductDetail";
import Cart from "../pages/Cart";
import NoMatch from "../pages/NoMatch";
import OrderSuccess from "../pages/OrderSuccess";
import TermsOfUse from "../pages/TermsOfUse";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import ForgotPassword from "../pages/ForgotPassword";
import ChangePassword from "../pages/ChangePassword";
import SelectAddress from "../pages/SelectAddress";
import CheckoutPayment from "../pages/CheckoutPayment";
import AccountMenu from "../components/AccountMenu";
import RewardsProgram from "../pages/RewardsProgram";
import BannerProducts from "../pages/BannerProducts";
import AddressCheckout from "../pages/AddressCheckout";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    <Route path="/produtos/buscar/:term" component={SearchResultProducts} />
    <Route path="/produtos/categoria/:ctg/:ctgId" component={SearchResultProducts} />
    <Route path="/produtos/subcategoria/:subCtg/:subCtgId" component={SearchResultProducts} />

    <Route  path="/lancamento" component={SearchResultProductsNews} />

    <Route path="/produtos/visualizar/:slug" component={ProductDetail} />
    <Route exact path="/checkout/pedido/concluido" component={OrderSuccess} />
    <Redirect exact from="/painel" to={`/painel/meus-dados`} />
    <Route
      isPrivate
      exact
      path="/checkout/selecionar-endereco"
      component={SelectAddress}
    />
    <Route
      isPrivate
      exact
      path="/checkout/pagamento"
      component={CheckoutPayment}
    />
    <Route
        exact
        path="/checkout/entrega"
        component={AddressCheckout}
    />
    <Route isPrivate path="/painel" component={AccountMenu} />
    <Route path="/carrinho" component={Cart} />
    <Route path="/criar-conta" component={SignUp} />
    <Route path="/entrar" component={SignIn} />
    <Route path="/termos" component={TermsOfUse} />
    <Route path="/programa-fidelidade" component={RewardsProgram} />
    <Route path="/esqueci-senha" component={ForgotPassword} />
    <Route path="/alterar-senha/:token" component={ChangePassword} />
    <Route path="/produtos/banner/:uuid" component={BannerProducts} />

    <Route component={NoMatch} />
  </Switch>
);

export default Routes;
