export const Types = {
  SET: "error/SET",
  HIDE: "error/HIDE"
};

const INITIAL_STATE = {
  visible: false,
  key: null,
  message: null
};

export default function error(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET:
      return {
        ...state,
        visible: true,
        message: action.payload.message,
        key: action.payload.key
      };
    case Types.HIDE:
      return { ...state, visible: false, message: null, key: null };
    default:
      return state;
  }
}

export const Creators = {
  setError: (key, message) => ({
    type: Types.SET,
    payload: { message, key }
  }),

  clearError: () => ({
    type: Types.HIDE
  })
};
