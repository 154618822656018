import React, { useEffect, useState } from "react";
import { Container, ContainerMain, ContainerSecundaryImages, ContainerSmallImage } from "./styles";

const GalleryImages = ({ productName, images, className, children }) => {
    const [mainImage, setMainImage] = useState({});
    const [secundaryImages, setSecundaryImages] = useState([]);

    useEffect(() => {
        if (images.length > 0) {
            setMainImage(images[0]);
            setSecundaryImages(images);
        }
    }, [images]);

    const handleSecundaryImageClick = (image) => {
        setMainImage(image);
    };

    return (
        <Container className={className}>
            {
                mainImage && (
                    <ContainerMain>
                        <img
                            src={mainImage.caminho_imagem}
                            alt={`${productName}-0`}
                        />
                        {children}
                    </ContainerMain>
                )
            }
            {
                secundaryImages.length > 0 && (
                    <ContainerSecundaryImages>
                        {
                            secundaryImages.map((image, index) => {
                                return (
                                    <ContainerSmallImage key={`image-${index}`} onClick={() => handleSecundaryImageClick(image)}>
                                        <img
                                            src={image.caminho_imagem}
                                            alt={`${productName}-${index}`}
                                        />
                                    </ContainerSmallImage>

                                )
                            })
                        }

                    </ContainerSecundaryImages>
                )
            }

        </Container>
    );
}

export default GalleryImages;