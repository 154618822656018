import React, { useEffect } from "react";
import RewardsProgramTerm from "../../components/RewardsProgramTerm";
import { useSelector } from "react-redux";
import { Container } from "./styles";
import SubHeader from "../../components/SubHeader";
import { setTitle } from "../../services/browser";
import MainLayout from "../../components/MainLayout";

export default function RewardsProgram({ history }) {
  const { programa_fidelidade_nome } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  useEffect(() => {
    setTitle("Cartão Vantagens");
  }, []);

  return (
    <MainLayout history={history}>
      <SubHeader
        title={programa_fidelidade_nome}
        withDescription
        description="Central de informações"
        withBackButton
      />
      <Container>
        <RewardsProgramTerm />
      </Container>
    </MainLayout>
  );
}
