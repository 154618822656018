import React from "react";
import { useSelector } from "react-redux";
import FooterCompact from "./FooterCompact";
import { Container } from "./styles";
import { Link } from "react-router-dom";

export default function Footer() {
  const { data } = useSelector(state => state.store);

  const { politica_privacidade } = useSelector(state =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  const showNossasMarcas = false;

  return (
    <Container>
      <div className="footer-info">
        <div className="footer-contact">
          <div className="divEq">
            <div className="menus">
              <div className="categorias">
                <strong>Categorias</strong>
                <ul>
                  <li><Link to={'/produtos/categoria/medicamentos/1'}>Medicamentos</Link></li>
                  <li><Link to={'/produtos/categoria/pais-e-filhos/2'}>Pais e Filhos</Link></li>
                  <li><Link to={'/produtos/categoria/cuidados-pessoais/3'}>Cuidados Pessoais</Link></li>
                  <li><Link to={'/produtos/categoria/beleza/4'}>Beleza</Link></li>
                  <li><Link to={'/produtos/categoria/saude/5'}>Saúde</Link></li>
                  <li><Link to={'/produtos/categoria/alimentos/6'}>Alimentos</Link></li>
                </ul>
              </div>
              {showNossasMarcas && (
                <div className="nossas-marcas">
                  <strong>Nossas marcas</strong>
                  <ul>
                    <li><a>Natura</a></li>
                    <li><a>O Boticário</a></li>
                    <li><a>Neutrogena</a></li>
                    <li><a>L’Oréal Paris</a></li>
                    <li><a>Carolina Herrera</a></li>
                  </ul>
                </div>
              )}
              <div className="nossos-termos">
                <strong>Nossos termos</strong>
                <ul>
                  <li>
                    <a href={politica_privacidade}>Política de privacidade</a>
                  </li>
                  <li>
                    <a href={politica_privacidade}>Termos de uso</a>
                  </li>
                  <li>
                    <a href={politica_privacidade}>Política de troca e devolução</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="divDr">
            <div className="contact">
              <strong>Entre em contato conosco</strong>
              <div className="contact-data">
                <div>
                  <p>Central de atendimento</p>
                  <b>{data?.empresa?.sac_telefone}</b>
                </div>
                <div>
                  <p>{data?.empresa?.sac_email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterCompact />
      </div>
    </Container>
  );
}
