import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Container,
  FormTitle,
  Title,
  Error,
  Form,
  SignUp,
  ForgotPassword,
  StyledButtonPrimary,
  StyledLinkLogo
} from "./styles";
import { Creators as AuthActions } from "../../store/ducks/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setTitle } from "../../services/browser";
import InputCustom from "../../components/InputCustom";
import { ToastContainer } from "react-toastify";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Informe um e-mail válido")
    .required("E-mail não pode ser vazio"),
  password: Yup.string().required("Senha não pode ser vazia")
});

export default function SignIn({ history }) {
  const dispatch = useDispatch();
  const error = useSelector(state => state.auth.error);
  const { data } = useSelector(state => state.store);

  useEffect(() => {
    setTitle("Entrar", true)
    dispatch(AuthActions.resetError());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(AuthActions.resetError());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(values, { setSubmitting }) {
    const { email, password } = values;

    const redirectCheckout =
      typeof history.location.state !== "undefined" &&
        history.location.state.previousPage === "/carrinho"
        ? true
        : false;

    dispatch(AuthActions.signInRequest(email, password, redirectCheckout));
    setTimeout(() => {
      setSubmitting(false);
    }, 400);
  }

  return (
    <Container>
      <ToastContainer autoClose={2500} />
      {data && data.empresa && data.empresa.url && (
        <StyledLinkLogo to="/">
          <img
            src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
            title={data?.empresa?.nome}
            alt={`${data?.empresa?.nome}`}
          />
        </StyledLinkLogo>
      )}

      <Formik
        initialValues={{ email: "", password: "" }}
        validate={values => { }}
        onSubmit={handleSubmit}
        validationSchema={SignInSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          isValid,
          handleReset,
          touched,
          isSubmitting
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <FormTitle>
                <Title>Já tenho uma conta</Title>
              </FormTitle>
              {error && <Error>{error}</Error>}
              <InputCustom id="signInEmail" name="email" type="text" description="E-mail" value={values.email} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.email && touched.email} errors={errors.email} />
              <InputCustom id="password" name="password" type="password" description="Senha" value={values.password} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.password && touched.password} errors={errors.password} />
              <ForgotPassword to="/esqueci-senha">
                Esqueci minha senha
              </ForgotPassword>
              <StyledButtonPrimary text="Entrar" disabled={isSubmitting || !isValid} />
              <SignUp>
                Ainda não tem cadastro?
                <Link to="/criar-conta">Criar minha conta</Link>
              </SignUp>
            </Form>
          </>
        )}
      </Formik>
    </Container>
  );
}
