import styled from "styled-components";
import { device } from "../../styles/device";

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 282px;
    min-width: 282px;
    height: 403px;
`;

export const ContainerDatails = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-radius: 0px 0px 8px 8px;
    justify-content: center;
    align-items: center;
    display: none;

    span{
        font-size: 14px;
        @media ${device.laptop} {
            font-size: 16px;
        }
        font-weight: 700;
    }

    &.zero-stock{
        display: flex;
        background-color: ${props => props.theme.colors.warning};

        span{
            color: ${props => props.theme.colors.black};
        }
    }

    &.promo-pague-mais-leve-menos{
        display: flex;
        justify-content: space-around;
        background-color: ${props => props.theme.colors.error};

        & span,
        & span::before{
            font-weight: 500;
            color: ${props => props.theme.colors.white};
            font-size: 11px;
        }

        & span strong{
            font-weight: 700;
            font-size: 12px;
        }

        @media ${device.laptop} {
            & span,
            & span::before{
                font-size: 14px;
            }

            & span strong{
                font-size: 18px;
            }
        }
    }
`;

export const ContainerImage = styled.div`
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 259px;
    min-height: 259px;
    border: 1px solid ${props => props.theme.colors.grayLight};
    border-radius: 8px;
    margin-bottom: 16px;
    background: ${props => props.theme.colors.white};
    padding: 15px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &:hover ${ContainerDatails} {
        .item-sale {
            display: flex;
            background-color: ${props => props.theme.colors.primary};

            span{
                color: ${props => props.theme.colors.white};
            }
        }
    }
`;

export const ContainerPromo = styled.div`
    background-color: ${props => props.theme.colors.error};
    border-radius: 0px 8px 0px 8px;
    padding: 8px 16px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    span{
        font-size: 14px;
        font-weight: 700;
        color: ${props => props.theme.colors.white};
    }
`;


export const ContainerDatailsPromo = styled.div`
    display: flex;
`;

export const ContainerDetailsPromoName = styled.div`
    width: 69px;
    display: inline-flex;

    @media ${device.laptop}{
        width: 87px;
    }
`;

export const ContainerDetailsPromoPrice = styled.div`
    align-self: center;
`;

export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    h3{
      /* min-height: 42px; */
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .old-price{
        font-size: 12px;
        font-weight: 500;
        text-decoration: line-through;
    }

    .price{
        color: var(--neutral-900, #151922);
        font-family: Red Hat Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;

export const ContainerRating = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContainerPriceAndAddCart = styled.div`
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.laptop} {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

export const ContainerPrice = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const ContainerCartOrRemember = styled.div`
    margin-top: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    span{
        margin-left: 4px;
        color: ${props => props.theme.colors.primary};
        font-size: 16px;
    }
`;
