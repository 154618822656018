import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import bag from "../../assets/images/bag.svg";
import { Creators as CartActions } from "../../store/ducks/cart";
import { formatPrice } from "../../util/format";
import { ContainerCard, ContainerCartOrRemember, ContainerDatails, ContainerDetailsPromoName, ContainerDetailsPromoPrice, ContainerImage, ContainerInfo, ContainerPrice, ContainerPriceAndAddCart, ContainerPromo } from "./styles";

const CardSale = ({ product, hasSelectedStore }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { nome, imagens, estoque_loja, estoque_total, maior_desconto, precos, url, leve_mais_pague_menos, preco_a_partir_de_quantidade, pode_vender, controlado } = product;
    const hasStock = (hasSelectedStore && estoque_loja > 0) || (!hasSelectedStore && estoque_total > 0);
    const { loading, productAddedId } = useSelector(state => state.cart);

    const showPriceProduct = (product) => {
        const { preco_de, preco_por } = Object.keys(product.menor_preco_com_programa_fidelidade).length > 0
            ? product.menor_preco_com_programa_fidelidade
            : product.menor_preco_sem_programa_fidelidade;

        return (
            <>
                {preco_de !== preco_por ? (
                    <span className="old-price">{formatPrice(parseFloat(preco_de))}</span>
                ) : null}
                <span className="price">{formatPrice(parseFloat(preco_por))}</span>

            </>
        );
    }

    const viewProductDetails = () => {
        history.push(`/produtos/visualizar/${url}`);
    }

    function addToCart(product) {
        dispatch(CartActions.addProduct(product));
    }

    function actionContainerCartOrRemember() {
        if (hasStock) {
            addToCart(product);
        } else {
            /*Implementação avise-me*/
        }
    }

    return (
        <ContainerCard>
            <ContainerImage onClick={viewProductDetails}>
                <img
                    src={imagens[0].caminho_imagem}
                    alt={nome.toLowerCase()}
                    title={nome.toLowerCase()}
                />
                {hasStock && maior_desconto > 0 && (
                    <ContainerPromo>
                        <span>
                            {precos.length > 1 ? "ATÉ " : ""}
                            {parseFloat(maior_desconto)
                                .toString()
                                .replace(".", ",")}
                            % OFF
                        </span>
                    </ContainerPromo>
                )}
                <ContainerDatails className={hasStock ? (leve_mais_pague_menos ? "promo-pague-mais-leve-menos" : "item-sale") : "zero-stock"}>
                    {hasStock && leve_mais_pague_menos ? (
                        <>
                            <ContainerDetailsPromoName>
                                <span>Compre Mais e pague até</span>
                            </ContainerDetailsPromoName>
                            <ContainerDetailsPromoPrice>
                                <span><strong>{formatPrice(preco_a_partir_de_quantidade)}</strong> cada</span>
                            </ContainerDetailsPromoPrice>
                        </>
                    ) : (
                        <span>{hasStock ? "Detalhes" : "Produto sem estoque!"}</span>
                    )}
                </ContainerDatails>
            </ContainerImage>

            <ContainerInfo>
                <h3>{nome}</h3>
                {pode_vender && hasStock && (
                    <ContainerPriceAndAddCart>
                        <ContainerPrice>
                            {showPriceProduct(product)}
                        </ContainerPrice>
                        {hasStock && !controlado && (
                            <ContainerCartOrRemember onClick={productAddedId === product.id && loading ? undefined : actionContainerCartOrRemember}>
                                <img src={bag} alt={"Icon Bag"} />
                                <span>{productAddedId === product.id && loading ? "Adicionando..." : "Adicionar"}</span>
                                {/* <img src={hasStock ? bag : bell} alt={hasStock ? "Icon Bag" : "Icon Bell"} />
                                <span>{hasStock ? "Adicionar" : "Avise-me"}</span> */}
                            </ContainerCartOrRemember>
                        )}
                    </ContainerPriceAndAddCart>
                )}
            </ContainerInfo>
        </ContainerCard>
    );
};

export default CardSale;
