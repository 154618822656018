import React, { useEffect } from "react";
import { Container, ParagraphContainer } from "./styles";
import SubHeader from "../../components/SubHeader";
import { setTitle } from "../../services/browser";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import MainLayout from "../../components/MainLayout";

export default function TermsOfUse({ history }) {

  const { termos_uso } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  useEffect(() => {
    setTitle("Políticas de Privacidade");
  }, []);

  return (
    <MainLayout history={history}>
      <SubHeader
        title="Nossas Políticas"
        withBackButton
        withDescription
        description="Central de informações"
      />
      <Container>

        <ParagraphContainer>
          {ReactHtmlParser(termos_uso)}
        </ParagraphContainer>

      </Container>
    </MainLayout>
  );
}
