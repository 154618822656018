import styled from "styled-components";

export const Container = styled.div`
  width: calc(100% - 40px);
  max-width: 1200px;
  min-height: calc(100vh - 355px);
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  justify-content: center;
`;

export const FieldErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const FormTitle = styled.header`
  padding: 5px 0 10px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  border: none;
  background: none;
  outline: none;
`;

export const Title = styled.h1`
  font-family: 'Red Hat Display', sans-serif;;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.headingLight};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px;
  max-width: 400px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 3px;
  height: 392px;

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 10px 0;

  &:first-child {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  height: 45px;
  background: #ffffff;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#cfcdcd")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;

  + label {
    color: ${(props) => (props.invalid ? "red" : "#a3a3a1")};
  }

  :focus {
    border: 1px solid
      ${(props) => (props.invalid ? "red" : props.theme.colors.primary)};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${(props) => (props.invalid ? "red" : props.theme.colors.primary)};
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 2px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;
  font-weight: 500;
  margin: 20px auto;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
