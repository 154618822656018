import React, { useEffect } from 'react';

import { Container, Content, ParagraphContainer } from './styles';
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";

function PrivacyPolicyModal({ visible, closeModal }) {

  const { politica_privacidade } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  return (
    <>
      {visible && (
        <Container>
          <Content>
            <button className="close" onClick={() => closeModal()}>
              <MdClose />
            </button>
            <div>
              <ParagraphContainer>
                <iframe src={politica_privacidade}></iframe>
              </ParagraphContainer>
            </div>
          </Content>
        </Container>
      )}
    </>
  );
}

export default PrivacyPolicyModal;
