import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import ReactSVG from "react-svg";
import { Header, HistoryBack, Info } from "./styles";

export default function SubHeader({
  withIcon = false,
  withDescription = false,
  withBackButton = false,
  icon = "",
  description = "",
  backDescription = "Voltar",
  title = "",
  uri = null
}) {
  const history = useHistory();
  function handleBack() {
    if (uri !== null) {
      history.push(uri);
    } else {
      history.goBack();
    }
  }

  return (
    <Header>
      <div>
        <Info>
          {withIcon && icon && <ReactSVG src={icon} />}
          <span>
            <strong>{title}</strong>
            {withDescription && <span>{description}</span>}
          </span>
        </Info>

        {withBackButton && (
          <div id="ordenationContainer">
            <HistoryBack 
              id="btn-back"
              onClick={() => handleBack()}>
              <IoIosArrowBack />
              {backDescription}
            </HistoryBack>
          </div>
        )}
      </div>
    </Header>
  );
}
