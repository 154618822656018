import * as Yup from "yup";
import axios from "axios";

export const AddressSchema = Yup.object().shape({
  nome_contato: Yup.string().required("Nome não pode ser vazio"),
  rua: Yup.string().required("Rua não pode ser vazio"),
  bairro: Yup.string().required("Bairro não pode ser vazio"),
  numero: Yup.number("Deve ser um número")
    .required("Número não pode ser vazio")
    .integer("Número deve ser inteiro")
    .positive("Número não pode ser negativo"),
  complemento: Yup.string(),
  telefone_contato: Yup.string().test(
    "telefone_contato",
    "Telefone incompleto",
    strTelefone => {
      if (strTelefone) {
        const telefone = strTelefone.replace(/[^a-zA-Z0-9]/g, "");
        return telefone.length === 11 || telefone.length === 10;
      }
    }
  ),
  cep: Yup.string()
    .required("CEP não pode ser vazio")
    .test("cep", "CEP incompleto", async strCEP => {
      if (strCEP) {
        const cep = strCEP.replace(/[^a-zA-Z0-9]/g, "");
        return cep.length === 8;
      }
    })
    .test("cep", "O CEP informado é inexistente.", async strCEP => {
      try {
        const cep = strCEP.replace(/[^a-zA-Z0-9]/g, "");
        if (cep.length === 8) {
          const { data } = await axios.get(
            `https://viacep.com.br/ws/${strCEP}/json`
          );
          return data.erro === true ? false : true;
        }
      } catch (err) {
        return false;
      }
    })
});

export const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const cepMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
