import * as Yup from "yup";

export const UpdatePasswordSchema = Yup.object().shape({
  senhaAtual: Yup.string().required("Senha antiga não pode ser vazia"),
  senhaNova: Yup.string().required("Senha nova não pode ser vazia"),
  confirmacaoSenhaNova: Yup.string()
    .required("Confirmar senha não pode ser vazio")
    .oneOf(
      [Yup.ref("senhaNova"), null],
      "A senha nova e confirmação de senha estão diferentes"
    )
});
