import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  overflow-y: auto;
`;

export const Modal = styled.div`
  width: 100%;
  max-width: 800px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  margin-top: 4%;
  padding: 64px 56px;

  div.wrap {
    width: 100%;
    display: flex;
    margin-top: 50px;
    gap: 24px;
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 55px;
    }
  }

  @media (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  button {
    border: none;
    background: none;
    position: absolute;
    right: 42px;
    top: 42px;
  }

  h1 {
    color: var(--neutral-900, #151922);
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: #000;
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px; /* 165.625% */
    margin-top: 20px;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;

  button.btn-confirm {
    display: inline-flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 40px;
    background: var(--primary-500, #143C97);
    border: none;

    :disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  button.btn-clear {
    background: ${props => props.theme.colors.warning};
    box-shadow: 0px 2px 0px ${props => darken(0.05, props.theme.colors.warning)};
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    color: #fff;
    border: none;
    height: 40px;

    :disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button.btn-confirm {
      width: 100%;
      max-width: 100%;
      font-size: 1.4rem;
    }
  }
`;

export const ItemsList = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 29px;

  h2 {
    color: var(--neutral-900, #151922);
    font-family: Red Hat Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  span.none-accepted {
    color: ${props => props.theme.colors.bodyLight};
    font-size: 1.6rem;
    font-weight: 400;
    align-self: center;
    justify-self: center;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 32px);
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  img {
    object-fit: contain;
    width: 53px;
    height: 53px;
    align-self: center;
  }

  div {
    display: flex;
    flex-direction: column;

    button.btn-undo {
      padding: 10px 16px;
      align-items: center;
      border-radius: 24px;
      border: 1px solid var(--alert-error, #728AC0);
      background: #FFF;
      color: var(--alert-error, #728AC0);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:hover{
        background: var(--alert-error, #728AC0);
        color: white;
      }
    }

    strong {
      color: ${props => props.inStock ? darken(0.05, props.theme.colors.bodyLight) : props.theme.colors.bodyLight};
      font-weight: 500;
    }

    span {
      color: ${props => props.inStock ? darken(0.05, props.theme.colors.bodyLight) : props.theme.colors.bodyLight};
      margin-top: 4px;
    }
  }

  div.content-area {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    width: 100%;
    gap: 10px;
  }

  div.text-area {
    display: flex;
    flex-direction: column;
    span{
      color: var(--alert-error, #DE1616);
      font-family: Red Hat Display;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

    }
  }

  div.btn-area {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    width: 100%;
    padding-left: 63px;
    gap: 16px;

    button.btn-transfer {
      color: var(--primary-500, #143C97);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 16px;
      align-items: center;
      border-radius: 24px;
      border: 1px solid var(--primary-500, #143C97);
      background: #FFF;
      &:hover{
        background: var(--primary-500, #143C97);
        color: white;
      }
    }

    button.btn-remove {
      padding: 10px 16px;
      align-items: center;
      border-radius: 24px;
      border: 1px solid var(--alert-error, #DE1616);
      background: #FFF;
      color: var(--alert-error, #DE1616);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:hover{
        background: var(--alert-error, #DE1616);
        color: white;
      }
    }
  }
`;
