import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../services/history";
import { Creators as AddressActions } from "../ducks/address";
import { Creators as ErrorActions } from "../ducks/error";


export function* getAddresses() {

  try {
    const user = yield select((state) => state.user.profile);

    const { data } = yield call(api.get, `/customers/${user.uuid}/address?_limit=100`);

    const orderedData = data.sort((a, b) => {
      if(a.id > b.id) {
        return -1
      } else {
        return true
      }
    })

    yield put(AddressActions.getAddressesSuccess(orderedData));
  } catch (err) {
    yield put(AddressActions.addressStoreFailure());
  }
}

export function* addressStore({ payload }) {
  try {
    const user = yield select((state) => state.user.profile);
    const store = yield select((state) => state.storeByUser.data);

    const { accessToken } = yield select((state) => state.auth);

    api.defaults.headers.Authorization = "Bearer " + accessToken;

    const {
      nome_contato,
      telefone_contato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge,
    } = payload.data;

    const { data } = yield call(api.post, `customers/${user.uuid}/address`, {
      nome_contato,
      telefone_contato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge,
    });


    if (payload.isFromCheckout) {

      try {

        // yield call(api.get, `/customers/${user.uuid}/address/${data.address.id}/check-store-delivery${store !== null ? `/${store.uuid}` : ''}`);

        localStorage.setItem(
          "@ConexaoGAM:checkout",
          JSON.stringify({ address: data.address })
        );

        // history.push("/checkout/pagamento");
        yield put(AddressActions.addressStoreSuccess(data.address));
        return;
      } catch (err) {
        window.scrollTo(0, 0);
        yield put(ErrorActions.setError("deliver-address", err.response.data.user));
        return;
      }
    }

    yield put(AddressActions.addressStoreSuccess(data.address));

    history.push("/painel/enderecos");
  } catch (err) {
    yield put(AddressActions.addressStoreFailure());
    yield put(ErrorActions.setError("create-update-address"));
  }
}

export function* addressUpdate({ payload }) {
  try {
    const user = yield select((state) => state.user.profile);

    const {
      nomeContato,
      telefoneContato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge,
      addressId,
    } = payload.data;

    const { data } = yield call(
      api.put,
      `customers/${user.uuid}/address/${addressId}`,
      {
        nomeContato,
        telefoneContato,
        cep,
        uf,
        cidade,
        bairro,
        numero,
        rua,
        complemento,
        ibge,
      }
    );

    yield put(AddressActions.addressUpdateSuccess());

    if (payload.isFromCheckout) {
      localStorage.setItem(
        "@ConexaoGAM:checkout",
        JSON.stringify({ address: data.address })
      );
      history.push("/checkout/pagamento");
      return;
    }
    history.push("/painel/enderecos");
  } catch (err) {
    yield put(ErrorActions.setError("create-update-address", err));
    yield put(AddressActions.addressUpdateFailure());
  }
}
