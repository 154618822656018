import produce from "immer";

export const SideMenuTypes = {
  TOGGLE_SIDE_MENU: "@sideMenu/TOGGLE_SIDE_MENU"
};

const INITIAL_STATE = {
  isOpen: false
};

export default function sideMenu(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SideMenuTypes.TOGGLE_SIDE_MENU: {
        if (action.payload.who !== "openWithButton" && draft.isOpen) {
          draft.isOpen = false;
        } else {
          draft.isOpen = true;
        }
        break;
      }
      default:
    }
  });
}

export const Creators = {
  toggleSideMenu: (who = "other") => ({
    type: SideMenuTypes.TOGGLE_SIDE_MENU,
    payload: { who }
  })
};
