import React from "react";
import { Container, ContainerInfoUnits, FirstColumn, SecondColumn } from "./styles";

import CartaoVantagens from "../../assets/images/cartao-vantagens.png";
import { formatPrice } from "../../util/format";

const CardSpecialPriceProduct = ({ product, key }) => {
    const { quantidade_minima, preco_por } = product;
    return (
        <Container key={key}>
            <FirstColumn>
                <ContainerInfoUnits>
                    <span><b>A partir de</b></span>
                    <br />
                    <span><b>{quantidade_minima} unidade(s)</b></span>
                </ContainerInfoUnits>
                {product.programa_fidelidade && (
                    <img
                        alt="Cartão Vantagem"
                        src={CartaoVantagens}
                    />
                )}
            </FirstColumn>
            <SecondColumn>
                <span><b>Pague</b> </span>
                <br />
                <span><b>{formatPrice(preco_por)}/cada</b></span>
            </SecondColumn>
        </Container>
    );
}

export default CardSpecialPriceProduct;