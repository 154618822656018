import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 0;
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-top: 40px;

  span {
    color: var(--neutral-900, #151922);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px; /* 165.625% */
  }
`;

export const Content = styled.div`
  position: absolute;
  width: 674px;
  min-height: 287px;

  position: absolute;
  margin-top: 4%;
  margin-bottom: 4%;

  border-radius: 16px;
  background: #FFF;
  padding: 56px;

  @media (max-width: 768px) {
    position:fixed;
    margin:0;
    top:0;
    left: 0;
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
  }

  .all-stores{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;
    cursor: pointer;

    span{
      color: var(--primary-500, #143C97);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 78px;
  align-items: center;

  h1 {
    color: var(--neutral-900, #151922);
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  button.close {
    position:absolute;
    background: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    right: 50px;
    top: 50px;
    @media (max-width: 768px) {
      right: 25px;
      top: 25px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-evenly;
  gap: 16px;

  button.btn-search {
    color: #fff;
    border: none;
    width: 117px;
    justify-content: center;
    border-radius: 3px;
    align-items: center;

    display: inline-flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: var(--primary-500, #143C97);

    :hover {
      background: ${props => darken(0.05, props.theme.colors.primary)};
    }

    svg {
      font-size: 1.8rem;
      margin-left: 4px;
    }

    @media (max-width: 768px) {
      position:fixed;
      margin:16px;
      bottom:10px;
      left: 0;
      border-radius: 0;
      width: calc(100vw - 32px);
      border-radius: 40px;
    }
  }
`;

export const InputContainer = styled.div`
  height: 53px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--neutral-200, #D0D1D3);
  background: #FFF;
  padding: 13px 24px 13px 24px;
  width: 100%;



  .mobile {
    display: none !important;
  }

  .desktop {
    display: flex;
  }

  input {
    background: transparent;
    height: 60px;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding-left: 16px;
    color: var(--neutral-600, #72757A);
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px; /* 165.625% */

    ::placeholder {
      color: #adadad;
    }
  }

  @media (max-width: 600px) {

    button.btn-search {
      width: 60px;
      height: 40px;

      svg {
        margin: 0;
        font-size: 2.5rem;
      }
    }

    button.mobile {
      display: flex !important;
    }

    button.desktop {
      display: none !important;
    }
  }
`;
export const StoreContainer = styled.div`
  @media (max-width: 768px) {
    max-height: calc(100vh - 355px);
    overflow: scroll;
  }
`;
export const StoreList = styled.ul`
  display: grid;
  max-width: 0 700px;
  margin: auto;
  list-style: none;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;

  li {
    border-radius: 8px;
    border: 1px solid var(--neutral-100, #E8E8E9);
    background: #FFF;
    padding: 24px;

    :hover {
      background: #fafafa;
      cursor: pointer;
    }

    @media (max-width: 640px) {
      width: 100%;
      max-width: 100%;
    }

    img {
      margin-right: 8px;
    }

    div {
      display: flex;
      flex-direction: column;

      strong {
        color: var(--neutral-900, #151922);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 8px;
      }

      span {
        line-height: 20px;
        font-weight: 300;
        font-size: 1.4rem;
        color: #a7a7a7;
      }
    }
  }


`;
