import { all, takeLatest, takeEvery } from "redux-saga/effects";

import { Types as BannersTypes } from "../ducks/banners";
import { Types as ProductsTypes } from "../ducks/products";
import { Types as CartTypes } from "../ducks/cart";
import { Types as AuthTypes } from "../ducks/auth";
import { Types as SignUpTypes } from "../ducks/signup";
import { Types as SearchProductsTypes } from "../ducks/search";
import { Types as PasswordTypes } from "../ducks/password";
import { Types as SuggestTypes } from "../ducks/suggest";
import { Types as StoreTypes } from "../ducks/store";
import { Types as AddressTypes } from "../ducks/address";
import { Types as OrderTypes } from "../ducks/order";
import { Types as MyOrdersTypes } from "../ducks/myOrders";
import { Types as StoreByUserTypes } from "../ducks/storeByUser";
import { Types as DomainTypes } from "../ducks/domain";

import { getBanners, getBannersProducts } from "./banners";
import { getMyOrdersRequest } from "./myOrders";
import { updateUser, getAccount, removeUser } from "./user";
import { finishOrder } from "./order";
import {
  getProducts,
  getProductBySlug,
  resetQuantityProducts,
  getNewsProduct
} from "./products";
import { getProductsBySearchTerm } from "./search";
import { getSuggestions, clearSuggestions } from "./suggest";
import { signUp } from "./signup";
import {
  recoverPassword,
  newPassword,
  verifyToken,
  updatePassword
} from "./password";

import {
  addProductBasket,
  setCartInState,
  removeProductCart,
  setOrderStorage,
  getOrderAndCartFromStorage,
  reorganizeBasket,
  checkOutOfStockProductsByStore,
  deleteOutOfStockItemsFromCart,
  createCartOnUserSign,
  removeProductsFromCart,
  getProductListRequest,
  addProductListBasket
} from "./cart";

import { signOut, signIn } from "./auth";
import { getStore } from "./store";
import { addressStore, addressUpdate, getAddresses } from "./address";
import { UserTypes } from "../ducks/user";
import { getStoreByDomain } from "./domain";

export default function* rootSaga() {
  yield all([
    takeLatest(BannersTypes.GET_REQUEST, getBanners),
    takeLatest(ProductsTypes.GET_REQUEST, getProducts),
    takeLatest(ProductsTypes.GET_REQUEST_NEW_PRODUCTS, getNewsProduct),
    takeLatest(ProductsTypes.GET_ALREADY_REQUESTED_PRODUCTS, getNewsProduct),
    takeLatest(ProductsTypes.SHOW_REQUEST, getProductBySlug),
    // takeLatest(
    //   SearchProductsTypes.SEARCH_PRODUCTS_REQUEST,
    //   getProductsBySearchTerm
    // ),
    takeLatest(SearchProductsTypes.LOAD_MORE_REQUEST, getProductsBySearchTerm),
    takeLatest(SearchProductsTypes.SET_FILTER_TERMS, getProductsBySearchTerm),
    takeEvery(SearchProductsTypes.SET_SEARCH_TERM, getSuggestions),
    takeEvery(SearchProductsTypes.SEARCH_PRODUCTS_SUCCESS, clearSuggestions),
    takeLatest(SuggestTypes.GET_REQUEST, getSuggestions),
    takeLatest(CartTypes.ADD_PRODUCT, addProductBasket),
    takeLatest(CartTypes.ADD_PRODUCT_LIST, addProductListBasket),
    takeLatest(CartTypes.REMOVE_PRODUCT, removeProductCart),
    takeLatest(CartTypes.GET_STORAGE_CART, setCartInState),
    takeLatest(CartTypes.SET_ORDER_STORAGE, setOrderStorage),
    takeLatest(CartTypes.GET_SELECTED_REQUEST, getProductListRequest),
    takeLatest(CartTypes.GET_ORDER_CART_STORAGE, getOrderAndCartFromStorage),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    takeLatest(AuthTypes.SIGN_OUT_SUCCESS, reorganizeBasket),
    takeLatest(UserTypes.UPDATE_REWARDS_PROGRAM, reorganizeBasket),
    takeLatest(SignUpTypes.SIGN_UP_REQUEST, signUp),
    takeLatest(PasswordTypes.RECOVER_PASSWORD_REQUEST, recoverPassword),
    takeLatest(PasswordTypes.NEW_PASSWORD_REQUEST, newPassword),
    takeLatest(PasswordTypes.VERIFY_TOKEN_REQUEST, verifyToken),
    takeLatest(AddressTypes.ADDRESS_REQUEST, addressStore),
    takeLatest(AddressTypes.ADDRESS_UPDATE_REQUEST, addressUpdate),
    takeLatest(StoreTypes.GET_REQUEST, getStore),
    takeLatest(OrderTypes.ORDER_REQUEST, finishOrder),
    takeLatest(MyOrdersTypes.GET_REQUEST, getMyOrdersRequest),
    takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(UserTypes.REMOVE_USER, removeUser),
    takeLatest(PasswordTypes.UPDATE_PASSWORD_REQUEST, updatePassword),
    takeLatest(UserTypes.GET_ACCOUNT_REQUEST, getAccount),
    takeLatest(CartTypes.CALCULATE_AMOUNT, reorganizeBasket),
    takeLatest(CartTypes.REMOVE_PRODUCTS_CART, removeProductsFromCart),
    takeLatest(AddressTypes.GET_ADDRESSES_REQUEST, getAddresses),
    takeLatest(AuthTypes.SIGN_IN_SUCCESS, createCartOnUserSign),
    takeLatest(AuthTypes.SIGN_OUT_SUCCESS, resetQuantityProducts),
    takeLatest(BannersTypes.GET_BANNER_PRODUCTS_REQUEST, getBannersProducts),
    takeLatest(
      StoreByUserTypes.CHECK_ITEMS_OUT_OF_STOCK,
      checkOutOfStockProductsByStore
    ),
    takeLatest(
      StoreByUserTypes.DELETE_OUT_OF_STOCK_ITEMS,
      deleteOutOfStockItemsFromCart
    ),
    takeLatest(OrderTypes.CONFIRM_ORDER_REQUEST, finishOrder),
    takeLatest(DomainTypes.GET_DOMAIN_REQUEST, getStoreByDomain)
  ]);
}
