import styled from 'styled-components';
import { device } from "../../styles/device";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ContainerMain = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.grayLight};
    position: relative;

    img {
       object-fit: contain;
       width: 100%;
       height: 202px;
       @media ${device.laptop} {
            height: 497px;
            width: 690px;
        }
       
    }
`;

export const ContainerSecundaryImages = styled.div`
    align-self: flex-start;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const ContainerSmallImage = styled.div`
    border: 1px solid ${props => props.theme.colors.grayLight};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    @media ${device.laptop} {
        height: 78px;
        width: 78px;
    }
    cursor: pointer;

    img {
        object-fit: contain;
        max-width: 59px;
        max-height: 59px;
        @media ${device.laptop} {
            max-width: 73px;
            max-height: 73px;
        }
    }
`;
