import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 355px);
  padding: 0 40px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  min-height: 100vh;
  min-height: calc(100vh - 355px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const NewAddressButton = styled.button`
  width: 330px;

  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.secondary};
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const AddressList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    color: ${(props) => props.theme.colors.headingLight};
    font-weight: 500;
    padding: 16px 0;
    font-family: 'Red Hat Display', sans-serif;;
    font-size: 2.2rem;
  }

  @media (max-width: 760px) {
    h1 {
      line-height: 20px;
      padding: 16px 0;
    }
  }
`;

export const AddressCard = styled.div`
  padding: 30px;
  min-width: 280px;
  width: 330px;
  background: #fff;
  border-radius: 5px;
  min-height: 160px;
  margin-right: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;

  @media (max-width: 760px) {
    margin-right: 0;
    width: 100%;
  }

  @media (max-width: 420px) {
    padding: 20px;
  }

  p {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.bodyLight};
  }

  button {
    padding: 5px 15px;
    font-weight: 400;
    font-family: 'Red Hat Display', sans-serif;;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-radius: 4px;
    background: #fff;
    margin-top: 10px;
    font-size: 1.2rem;

    :active {
      background: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.bodyDark};
      font-weight: 400;
    }
  }
`;

export const RemoveAddressConfirmation = styled.div`
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 20000;
  background: rgba(0, 0, 0, 0.1);

  > div {
    width: 500px;
    height: 180px;
    margin: 0 10px;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.07);

    .content {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      h1 {
        color: ${(props) => props.theme.colors.headingLight};
        padding: 10px 30px;
        font-size: 2.2rem;
        font-weight: 400;
        width: 100%;
      }

      p {
        color: ${(props) => props.theme.colors.bodyLight};
        padding: 10px 30px;
        font-size: 1.8rem;
        font-weight: 300;
        width: 100%;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 70px;
      border-radius: 0 0 4px 4px;
      background: #fff;
      border-top: ${(props) => props.theme.colors.primary};

      .remove-btn {
        border: none;
        height: 40px;
        min-height: 40px;
        font-weight: 500;
        background: ${(props) => props.theme.colors.warning};
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.bodyDark};
        padding: 5px 10px;
        border-radius: 4px;
        margin-right: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          margin-top: 3px;
          margin-right: 12px;
        }

        :active {
          background: ${(props) => darken(0.1, props.theme.colors.warning)};
        }
      }

      .cancel-btn {
        border: none;
        height: 40px;
        font-weight: 500;
        background: transparent;
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.primary};
        padding: 5px 10px;
        border: 1px solid ${(props) => props.theme.colors.primary};
        border-radius: 4px;

        :active {
          background: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.bodyDark};
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const Radio = styled.div`
  display: flex;
  font-size: 1.6rem;

  .radio {
    margin: 0.5rem 0.5rem 0.5rem 0rem;
    + .radio {
      margin: 0.5rem;
    }
    label {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 500;
    }
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: "";
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid ${(props) => props.theme.colors.primary};
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.2em;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: ${(props) => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${(props) => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.headingLight};
`;

export const InputField = styled.input`
  height: 45px;
  background: #ffffff;
  border: 1px solid
    ${(props) => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;

  + label {
    color: ${(props) =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.bodyLight};
  }

  :focus {
    border: 1px solid
      ${(props) =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${(props) =>
    props.invalid
      ? (props) => props.theme.colors.warning
      : (props) => props.theme.colors.primary};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.bodyLight};
  }

  :disabled {
    background: #f1f1f1;
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const AddressForm = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  width: 100%;
  margin-bottom: 80px;
  margin-top: ${props => props.haveAddresses ? '0' : '32px'};

  h1 {
    color: ${(props) => props.theme.colors.bodyLight};
    font-weight: 500;
    line-height: 80px;
    font-family: 'Red Hat Display', sans-serif;;
  }

  @media (max-width: 760px) {
    h1 {
      line-height: 20px;
      padding: 20px 0;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 900px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

export const SubmitBtn = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  background: none;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  order: 2;

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 760px) {
    margin-top: 0;
  }
`;

export const ContinueButton = styled.button`
  width: 330px;
  margin-top: 30px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 760px) {
    margin-top: 0;
    width: 100%;
  }
`;
