import React, { useEffect } from 'react';

import { Container, Modal, Header, Content, Footer } from './styles';
import { FiX } from "react-icons/fi";
import { Creators as OrderActions } from "../../store/ducks/order";
import { MdStore, MdShoppingBasket, MdTimer, MdLocalShipping } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import { formatPrice } from "../../util/format";
import Loader from "react-loader-spinner";
import DeliveryStoreClosed from './DeliveryStoreClosed';

function ShowDeliveryStoreModal() {
  const dispatch = useDispatch();
  const { showDeliveryStore, deliveryStoreData, checkoutData, loading, deliveryStoreDataHour } = useSelector(state => state.order);
  const { amount } = useSelector(state => state.cart);
  const { dadosEntrega } = JSON.parse(localStorage.getItem("@ConexaoGAM:checkout")) || {};

  function finishOrder() {
    dispatch(OrderActions.orderRequest({ ...checkoutData }, true));
  }

  function closeModal() {
    dispatch(OrderActions.hideDeliveryStoreModal());
  }

  useEffect(() => {
    return () => {
      dispatch(OrderActions.hideDeliveryStoreModal());
    }
  }, [dispatch])

  return (
    <>
      {(showDeliveryStore && dadosEntrega.tipoEntrega === 'RECEBER_EM_CASA' && ((deliveryStoreDataHour?.abertoAgora && deliveryStoreDataHour?.abertoHoje) || (!deliveryStoreDataHour)))  ? (
        <Container>
          <Modal>
            <Header>
              <h1>Loja selecionada para entrega</h1>
              <FiX size={35} style={{cursor: 'pointer'}} onClick={() => closeModal()} />
            </Header>

            <Content>
              <ul>
                <li>
                  <div className="card-icon-bg">
                    <MdStore size={35} />
                  </div>

                  <div className="card-info">
                    <span className="card-title">Farmácia Selecionada para Entrega</span>
                    <span className="card-description">
                      <strong>{deliveryStoreData.nome_fantasia}</strong>
                       | {deliveryStoreData.endereco} {deliveryStoreData.endereco_numero},
                       {deliveryStoreData.cidade} - {deliveryStoreData.uf}
                    </span>
                  </div>
                </li>

                <li>
                  <div className="card-icon-bg">
                    <MdShoppingBasket size={25} />
                  </div>

                  <div className="card-info">
                    <span className="card-title">Valor Total do Pedido</span>
                    <span className="card-description">
                      {formatPrice(amount)}
                    </span>
                  </div>
                </li>

                <li>
                  <div className="card-icon-bg">
                    <MdLocalShipping size={30} />
                  </div>

                  <div className="card-info">
                    <span className="card-title">Custo Entrega</span>
                    <span className="card-description">
                      {formatPrice(deliveryStoreData.local_entrega.custo_entrega)}
                    </span>
                  </div>
                </li>

                <li>
                  <div className="card-icon-bg">
                    <MdTimer size={30} />
                  </div>

                  <div className="card-info">
                    <span className="card-title">Tempo de Entrega</span>
                    <span className="card-description">
                      {deliveryStoreData.local_entrega.tempo_entrega}
                    </span>
                  </div>
                </li>
              </ul>

            </Content>

            <Footer>
              <div className="order-amount">
                <span className="order-amount-label">Total Pedido:</span>
                <strong className="order-amount-value">{formatPrice(parseFloat(deliveryStoreData.local_entrega.custo_entrega) + amount)}</strong>
              </div>

              <button onClick={() => finishOrder()}>{loading ? (<><Loader type="Oval" color="#FFFFFF" height={16} width={16} /> FINALIZANDO PEDIDO...</>) : "FINALIZAR PEDIDO" }</button>
            </Footer>
          </Modal>
        </Container>
      ): (showDeliveryStore && dadosEntrega.tipoEntrega === 'RECEBER_EM_CASA') && (
        <DeliveryStoreClosed />
      )}
    </>
  );
}

export default ShowDeliveryStoreModal;
