import React, { useEffect, useState } from 'react';
import expandLeft from '../../assets/images/expand-left.svg';
import expandRight from '../../assets/images/expand-right.svg';
import { Container, ContainerCircle } from './styles';

const Pagination = ({ totalItens, itensByPage, loadMore, show }) => {
  const totalPages = Math.ceil(totalItens / itensByPage);
  const limit = totalPages < 5 ? totalPages : 5;
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pages, setPages] = useState([]);
  const [pageInit, setPageInit] = useState(1);
  const [pageEnd, setPageEnd] = useState(limit);

  useEffect(() => {
    handleComponentPages(1);
  }, [totalItens]);

  const handleComponentPages = (page) => {
    setPageCurrent(page);
    const newPages = buildComponentPages(page);
    setPages(newPages);
  }


  const buildComponentPages = (page) => {

    if (page > 3) {
      if (totalPages >= page + 2) {
        setPageInit(page - 2);
        setPageEnd(page + 2);
      }
    } else {
      setPageInit(1);
      setPageEnd(limit);
    }

    const pages = [];

    for (let index = pageInit; index <= pageEnd; index++) {
      pages.push(index);
    }

    return pages;
  }

  const handlePage = (page) => {
    if (page !== pageCurrent) {
      handleComponentPages(page);
      if (loadMore) {
        loadMore((page - 1) * itensByPage, itensByPage);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }

  }

  const expandLeftIsDisabled = (pageCurrent) => {
    return !(pageCurrent > 1);
  }

  const handleExpandLeft = () => {
    if (!expandLeftIsDisabled(pageCurrent)) {
      handlePage(pageCurrent - 1)
    }
  }

  const expandRightIsDisabled = (pageCurrent) => {
    return !(pages.length > 0 && pageCurrent < totalPages);
  }

  const handleExpandRight = () => {
    if (!expandRightIsDisabled(pageCurrent)) {
      handlePage(pageCurrent + 1)
    }
  }

  const pageIsSelected = (page) => {
    return page === pageCurrent;
  }

  return (
    <Container show={show}>
      <ContainerCircle aria-disabled={expandLeftIsDisabled(pageCurrent)} onClick={handleExpandLeft}>
        <img src={expandLeft} alt='Expand left' />
      </ContainerCircle>
      {pages?.map((page) => {
        return (
          <ContainerCircle aria-selected={pageIsSelected(page)} key={page} onClick={() => {
            handlePage(page)
          }}>
            <span>{page}</span>
          </ContainerCircle>
        )
      })}


      <ContainerCircle aria-disabled={expandRightIsDisabled(pageCurrent)} onClick={handleExpandRight}>
        <img src={expandRight} alt='Expand right' />
      </ContainerCircle>
    </Container>
  );
}

export default Pagination;
