import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Overlay, Content } from "./styles";
import { FiX } from "react-icons/fi";
import { Creators as CartActions } from "../../store/ducks/cart";
import { formatPrice } from "../../util/format";
import isEmpty from "lodash/isEmpty";

export default function SpecialPricesModal({ visible }) {
  const dispatch = useDispatch();
  const { product } = useSelector(state => state.cart);
  const { data } = useSelector(state => state.store);

  return (
    <>
      {visible && (
        <Overlay>
          <Content>
            <button
              onClick={() => dispatch(CartActions.toggleProductConditions({}))}
            >
              <FiX />
            </button>

            <table>
              <thead>
                <tr>
                  <th>Quantidade</th>
                  <th>Desconto (%)</th>
                  <th>Valor (R$)</th>
                </tr>
              </thead>

              <tbody>
                {product.preco_quantidade_multipla.length > 0 && (
                  product.preco_quantidade_multipla.map(preco => (
                    <tr>
                      <td>
                        A cada {preco.quantidade_multipla} {" "}
                        un.
                      </td>
                      <td className="discount">
                        {parseFloat(
                          preco.percentual_desconto
                        ).toLocaleString("pt-BR")}
                        %
                        {preco.programa_fidelidade && (<img src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/cartaovantagem/cartao-vantagem.png`} alt="cartao-vantagens" title="cartao vantagens" />)}
                      </td>
                      <td>
                        {formatPrice(
                          parseFloat(
                            preco.preco_por
                          )
                        )}
                      </td>
                    </tr>
                  ))
                )}
                 {product.preco_quantidade_minima.length > 0 && (
                  product.preco_quantidade_minima.map(preco => (
                    <tr>
                      <td>
                        A partir de {preco.quantidade_minima} {" "}
                        un.
                      </td>
                      <td className="discount">
                        {parseFloat(
                          preco.percentual_desconto
                        ).toLocaleString("pt-BR")}
                        %
                        {preco.programa_fidelidade && (<img src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/cartaovantagem/cartao-vantagem.png`} alt="cartao-vantagens" title="cartao vantagens" />)}
                      </td>
                      <td>
                        {formatPrice(
                          parseFloat(
                            preco.preco_por
                          )
                        )}
                      </td>
                    </tr>
                  ))
                )}
                {!isEmpty(product.preco_programa_fidelidade) && (
                  <tr>
                    <td>
                      1
                      un.
                    </td>
                    <td className="discount">
                      {parseFloat(
                        product.preco_programa_fidelidade
                          .percentual_desconto
                      ).toLocaleString("pt-BR")}
                      %
                      <img src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/cartaovantagem/cartao-vantagem.png`} alt="cartao-vantagens" title="cartao vantagens" />
                    </td>
                    <td>
                      {formatPrice(
                        parseFloat(
                          product.preco_programa_fidelidade.preco_por
                        )
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Content>
        </Overlay>
      )}
    </>
  );
}
