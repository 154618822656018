import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: calc(100vh - 355px);
  display: flex;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  margin-top: 30px;
  display: flex;
  min-height: 450px;
  background: #fff;
  width: calc(100% - 40px);
  max-width: 800px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  @media (min-width: 600px) {
    height: 450px;
  }

  @media (max-width: 500px) {
    padding: 15px;

    > .form-container {
      padding: 15px;
    }
  }

  > .form-container {
    padding: 20px;
    width: 100%;
  }

  @media (max-width: 350px) {
    .form-container {
      padding: 0;
    }
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  font-size: 1.6rem;
  height: 45px;
  align-items: center;

  .radio {
    margin: 0.5rem;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: "";
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid ${props => props.theme.colors.primary};
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.2em;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: ${props => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const Column = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContainer = styled.form`
  width: 100%;
  max-width: 800px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const InputField = styled.input`
  height: 45px;
  background: #ffffff;
  border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;

  + label {
    color: ${props => (props.invalid ? props.theme.colors.warning : "#a3a3a1")};
  }

  :focus {
    border: 1px solid
      ${props =>
        props.invalid
          ? props.theme.colors.warning
          : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${props =>
      props.invalid ? props.theme.colors.warning : props.theme.colors.primary};
  }

  :disabled {
    opacity: 0.5;
  }

  ::placeholder {
    color: ${props => props.theme.colors.bodyLight};
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.bodyDark};
  background: ${props => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 2px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;
  font-weight: 500;
  margin-top: 30px;

  &:hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.7;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    margin-top: 0;
  }
`;
