import React from "react";
import { Container, Label } from "./styles";
export default function HeaderPromotion({ visible }) {
  if (!visible) return null;
  return (
    <Container>
      <Label className="">
        <span className="mobile">5% off nas compras feitas por Pix</span>
        <span className="desktop">5% off nas compras feitas por Pix ou 2x sem juros em toda loja!</span>
      </Label>
    </Container>
  );
};

