import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  overflow-y: auto;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100%;
  max-width: calc(800px - 40px);
  min-height: 300px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  margin-top: 4%;
  margin-bottom: 4%;

  @media (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  max-width: calc(100% - 64px);
  margin: 32px auto;

  h1 {
    font-size: 2rem;
    color: ${props => props.theme.colors.headingLight};
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    font-size: 1.6rem;
    color: ${props => props.theme.colors.bodyLight};
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 32px);
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  max-width: calc(100% - 64px);
  margin: 32px auto;
  display: flex;

  button.btn-automatic {
    background: ${props => props.theme.colors.primary};
    box-shadow: 0px 2px 0px ${props => darken(0.05, props.theme.colors.primary)};
    border-radius: 3px;
    width: 100%;
    max-width: 414px;
    color: #fff;
    border: none;
    height: 40px;
  }

  button.btn-delete {
    background: #f36f21;
    box-shadow: 0px 2px 0px #cc5c1a;
    border-radius: 3px;
    border: none;
    height: 40px;
    width: 100%;
    max-width: 156px;
    color: #fff;
    margin-left: 16px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    max-width: calc(100% - 32px);

    button.btn-automatic {
      width: 100%;
      max-width: 100%;
      font-size: 1.4rem;
    }

    button.btn-delete {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: 16px;
      font-size: 1.4rem;
    }
  }
`;

export const ItemsList = styled.ul`
  width: 100%;
  max-width: calc(100% - 64px);
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 16px;

  @media (max-width: 600px) {
    max-width: calc(100% - 32px);
  }
`;

export const Item = styled.li`
  display: flex;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-height: 70px;
  align-items: center;
  cursor: pointer;

  img {
    object-fit: contain;
    width: 53px;
    height: 53px;
    margin: 8px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    strong {
      color: ${props => props.theme.colors.headingLight};
      font-weight: 500;
    }

    span {
      color: ${props => props.theme.colors.bodyLight};
      margin-top: 4px;
    }
  }
`;
