import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { formatPrice } from "../../util/format";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
    ItemsList,
    Item
  } from "./styles";

const CollapseList = () => {
    const [collapsed, setIsCollapsed] = useState(true);
    
    const {
        showProductConditions,
        data: basket,
        amount,
     } = useSelector((state) => state.cart);

    const [frontBasket, setFrontBasket] = useState([]);
    
    useEffect(() => {
        setFrontBasket(basket);
    }, [basket]);

    function handleCollapse() {
        setIsCollapsed(!collapsed);
    }

    return (
        
        <ItemsList onClick={handleCollapse} style={{cursor: "pointer"}}>
            <div>
            <div style={{paddingBottom: collapsed ? "0" : "20px"}}>
                Meus produtos
                <IoIosArrowDown style={{display: collapsed ? "block" : "none", float: "right"}} />
                <IoIosArrowUp style={{display: collapsed ? "none" : "block", float: "right"}} />
            </div>
            {!collapsed && frontBasket.map(item => (
                  <Item key={item.id}>
                  <div className="content-area">
                    <img
                      src={item.imagens[0].caminho_imagem}
                      alt={item.nome}
                    />
                    <div className="text-area">
                        <strong>{item.nome}</strong>
                        {(item.preco_mostrar_cesta.preco_de !== item.preco_mostrar_cesta.preco_por) && (
                        <>
                            <span className="oldValue">De: {formatPrice(item.preco_mostrar_cesta?.preco_de)}</span>
                            <span className="value">Por: {formatPrice(item.preco_mostrar_cesta?.preco_por)}</span>
                        </>
                        )}
                        {(item.preco_mostrar_cesta.preco_de === item.preco_mostrar_cesta.preco_por) && (
                        <>
                            <span className="value">{formatPrice(item.preco_mostrar_cesta?.preco_por)}</span>
                        </>
                        )}
                    </div>
                  </div>
                </Item>
              ))}
                </div>
        </ItemsList>        
    );
};

export default CollapseList;