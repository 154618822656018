import React from "react";
import cookie from '../../assets/images/cookies.svg';
import ButtonPrimary from "../ButtonPrimary";
import { Container, Content, Overlay } from "./styles";


export default function Cookies() {
  return (
    <>
      <Overlay />
      <Container>
        <img src={cookie} alt={"Cookies"} title={"Cookies"} />
        <Content>
          <p>
            Durante sua navegação, podemos utilizar cookies para: confirmar sua identidade; personalizar seu acesso;
            e acompanhar a utilização de nossos websites, visando o aprimoramento de sua funcionalidade. Alguns cookies são essenciais para nossos serviços, outros opcionais. Você poderá gerenciar os cookies que utilizamos de acordo com suas preferências.
            <a href="/termos">Gerenciar cookies.</a>.
          </p>
        </Content>

        <ButtonPrimary text="Aceitar todos cookies" onClick={() => {
          localStorage.setItem("@ConexaoGAM:Cookies", true);
          window.location.reload()
        }} />
      </Container>
    </>
  );
}
