import styled from "styled-components";


export const Container = styled.div`
  max-width: 996px;
  width: 100%;
  margin: 0 auto;
  background: #FDFDFD;
  font-size: 14px;
  margin-top: 30px;
  padding-bottom: 64px;
  .categories{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: baseline;

    height: 100%;
    gap: 40px;
    /* padding-bottom: 5px; */
    overflow-x: hidden;


    a{
      color: var(--neutral-800, #44474E);
      text-align: center;
      /* Paragraph 1 */
      font-family: Red Hat Display;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.5px; /* 165.625% */
      line-break: loose;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .category{
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 80px;
    }
  }
  .actions{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      ul{
        display:inline-flex;
        list-style: none;
        justify-content: center;
        align-content: center;
        align-items: center;
        gap: 8px;
        li{
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          cursor: pointer;
          span{
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: var(--neutral-200, #D0D1D3);
            transition: all 0.2s ease-in-out;
            &.active{
              width: 32px;
              background: var(--primary-500, #143C97);
            }
          }
        }
      }
    }
  h2{
    font-family: var(--subtitle-h2-font-family);
    font-weight: var(--subtitle-h2-font-weight);
    color: var(--neutral-900);
    font-size: var(--subtitle-h2-font-size);
    letter-spacing: var(--subtitle-h2-letter-spacing);
    line-height: var(--subtitle-h2-line-height);
    font-style: var(--subtitle-h2-font-style);
    padding-bottom: 24px;
  }
  .products{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 24px;
  }

  @media (max-width: 1200px){
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 996px){
    .categories{

    }
  }
`;
