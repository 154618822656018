import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  padding: 32px;
  width: 100%;
  max-width: 1200px;
  margin: 32px auto 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  min-height: 100vh;
`;


export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ol {
    counter-reset: item;

    > ol {
      margin-left: 8px;
    }
  }
  li {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.bodyLight};
    padding: 4px 0;
    line-height: 3rem;
    text-align: left;

    strong {
      font-size: 1.8rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.primary};
      line-height: 64px;
      text-transform: uppercase;
    }

    > ol {
      margin-left: 8px;
    }
  }

  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;

    font-size: 1.8rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 16px;
    margin-top: 16px;
    text-transform: uppercase;
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.bodyLight};
    padding-bottom: 16px;
    line-height: 3rem;
    text-align: left;
  }

  b {
    color: ${(props) => props.theme.colors.bodyLight};
    font-weight: 400;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;

