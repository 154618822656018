export const TipoPagamento = {
    Presencial: "P",
    Online: "O"
}

export const FormaPagamento = {
    Pix: "PI",
    Dinheiro: "DI",
    CartaoCredito: "CR",
    CartaoDebito: "CD",
    Crediario: "CE",
    Convenio: "CV"
}

export function obterDescricaoFormaPagamento(formaPagamento) {
    switch (formaPagamento) {
        case FormaPagamento.Pix:
            return "PIX";
        case FormaPagamento.Dinheiro:
            return "Dinheiro";
        default:
        case FormaPagamento.CartaoCredito:
            return "Cartão de crédito";
        case FormaPagamento.CartaoDebito:
            return "Cartão de débito";
        case FormaPagamento.Crediario:
            return "Crediário";
        case FormaPagamento.Convenio:
            return "Convênio";
    }

}