import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import emptyCart from "../../assets/images/emptyCart.svg";
import excluirItemCesta from "../../assets/images/excluir-item-cesta.svg";
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import SubHeader from "../../components/SubHeader";
import { setTitle } from "../../services/browser";
import { Creators as CartActions } from "../../store/ducks/cart";

import { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  AddIcon,
  ButtonFooterWrap,
  ContainerPageCart,
  ContentWrap,
  Discount,
  EmptyCartImage,
  EmptyCartMessageContainer,
  EmptyCartWrap,
  GoBackButton,
  KeepBuyingLink,
  NoRewardsProgramMessage,
  PriceInfoContent,
  PriceInfoWrap,
  ProductCard,
  ProductInfo,
  ProductTitle,
  ProductValue,
  QuantityWrap,
  RemoveIcon,
  SpecialPrice,
  ThumbnailWrap,
  TitleWrap,
  Wrap
} from "./styles";
import "./styles.css";

import SpecialPricesModal from "../../components/SpecialPricesModal";
import { formatPrice } from "../../util/format";

import { FiX } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";

export default function Cart({ history }) {
  const showDiscount = false;

  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const [toastId, setToastId] = useState(null);
  const [alert, setAlert] = useState(true);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const signed = useSelector((state) => state.auth.signed);
  const { programa_fidelidade = true } = useSelector((state) =>
    state.user.profile == null ? true : state.user.profile
  );
  const { data } = useSelector((state) => state.store);

  const programa_fidelidade_nome = useSelector((state) =>
    state.store.data == null
      ? "Programa de Fidelidade"
      : state.store.data.empresa.programa_fidelidade_nome
  );

  const {
    showProductConditions,
    data: basket,
    amount,
  } = useSelector((state) => state.cart);

  const [frontBasket, setFrontBasket] = useState([]);

  useEffect(() => {
    setTitle("Cesta", true);
    dispatch(CartActions.getStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFrontBasket(basket);
  }, [basket]);

  useEffect(() => {
    const subtotal = basket?.reduce(
      (acc, product) =>
        acc +
        parseFloat(
          product?.preco_mostrar_cesta?.preco_de || product?.preco_mostrar_cesta
        ) *
        product.quantidade,
      0
    );
    setSubtotal(subtotal);
    setDiscount(subtotal - amount);
  }, [amount, basket]);

  function notify() {
    if (!toast.isActive(toastId)) {
      const newToast = toast.info(`Produto removido do carrinho`, {
        position: "top-center",
        className: "toast",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setToastId(newToast);
    }
  }

  function handleDelete(e) {
    var evt = e || window.event;
    if (evt) {
      var keyCode = evt.charCode || evt.keyCode;
      if (keyCode === 8 || keyCode === 46 || keyCode === 17) {
        if (evt.preventDefault) {
          evt.preventDefault();
        } else {
          evt.returnValue = false;
        }
      }
    }
  }

  function handleFocus(e, product, key) {
    const input = document.getElementById(`qtyInput${key}`);
    input.focus();
    input.select();
  }

  function handleChange(quantity, product, key) {
    setFrontBasket(
      frontBasket.map((product, index) =>
        index === key ? { ...product, quantidade: quantity } : product
      )
    );

    const input = document.getElementById(`qtyInput${key}`);

    if (quantity > 50) {
      input.value = 50;
      dispatch(CartActions.addProduct(product, 50, false, true, false, true));
      return;
    }

    if (quantity === "") {
      quantity = 1;
    }

    input.value = quantity;

    if (quantity && quantity > 0) {
      dispatch(
        CartActions.addProduct(
          product,
          parseInt(quantity) + (product?.brotherQuantity ?? 0),
          false,
          true,
          false,
          true
        )
      );
    } else if (parseInt(quantity) === 0) {
      setTimeout(() => {
        handleRemoveProductCart(product, key);
      }, 1000);
    } else if (quantity < 0) {
      input.value = parseInt(quantity) * -1;
    }
  }

  function showPriceProduct(product) {
    return (
      <div className="fidelity-price-container">
        {product?.preco_mostrar_cesta &&
          product?.preco_mostrar_cesta?.preco_de !==
          product?.preco_mostrar_cesta?.preco_por ? (
          <span className="old-price">
            {formatPrice(
              parseFloat(
                product?.preco_mostrar_cesta?.preco_de ||
                product?.preco_mostrar_cesta
              )
            )}
          </span>
        ) : null}
        <div className="fidelity-price">
          <span className="new-price">
            {formatPrice(
              parseFloat(
                product?.preco_mostrar_cesta?.preco_por ||
                product?.preco_mostrar_cesta
              )
            )}
          </span>
          {/* {product?.preco_mostrar_cesta &&
            product?.preco_mostrar_cesta?.programa_fidelidade && (
              <img
                src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/cartaovantagem/cartao-vantagem.png`}
                alt="cartao-vantagens"
                title="cartao vantagens"
              />
            )} */}
        </div>
      </div>
    );
  }

  function showTotalPrice() {
    return <>{formatPrice(parseFloat(amount))}</>;
  }

  function handleRemoveProductCart(product, key) {
    dispatch(CartActions.removeProduct(product, key));
    notify(product);
  }

  if (frontBasket && frontBasket.length > 0) {
    return (
      <BuyStepsTemplate>
        <ContainerPageCart>
          <div className="produtcs-and-offers">
            <SubHeader title="Cesta de compras" />

            {signed && !programa_fidelidade && alert && (
              <NoRewardsProgramMessage>
                <span>
                  Aproveite os preços diferenciados para quem tem{" "}
                  {programa_fidelidade_nome}!{" "}
                  <Link to="/painel/programa-fidelidade">Quero participar</Link>
                </span>
                <button onClick={() => setAlert(!alert)}>
                  <FiX />
                </button>
              </NoRewardsProgramMessage>
            )}
            <ContentWrap>
              <Wrap>
                <TitleWrap>Meus itens</TitleWrap>
                {frontBasket.map((product, index) => (
                  <ProductCard key={index}>
                    <ThumbnailWrap>
                      <img
                        src={product.imagens && product.imagens[0].caminho_imagem}
                        alt={product.nome}
                        title={product.nome}
                      />
                    </ThumbnailWrap>
                    <ProductInfo>
                      <ProductTitle
                        to={`/produtos/visualizar/${product.url}`}
                        lines={1}
                      >
                        {product.nome && product.nome.toLowerCase()}
                      </ProductTitle>

                      {(product?.leve_mais_pague_menos ||
                        product?.menor_preco?.programa_fidelidade) && (
                          <div className="special-prices-container">
                            <button
                              className="special-conditions"
                              onClick={() =>
                                dispatch(CartActions.toggleProductConditions(product))
                              }
                            >
                              Preços
                            </button>
                            {product.maior_desconto > 0 && (
                              <div className="biggest-discount">
                                <span>
                                  ATÉ {parseFloat(product.maior_desconto)}% OFF
                                </span>
                              </div>
                            )}
                            {product?.leve_mais_pague_menos && product?.promocao_aplicada && (
                              <>
                                <SpecialPrice>
                                  <span>Leve + Pague -</span>
                                </SpecialPrice>
                              </>
                            )}
                          </div>
                        )}
                    </ProductInfo>

                    <div className="excluir-item-cesta">
                      <img src={excluirItemCesta} alt="Excluir item da cesta" onClick={() => handleRemoveProductCart(product, index)} />
                    </div>
                    <QuantityWrap>
                      <button
                        className="decrement-item"
                        onClick={() =>
                          handleChange(product.quantidade - 1, product, index)
                        }
                      >
                        <RemoveIcon color={"143C97"} size={30} />
                      </button>
                      <input
                        className="qtyInput"
                        onKeyDown={handleDelete()}
                        onFocus={(e) => handleFocus(e, product, index)}
                        type="number"
                        id={`qtyInput${index}`}
                        value={product.quantidade}
                        onChange={(e) =>
                          handleChange(e.target.value, product, index)
                        }
                        name={`qtyInput${product.key}`}
                      />
                      <button
                        className="increment-item"
                        onClick={() =>
                          handleChange(product.quantidade + 1, product, index)
                        }
                      >
                        <AddIcon color={"143C97"} size={30} />
                      </button>
                    </QuantityWrap>
                    {showDiscount && product?.preco_mostrar_cesta?.percentual_desconto > 0 && (
                      <Discount>
                        <span>
                          {parseFloat(
                            product?.preco_mostrar_cesta?.percentual_desconto * -1
                          ).toLocaleString("pt-BR")}
                          %
                        </span>
                      </Discount>
                    )}
                    <ProductValue>
                      {showPriceProduct(product)}
                    </ProductValue>
                  </ProductCard>
                ))}
              </Wrap>
              <PriceInfoWrap>
                <TitleWrap>Resumo do Pedido</TitleWrap>
                <PriceInfoContent>
                  <span className="price-description">Subtotal ({frontBasket.length} itens)</span>
                  <span className="price-value">{formatPrice(subtotal)}</span>
                </PriceInfoContent>

                {discount > 0 && (
                  <PriceInfoContent>
                    <span className="price-description">Descontos</span>
                    <span className="price-value">{formatPrice(discount)}</span>
                  </PriceInfoContent>
                )}

                <PriceInfoContent className="total">
                  <span className="price-description total">
                    Total
                  </span>
                  <span className="price-value total">{formatPrice(amount)}</span>
                </PriceInfoContent>

                <ButtonFooterWrap
                  id="btn-prosseguir"
                  onClick={() => {
                    if (!signed)
                      return history.push("/entrar", {
                        previousPage: history.location.pathname,
                      });
                    history.push("/checkout/entrega");
                  }}
                >
                  Avançar
                </ButtonFooterWrap>
                <KeepBuyingLink to="/">Continuar comprando</KeepBuyingLink>
              </PriceInfoWrap>
            </ContentWrap>

            <SpecialPricesModal visible={showProductConditions} />
          </div>
        </ContainerPageCart>
      </BuyStepsTemplate>

    );
  } else {
    return (
      <>
        <BuyStepsTemplate>
          <SubHeader title="Cesta de compras" />
          <EmptyCartWrap>
            <EmptyCartImage src={emptyCart} alt="Empty Cart" />
            <EmptyCartMessageContainer>
              <span>Sua cesta de compras está vazia no momento!</span>
              <span>Adicione produtos a ela para continuar com a compra.</span>
            </EmptyCartMessageContainer>
            <Link to="/">
              <GoBackButton text="Voltar para o início" />
            </Link>
          </EmptyCartWrap>
        </BuyStepsTemplate>
      </>
    );
  }
}
