import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SignedMobile, Sidenav } from "./styles";
import { Creators as AuthActions } from "../../store/ducks/auth";
import { Creators as SideMenuActions } from "../../store/ducks/sideMenu";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Creators as ProductsActions } from "../../store/ducks/products";

export default function SideMenu() {
  const dispatch = useDispatch();
  const { dataNewProducts: products } = useSelector((state) => state.products);

  const ref = useRef();
  const { signed } = useSelector((state) => state.auth);
  const { isOpen } = useSelector((state) => state.sideMenu);
  const { profile: user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(ProductsActions.getNewProductsRequest());
  }, [dispatch]);

  useOutsideClick(ref, () => {
    if (!isOpen) return;

    dispatch(SideMenuActions.toggleSideMenu());
  });

  function toggleSideMenu() {
    dispatch(SideMenuActions.toggleSideMenu());
  }

  function signOut() {
    dispatch(AuthActions.signOut());
    dispatch(SideMenuActions.toggleSideMenu());
  }

  return (
    <Sidenav ref={ref} isOpen={isOpen}>
      {signed && (
        <SignedMobile>
          <span>Olá</span>{" "}
          {user && user.nome.substring(0, user.nome.indexOf(" "))}
        </SignedMobile>
      )}

      <button className="closebtn" onClick={() => toggleSideMenu()}>
        &times;
      </button>
      <Link id="link-home" to="/" onClick={() => toggleSideMenu()}>
        Home
      </Link>
      {products.length >= 1 && (
        <Link id="link-lancamento" to="/lancamento" replace onClick={() => toggleSideMenu()}>
          <div className="point"></div>
          Produtos em lançamento
        </Link>
      )}
      <Link id="link-carrinho" to="/carrinho" onClick={() => toggleSideMenu()}>
        Minha Cesta
      </Link>
      {!signed && (
        <Link id="link-login" to="/entrar" onClick={() => toggleSideMenu()}>
          Login
        </Link>
      )}
      {signed && (
        <>
          <Link id="link-minha-conta" to="/painel" onClick={() => toggleSideMenu()}>
            Minha Conta
          </Link>
          <Link id="link-meus-pedidos" to="/painel/meus-pedidos" onClick={() => toggleSideMenu()}>
            <div className="point"></div>
            Meus Pedidos
          </Link>
          <button id="link-signout" className="signOut" onClick={() => signOut()}>
            Sair
          </button>
        </>
      )}
    </Sidenav>
  );
}
