import styled from "styled-components";

export const Container = styled.div`
  min-height: calc(100vh - 355px);
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 15px 0;

  h2 {
    font-size: 20px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
  }
`;

export const NoResultsFound = styled.div`
  display: flex;
  width: 100%;
  margin-top: 85px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 355px);

  span {
    margin-top: 10px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 18px;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  b {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 22px;
  }
`;
