import React, { useEffect } from 'react';

import { Container, Modal, Header, Content, Footer, HeaderFlag, ModalContent, DescriptionContainer, TableContainer } from './styles';
import { Creators as OrderActions } from "../../../store/ducks/order";
import { MdAttachMoney, MdLocalShipping, MdStore } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import Loader from "react-loader-spinner";
import CarDelivery from "../../../assets/images/car-delivery.svg";
import { formatPrice } from "../../../util/format";
import { format, addDays } from 'date-fns'

function DeliveryStoreClosed() {
  const { amount } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const { deliveryStoreData, checkoutData, loading, deliveryStoreDataHour } = useSelector(state => state.order);

  function finishOrder() {
    dispatch(OrderActions.orderRequest({ ...checkoutData }, true));
  }

  function closeModal() {
    dispatch(OrderActions.hideDeliveryStoreModal());
  }

  useEffect(() => {
    return () => {
      dispatch(OrderActions.hideDeliveryStoreModal());
    }
  }, [dispatch])

  return (
    <Container>
        <Modal>
          <img src={CarDelivery} alt="Ìcone carro entrega" />
          <HeaderFlag />
          <ModalContent>
          <Header>
            <h1>Fora de Horário de Entrega</h1>
          </Header>

          <Content>
            <DescriptionContainer>
              <p>
              Não estamos em nosso horário de entrega no momento,
              mas caso você aceite, receberá seu pedido no horário listado abaixo.
              </p>
            </DescriptionContainer>
            <TableContainer>
              <thead>
                <tr>
                  <th>Próximo horário de entrega:</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>{deliveryStoreDataHour.dia ? deliveryStoreDataHour.dia.descricao[0].toUpperCase() + deliveryStoreDataHour.dia.descricao.substr(1) : format(addDays(new Date(deliveryStoreDataHour.data), 1), 'dd/MM/yyyy')}</td>
                <td>A partir de {deliveryStoreDataHour.hora_inicio_entrega.slice(0, -3)}</td>
              </tr>
              </tbody>
            </TableContainer>

            <ul>
              <li>
                <div className="card-icon-bg">
                  <MdStore size={48} />
                </div>
                <div className="card-info">
                  <span className="card-title">Farmácia Selecionada para Entrega</span>
                  <span className="card-description">
                    <strong>{deliveryStoreData.nome_fantasia} </strong>
                      | {deliveryStoreData.endereco} {deliveryStoreData.endereco_numero},
                      {deliveryStoreData.cidade} - {deliveryStoreData.uf}
                  </span>
                </div>
              </li>
              <li>
                <div className="card-icon-bg">
                  <MdLocalShipping size={48} />
                </div>
                <div className="order-amount">
                  <span className="order-amount-label">Custo Entrega: </span>
                  <strong className="order-amount-value">{formatPrice(deliveryStoreData.local_entrega.custo_entrega)}</strong>
                </div>
                <div className="card-icon-bg">
                  <MdAttachMoney size={48} />
                </div>
                <div className="order-amount">
                  <span className="order-amount-label">Total Pedido: </span>
                  <strong className="order-amount-value">{formatPrice(parseFloat(deliveryStoreData.local_entrega.custo_entrega) + amount)}</strong>
                </div>
              </li>
            </ul>
          </Content>

          <Footer>
            <button onClick={() => finishOrder()}>{loading ? (<><Loader type="Oval" color="#FFFFFF" height={16} width={16} /> Confirmando compra...</>) : "Confirmar Compra" }</button>
            <p onClick={() => closeModal()}>Não, obrigado</p>
          </Footer>
          </ModalContent>
        </Modal>
      </Container>
  );
}

export default DeliveryStoreClosed;
