import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import MaskedInput from "react-text-mask";
import close from "../../assets/images/close-popup.svg";
import signOut from "../../assets/images/signOut.svg";
import excluir from "../../assets/images/excluir-item-cesta.svg";
import ModalConfirm from "../../components/ModalConfirm";
import UserIcon from "../../assets/images/userPhoto.svg";
import TermsOfUseModal from "../../components/TermsOfUseModal";
import api from "../../services/api";
import { setTitle } from "../../services/browser";
import { Creators as AuthActions } from "../../store/ducks/auth";
import { Creators as UserActions } from "../../store/ducks/user";
import { AccessButton, ButtonFooterWrap, CancelBtn, Column, DataContainer, FieldErrorMessage, FormContainer, FormGroup, InputField, Label, LoaderContainer, RadioGroup, SubmitButton, UserData } from "./styles";
import { SignUpSchema, cpfMask, dateMask, phoneMask } from "./validation";
import UpdatePasswordModal from "../UpdatePasswordModal";

export default function Panel() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.user);
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true)
  const [showTermsOfUse, setTermsOfUse] = useState(false);
  const [modalDados, setModalDados] = useState(false);
  const [date, setDate] = useState("");
  const [updatePasswordModalIsOpen, setUpdatePasswordModalIsOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setTitle("Meus Dados");

    async function getUser() {
      const { data } = await api.get(`/customers/account`);
      setUser(data);
      const date = data.data_nascimento.split("-");
      setDate(`${date[2]}/${date[1]}/${date[0]}`);
    }

    getUser();
  }, []);

  useEffect(() => {
    setTitle("Painel");

    async function getUser() {
      setLoadingUser(true)
      if (!loading) {
        setLoadingUser(true)
        const { data } = await api.get(`/customers/account`);
        setUser(data);
        setLoadingUser(false)
      }
      setLoadingUser(false)
    }

    getUser();
  }, [loading]);

  async function handleUserData() {
    setModalDados(true);
  }

  function handleClose() {
    setModalDados(false);
  }

  function handleSubmit({
    cpf,
    nome,
    email,
    senha,
    data_nascimento,
    celular: celularComFormatacao,
    genero
  }) {
    const celular = celularComFormatacao.replace(/[^a-zA-Z0-9]/g, "");

    dispatch(
      UserActions.updateUserRequest(
        cpf,
        nome,
        email,
        data_nascimento,
        celular,
        genero
      )
    );
  }

  function handleCheckRemoveUser(){
    setShowConfirmModal(true);
  }

  function handleRemove(){
    dispatch(UserActions.removeUser(user.cpf))
  }

  return (
    <>
      <TermsOfUseModal visible={showTermsOfUse} closeModal={() => setTermsOfUse(false)} />
      <UserData>
        <div className="account-avatar">
          <ReactSVG src={UserIcon} />
          <div className="account-avatar-data">
            {loading || loadingUser ? <Loader type="ThreeDots" color="#00b2a9" height={16} width={16} /> : (<span className="userTitle">{user?.nome}</span>)}
            {loading || loadingUser ? <Loader type="ThreeDots" color="#00b2a9" height={16} width={16} /> : (<span className="userSubtitle">{user?.email}</span>)}
            <ButtonFooterWrap type="button" onClick={handleUserData}>
              Atualizar meus dados
            </ButtonFooterWrap>
          </div>
        </div>

        <div className="account-card-info">
          <span>Termos de uso</span>
          {loading || loadingUser ? <Loader type="ThreeDots" color="#00b2a9" height={16} width={16} /> :
            <AccessButton onClick={() => setTermsOfUse(true)} type="button">
              Acessar
            </AccessButton>}
        </div>

        <hr />

        <div className="account-card-info">
          <span>Atualizar minha senha</span>
          {loading || loadingUser ? <Loader type="ThreeDots" color="#00b2a9" height={16} width={16} /> :
            <AccessButton type="button" onClick={() => {
              setUpdatePasswordModalIsOpen(!updatePasswordModalIsOpen)
            }}>
              Acessar
            </AccessButton>}
        </div>

        <hr />

        <div className="account-actions">
          <button onClick={() => dispatch(AuthActions.signOut())}>
            <img src={signOut} alt="Icon Signout" />Sair da conta
          </button>
          <button onClick={handleCheckRemoveUser}>
            <img src={excluir} alt="Icon Signout" />Excluir conta
          </button>
        </div>
      </UserData>

      <ModalConfirm
            message="Na exclusão da conta você perderá todo o seu histórico de pedidos e o seu acesso ao site, após confirmar a exclusão será redirecionado para a página principal. </ br></ br> Você tem certeza que quer continuar?"
            visible={showConfirmModal}
            confirm={handleRemove}
            textBtnConfirm="Confirmar"
            cancel={() => setShowConfirmModal(false)}
          />

      {modalDados && (
        <DataContainer>
          <div>
            <div>
              <img src={close} onClick={handleClose} />
              <h1>Atualizar meus dados</h1>
            </div>
            {user && date ? (
              <Formik
                initialValues={{
                  cpf: user.cpf,
                  nome: user.nome,
                  email: user.email,
                  data_nascimento: date,
                  celular: user.celular,
                  genero: user.genero
                }}
                validationSchema={SignUpSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <FormContainer onSubmit={handleSubmit}>
                    <Column>
                      <FormGroup>
                        {errors.nome && touched.nome ? (
                          <FieldErrorMessage>{errors.nome}</FieldErrorMessage>
                        ) : null}

                        <InputField
                          invalid={errors.nome && touched.nome}
                          id="nome"
                          type="text"
                          name="nome"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nome}
                          placeholder="Ex: Fulano da Silva"
                        />
                        <Label>Nome Completo*</Label>
                      </FormGroup>

                      <FormGroup>
                        {errors.email && touched.email ? (
                          <FieldErrorMessage>{errors.email}</FieldErrorMessage>
                        ) : null}

                        <InputField
                          invalid={errors.email && touched.email}
                          id="email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Ex: fulano@gmail.com"
                        />
                        <Label>E-mail*</Label>
                      </FormGroup>

                      <div className="groupLine">
                        <FormGroup>
                          <MaskedInput
                            mask={dateMask}
                            invalid={
                              errors.data_nascimento && touched.data_nascimento
                            }
                            id="data_nascimento"
                            placeholder="Ex. 01/01/1999"
                            name="data_nascimento"
                            value={values.data_nascimento}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />

                          <Label htmlFor="data_nascimento">
                            Data de Nascimento*
                          </Label>
                        </FormGroup>

                        <FormGroup className="marginLeft">
                          {errors.celular && touched.celular ? (
                            <FieldErrorMessage>{errors.celular}</FieldErrorMessage>
                          ) : null}

                          <MaskedInput
                            mask={phoneMask}
                            invalid={errors.celular && touched.celular}
                            id="celular"
                            placeholder="Ex: (00) 0000-0000"
                            name="celular"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.celular}
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />
                          <Label htmlFor="celular">Telefone*</Label>
                        </FormGroup>
                      </div>

                      <FormGroup className="halfWidth">
                        {errors.cpf && touched.cpf ? (
                          <FieldErrorMessage>{errors.cpf}</FieldErrorMessage>
                        ) : null}

                        <MaskedInput
                          mask={cpfMask}
                          invalid={errors.cpf && touched.cpf}
                          id="cpf"
                          disabled
                          placeholder="Ex: 000.000.000-00"
                          name="cpf"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cpf}
                          render={(ref, props) => (
                            <InputField ref={input => ref(input)} {...props} />
                          )}
                        />
                        <Label htmlFor="cpf">CPF*</Label>
                      </FormGroup>

                      <FormGroup>
                        {errors.genero && touched.genero ? (
                          <FieldErrorMessage>{errors.genero}</FieldErrorMessage>
                        ) : null}

                        <RadioGroup>
                          <div className="radio">
                            <input
                              id="radio-1"
                              value="M"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="genero"
                              type="radio"
                              checked={values.genero === "M" ? true : false}
                            />
                            <label htmlFor="radio-1" className="radio-label">
                              Masculino
                            </label>
                          </div>

                          <div className="radio">
                            <input
                              id="radio-2"
                              value="F"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="genero"
                              type="radio"
                              checked={values.genero === "F" ? true : false}
                            />
                            <label htmlFor="radio-2" className="radio-label">
                              Feminino
                            </label>
                          </div>

                          <div className="radio naoInformar">
                            <input
                              id="radio-3"
                              value="N"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="genero"
                              type="radio"
                              checked={values.genero === "N" ? true : false}
                            />
                            <label htmlFor="radio-3" className="radio-label">
                              Não informar
                            </label>
                          </div>
                        </RadioGroup>
                        <Label>Sexo*</Label>
                      </FormGroup>

                      <div className="btnArea">
                        <SubmitButton type="submit" disabled={loading}>
                          {loading ? "Salvando alterações..." : "Salvar alterações"}
                        </SubmitButton>
                        <CancelBtn onClick={handleClose}>
                          Cancelar
                        </CancelBtn>
                      </div>
                    </Column>
                  </FormContainer>
                )}
              </Formik>
            ) : <LoaderContainer>
              <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
            </LoaderContainer>}
          </div>
        </DataContainer>
      )}
      {updatePasswordModalIsOpen && (
        <UpdatePasswordModal closeModal={() => {
          setUpdatePasswordModalIsOpen(!updatePasswordModalIsOpen);
        }}/>
      )}
    </>
  );
}
