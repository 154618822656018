import React, { useEffect } from 'react';

import { Container, Content, ParagraphContainer } from './styles';
import { MdClose } from "react-icons/md";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

function TermsOfUseModal({ visible, closeModal }) {

  const { termos_uso } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <Container>
          <Content>
            <button className="close" onClick={() => closeModal()}>
              <MdClose />
            </button>
            <div>
              <ParagraphContainer>
                {ReactHtmlParser(termos_uso)}
              </ParagraphContainer>
            </div>
          </Content>
        </Container>
      )}
    </>
  );
}

export default TermsOfUseModal;
