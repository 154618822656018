import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as StoreByUserActions } from "../../store/ducks/storeByUser";

import {
  Container,
  Item,
  ItemsList,
  Modal,
  ModalHeader,
  ModalFooter
} from "./styles";

function ProductsOutOfStockModal() {
  const dispatch = useDispatch();
  const outOfStockItems = useSelector(state => state.storeByUser.outOfStockItems);

  function setStore(store) {
    dispatch(StoreByUserActions.setStore(store));
    dispatch(StoreByUserActions.toggleOutOfStockModal());
  }

  function deleteItems() {
    dispatch(StoreByUserActions.deleteOutOfStockItems());
  }

  return (
    <Container>
      <Modal>
        <ModalHeader>
          <h1>Ops... algo aconteceu</h1>
          <p>
            Os produtos abaixo não possuem estoque na loja escolhida, você pode
            deixar o sistema escolher a loja automático, ou então, excluir os
            itens da cesta
          </p>
        </ModalHeader>

        <ItemsList>
          {outOfStockItems &&
            outOfStockItems.map(item => (
              <Item>
                <img
                  src={item.imagens[0].caminho_imagem}
                  alt={item.nome}
                />
                <div>
                  <strong>{item.nome}</strong>
                  <span>Qtde: 0</span>
                </div>
              </Item>
            ))}
        </ItemsList>

        <ModalFooter>
          <button className="btn-automatic" onClick={() => setStore(null)}>
            DEIXAR O SISTEMA ESCOLHER A LOJA PARA MIM
          </button>
          <button className="btn-delete" onClick={() => deleteItems()}>
            EXCLUIR ITENS
          </button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default ProductsOutOfStockModal;
